import { User } from "@apacta/sdk";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router-dom";
import { useMount } from "~/lib/lifecycle-helpers";
import { Suspense } from "react";
import { useFormState } from "~/lib/form-state";
import { userSettingsSchema } from "~/pages/employees/_cmp/user-settings-schema";
import { Button } from "~/lib/ui";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";
import { IntegrationTypes } from "~/pages/employees/_cmp/integration-types";
import { MobileAppSettings } from "~/pages/employees/_cmp/mobile-app-settings";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { TimeEntryRuleGroupsInput } from "~/pages/employees/_cmp/time_entry_rule_groups";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export default function SettingsTab() {
  const pageTitle = usePageTitle();
  const { user } = useOutletContext<{ user: User }>();
  const api = useAPI();
  const { t } = useTranslation();
  const { id: userId } = useParams();

  const toast = useToasts();
  useMount(() => {
    pageTitle.set(`${user?.fullName} | ${t("common:user", { count: 2 })}`);
  });

  const getProjectIds = () => {
    if (user.projects === undefined || user.projects?.length < 1) {
      return [];
    }
    return user?.projects?.map((p) => {
      return p.id;
    });
  };

  const queryClient = useQueryClient();

  const { isValid, isModified, getValue, getValues, register, onChange, resetInitialValues } =
    useFormState({
      schema: userSettingsSchema,
      initialValues: {
        adminAccess: user.adminAccess,
        timeEntryRuleGroupId: user.timeEntryRuleGroupId,
        projectIds: getProjectIds(),
        userIntegrationSetting: user.integrationSettings ?? [],
      },
    });

  const userEdit = useMutation({
    mutationFn: () =>
      api.editUser({
        userId: userId as string,
        editUserRequest: {
          timeEntryRuleGroupId: getValue("timeEntryRuleGroupId") ?? null,
          adminAccess: getValue("adminAccess"),
          projectIds: getValue("projectIds") ?? undefined,
          customIntegrationSettings: getValue("userIntegrationSetting") ?? [],
        },
      }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
      queryClient.invalidateQueries({
        queryKey: ["user", userId],
      });
      resetInitialValues(getValues());
    },
  });

  const handleSaveUserSettings = () => {
    if (userId) {
      userEdit.mutate();
    }
  };

  const adminAccessOptions = (() => {
    if (!user) return [];
    return user.adminAccessOptions?.map((accessOption) => optionToListItem(accessOption)) || [];
  })();

  function optionToListItem(a: string): ComboboxListItem {
    return {
      id: a,
      label: t("users:" + a),
      value: a,
    };
  }

  return (
    <>
      <div className="flex w-full flex-col gap-12">
        <Suspense
          fallback={
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          }
        >
          <>
            <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
              <h2 className="m-0">{t("users:tabs.settings")}</h2>
              <div>
                <Button
                  onClick={() => handleSaveUserSettings()}
                  variant="tertiary"
                  disabled={!isModified || !isValid}
                >
                  {t("common:save_changes")}
                </Button>
              </div>
            </div>
            <div className="flex h-full flex-col gap-8">
              <div className="flex flex-col gap-8">
                <div className="flex-1">
                  <div className="mb-2 text-gray-500">{t("users:tabs.primary_label")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                      <div>
                        <SelectionCombobox
                          required
                          label={t("users:access_to_admin")}
                          defaultValue={
                            adminAccessOptions?.find((i) => i.value === user.adminAccess)?.id
                          }
                          data={adminAccessOptions}
                          valueFn={(e) => e.value}
                          labelFn={(e) => e.label}
                          onSelect={(v) => onChange("adminAccess", v ?? undefined)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col md:basis-1/2">
                      <TimeEntryRuleGroupsInput
                        {...register("timeEntryRuleGroupId")}
                        onChange={(groupId) => {
                          onChange("timeEntryRuleGroupId", groupId ?? undefined);
                        }}
                        initialEntryId={getValue("timeEntryRuleGroupId")}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <div className="mb-2 text-gray-500"> {t("projects:project_data")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6 md:max-w-xl">
                      <ProjectSelection
                        multiple={true}
                        defaultValue={getValue("projectIds")}
                        onSelect={(val) => onChange("projectIds", val)}
                      />
                    </div>
                  </div>
                </div>
                {user.mobileAppPreferences ? (
                  <div className="flex-1">
                    <MobileAppSettings data={user.mobileAppPreferences} />
                  </div>
                ) : null}
              </div>
            </div>
            <IntegrationTypes
              user={user}
              onChange={(changedSettings) => {
                onChange("userIntegrationSetting", changedSettings);
              }}
            />
          </>
        </Suspense>
      </div>
    </>
  );
}
