import { Card } from "~/lib/planning/types";
import { getDateWithoutTime } from "~/lib/utils/date/date-utils";
import { isEqual } from "lodash";
import { Draft } from "immer";
import { toArray } from "~/lib/planning/utils";
import { CardReducerState, CardReducerViewState } from "~/lib/planning/card-reducer";

export const isSameContainer = (card1: Card, card2: Card) => {
  const date1 = !!card1.date ? getDateWithoutTime(card1.date) : null;
  const date2 = !!card2.date ? getDateWithoutTime(card2.date) : null;

  return card1.userId === card2.userId && isEqual(date1, date2);
};

export const isUnplanned = (card: Card) => !card.date;

export const cardReducerFilter = (cards: Draft<Array<Card>>, cardToCheck: Card) => {
  const cardCheckDate = !!cardToCheck.date ? getDateWithoutTime(cardToCheck.date) : null;
  return cards
    .filter((card) => {
      const cardDate = !!card.date ? getDateWithoutTime(card.date) : null;
      return card.userId === cardToCheck.userId && isEqual(cardDate, cardCheckDate);
    })
    .sort((a, b) => {
      if (a.listIndex === null || b.listIndex === null) {
        return 0;
      }
      return a.listIndex - b.listIndex;
    });
};

export function naiveViewGenerator(state: Draft<CardReducerState>): Draft<CardReducerViewState> {
  return {
    ...state.views,
    allCards: toArray(state.cards),
    plannedAssigned: toArray(state.cards)
      .filter((c) => c.userId && c.date)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
    plannedUnassigned: toArray(state.cards)
      .filter((c) => !c.userId && c.date)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
    unplanned: toArray(state.cards)
      .filter((c) => c.date === undefined || c.date === null)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
  };
}
