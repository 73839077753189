export class KeyboardShortcut {
  private keys: Set<string> = new Set();
  private subscriptions: Array<{ keys: Array<string>; cb: () => void }> = [];

  constructor() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
    document.addEventListener("keyup", this.handleKeyUp.bind(this));
  }

  private handleKeyDown(event: KeyboardEvent) {
    this.keys.add(event.code);
    this.checkForMatches();
  }

  private handleKeyUp(event: KeyboardEvent) {
    this.keys.delete(event.key);
  }

  onShortCut(keys: Array<string>, cb: () => void) {
    this.subscriptions.push({ keys, cb });
  }

  private checkForMatches() {
    for (const { keys, cb } of this.subscriptions) {
      if (keys.every((k) => this.keys.has(k))) {
        cb();
      }
    }
  }

  close() {
    this.subscriptions = [];
    this.keys = new Set();
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }
}
