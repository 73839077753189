import { BaseFile, FrontpageFeedDTO } from "@apacta/sdk";
import { formatCurrency } from "~/lib/utils/number";
import { Card, CardField } from "../static/card";
import { CardHeader } from "../static/card-header";
import colors from "tailwindcss/colors";
import { useTranslation } from "react-i18next";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { useLinking } from "~/lib/utils/use-linking";

export const InvoiceCardDraftErp = ({ data }: { data: FrontpageFeedDTO }) => {
  const { t } = useTranslation();
  const link = useLinking();
  const files: Array<BaseFile> = data.invoice?.pdfUrl
    ? [
        {
          name: `${t("common:invoice")}.pdf`,
          originalFilename: `${t("common:invoice")}.pdf`,
          fileUrl: data.invoice.pdfUrl,
          downloadUrl: data.invoice.pdfUrl,
          mimeType: "application/pdf",
          id: "pdf",
        },
      ]
    : [];

  const fields: Array<CardField> = [
    {
      label: t("common:invoice_no"),
      render: () =>
        data.invoice?.invoiceNumber ? (
          <OptionalLink to={link.invoice(data.invoice.id)}>
            {data.invoice?.invoiceNumber}
          </OptionalLink>
        ) : (
          <span className="text-gray-400">{t("common:not_available")}</span>
        ),
    },
    {
      label: t("common:project", { count: 1 }),
      render: () => (
        <OptionalLink to={link.project(data.project?.id)}>{data.project?.fullName}</OptionalLink>
      ),
    },
    {
      label: t("common:customer", { count: 1 }),
      render: () => (
        <OptionalLink to={link.customer(data.invoice?.contact?.id)}>
          {data.invoice?.contact?.name}
        </OptionalLink>
      ),
    },
    {
      label: t("common:amount"),
      render: () =>
        data.sum !== undefined && data.sum !== null ? (
          formatCurrency(data.sum)
        ) : (
          <span className="text-gray-400">{t("common:not_available")}</span>
        ),
    },
  ];

  return (
    <Card
      renderHeader={() => (
        <CardHeader
          title={`${t("common:invoice", { count: 1 })} (${t("common:draft")})`}
          icon="invoice"
          color={colors["amber"][500]}
          renderIdentifier={() =>
            data.invoice?.integrationImageUrl && (
              <img src={data.invoice.integrationImageUrl} alt="ERP logo" className="h-6 w-auto" />
            )
          }
        />
      )}
      fields={fields}
      files={files}
    />
  );
};
