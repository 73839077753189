import { availableLanguages } from "~/lib/i18n/i18n";
import { TFunction } from "i18next";
import { onlyUniqueFilter } from "~/lib/utils/filters";
import {
  DateFormatDetails,
  DateInRangeOptions,
  DateObject,
  DatePeriodType,
  DayNameFormatOptions,
  FormatDateOptions,
  GetDatesProps,
  GetDatesResult,
  MatchDatesOptions,
  periodLengths,
} from "~/lib/utils/date/date-utils.types";
import { ApactaHolidays } from "~/lib/utils/date/apacta-holidays";

const defaultDayNameFormatOptions: DayNameFormatOptions = {
  format: "long",
  capitalizeFirst: false,
};

/**
 * Get the name of the day in the given language
 * @param {Date} date - The date for which you want the day name
 * @param {string} resolvedLanguage - String representing the resolved language of the system
 * @param {DayNameFormatOptions} options - Options for formatting the day name
 */
export const getDayName = (
  date: Date,
  resolvedLanguage?: string,
  options?: DayNameFormatOptions
): string => {
  const locale = resolvedLanguage ? availableLanguages[resolvedLanguage].defaultLocale : "default";
  const formatOpts = Object.assign(defaultDayNameFormatOptions, options || {});
  let dayName = new Intl.DateTimeFormat(locale, { weekday: formatOpts.format }).format(date);
  if (formatOpts.capitalizeFirst) {
    const firstLetter = dayName.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = dayName.slice(1);
    dayName = firstLetterCap + remainingLetters;
  }
  return dayName;
};

/**
 * @description Formatter using Intl to prettify dates
 * @param {Date | null} date The date to format, if no date is supplied, today's date will be used
 * @param {string} resolvedLanguage The resolved language of the system to use in formatting
 * @param {FormatDateOptions} formatOptions Formatting options used to extend or limit functionality
 * @returns {string} Formatted date string
 */
export const formatDate = (
  date: Date | null,
  resolvedLanguage?: string,
  formatOptions?: FormatDateOptions
): string => {
  const defaultFormatDateOptions: FormatDateOptions = {
    shortDate: false,
    excludeTime: false,
    shortMonth: false,
    emptyStringOnNull: false,
    excludeYear: false,
  };

  const formatOpts = Object.assign(defaultFormatDateOptions, formatOptions || {});
  if (formatOpts.emptyStringOnNull && date === null) {
    return "";
  }

  const localDate = date ? date : new Date();

  const locale =
    (resolvedLanguage && availableLanguages[resolvedLanguage].defaultLocale) || "default";

  let options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

  if (!formatOpts.shortDate) {
    if (formatOpts.excludeTime) {
      options = {
        year: formatOpts.excludeYear ? undefined : "numeric",
        month: formatOpts.shortMonth ? "short" : "long",
        day: "numeric",
      };
    } else {
      options = {
        year: formatOpts.excludeYear ? undefined : "numeric",
        month: formatOpts.shortMonth ? "short" : "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
    }
  }

  return new Intl.DateTimeFormat(locale, options).format(localDate);
};

/**
 * Method used to calculate the format of a date string based on the locale and options
 * Used in `formatToDate` to parse a date string
 * @param locale
 * @param formatOptions
 */
export const getDateFormat = (
  locale: string,
  formatOptions?: FormatDateOptions
): DateFormatDetails | null => {
  const defaultFormatDateOptions: FormatDateOptions = {
    shortDate: false,
    excludeTime: false,
    shortMonth: false,
    emptyStringOnNull: false,
  };

  const formatOpts = Object.assign(defaultFormatDateOptions, formatOptions || {});

  if (!formatOpts.shortDate) {
    return null;
  }

  // Known date used to get the format parts regardless of locale or options
  // Must be formatted with Timezone to avoid issues with Safari < 14.1
  const localDate = new Date("2000-10-20T00:01:02");

  let options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

  if (!formatOpts.shortDate) {
    if (formatOpts.excludeTime) {
      options = {
        year: "numeric",
        month: formatOpts.shortMonth ? "short" : "long",
        day: "numeric",
      };
    } else {
      options = {
        year: "numeric",
        month: formatOpts.shortMonth ? "short" : "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
    }
  }

  const formattedDate = Intl.DateTimeFormat(locale, options).format(localDate);
  const dateFormat = formattedDate
    .replace("2000", "YYYY")
    .replace("10", "MM")
    .replace("20", "DD")
    .replace("00", "HH")
    .replace("01", "II")
    .replace("02", "SS");

  return {
    locale,
    options: formatOpts,
    formatParts: Intl.DateTimeFormat(locale, options).formatToParts(localDate),
    dateFormat,
  };
};
/**
 * Method used to parse a date string based on the locale and options
 * @param {string} date - The date string to parse
 * @param {string} resolvedLanguage - The resolved language of the system to use in formatting
 * @param {FormatDateOptions} formatOptions - Formatting options used to extend or limit functionality
 * @returns {Date | null} The parsed date or null if the date string is invalid
 */
export const parseStringToDate = (
  date: string,
  resolvedLanguage?: string,
  formatOptions?: FormatDateOptions
): Date | null => {
  const locale =
    (resolvedLanguage && availableLanguages[resolvedLanguage].defaultLocale) || "default";

  const details = getDateFormat(locale, formatOptions);

  if (!details) {
    return null;
  }

  const separator = details?.formatParts.find((part) => part.type === "literal")?.value;
  const formatOrder = details.formatParts.filter((d) => d.type !== "literal");
  const dateParts = date.split(separator || ".");

  const year = dateParts[formatOrder.findIndex((d) => d.type === "year")];
  const month = dateParts[formatOrder.findIndex((d) => d.type === "month")];
  const day = dateParts[formatOrder.findIndex((d) => d.type === "day")];

  if (year.length !== 4 || month.length !== 2 || day.length !== 2) {
    return null;
  }

  return new Date(`${year}-${month}-${day}`);
};

export const getDateWithoutTime = (v: Date) => {
  return new Date(v.getFullYear(), v.getMonth(), v.getDate());
};

const defaultDateInRangeOptions: DateInRangeOptions = {
  ignoreTimestamp: false,
  exactFrom: true,
  exactTo: true,
};

/**
 * Check if a date is within a range of dates
 * @param {Date} target - The date to check
 * @param {Date} fromDate - The start date of the range
 * @param {Date} toDate - The end date of the range
 * @param {DateInRangeOptions} options - Options for the date range check
 * @returns {boolean} true | false
 */
export const dateInRange = (
  target: Date,
  fromDate: Date,
  toDate: Date,
  options?: DateInRangeOptions
): boolean => {
  const opts = Object.assign(defaultDateInRangeOptions, options || {});

  let targetDate = target;
  let from = fromDate;
  let to = toDate;

  if (opts.ignoreTimestamp) {
    targetDate = getDateWithoutTime(target);
    from = getDateWithoutTime(fromDate);
    to = getDateWithoutTime(toDate);
  }

  const fromMatch = opts.exactFrom ? targetDate >= from : targetDate > from;
  const toMatch = opts.exactTo ? targetDate <= to : targetDate < to;

  return fromMatch && toMatch;
};

const defaultMatchDatesOptions: MatchDatesOptions = {
  ignoreTimestamp: false,
};

/**
 * Matches two dates based on the options
 * @param {Date} date1 - The first date to compare
 * @param {Date} date2 - The second date to compare
 * @param {MatchDatesOptions} options - Options for the date comparison
 */
export const matchDates = (date1: Date, date2: Date, options?: MatchDatesOptions): boolean => {
  const opts = Object.assign(defaultMatchDatesOptions, options || {});
  if (opts.ignoreTimestamp) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  return date1 === date2;
};

export const dateIsWeekend = (v: Date): boolean => {
  return v.getDay() === 0 || v.getDay() === 6;
};

export const getOffsetDate = (date: Date, offset: number) => {
  const d = new Date(date);
  d.setDate(d.getDate() + offset);
  return d;
};

export const startOfDay = (date: Date): Date => {
  return getDateWithoutTime(new Date(date));
};

export const endOfDay = (date: Date): Date => {
  const d = getDateWithoutTime(new Date(date));
  d.setDate(d.getDate() + 1);
  d.setTime(d.getTime() - 1);
  return d;
};

export const monthsBetween = (from: Date, to: Date): number => {
  return to.getMonth() - from.getMonth() + 12 * (to.getFullYear() - from.getFullYear());
};

export const daysBetween = (from: Date, to: Date, includeTo = false): number => {
  const value = Math.round(
    (getDateWithoutTime(to).getTime() - getDateWithoutTime(from).getTime()) / (1000 * 3600 * 24)
  );

  return includeTo ? value + 1 : value;
};

export const datesInRange = (from: Date, to: Date, includeWeekend = false): Array<Date> => {
  const dates: Array<Date> = [];

  // DANGER: This function mutates the date object. Always make a copy before mutating.
  // - 1 whole day was spent debugging this issue before adding a copy of the date object.

  const currentDate = new Date(from);
  while (+getDateWithoutTime(currentDate) <= +getDateWithoutTime(to)) {
    if (!includeWeekend && (currentDate.getDay() === 0 || currentDate.getDay() === 6)) {
      currentDate.setDate(currentDate.getDate() + 1);
      continue;
    }
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const getWeekNumber = (v: Date): number => {
  const d = new Date(Date.UTC(v.getFullYear(), v.getMonth(), v.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  /*
  https://github.com/microsoft/TypeScript/issues/5710
  TS2362: The left-hand side of an arithmetic operation must be of type 'any', 'number' or an enum type.

  Force number
   */
  return Math.ceil(((+d - +yearStart) / 86400000 + 1) / 7);
};

export const getDatesInPeriod = (v: Date, type: DatePeriodType): Array<Date> => {
  const dates: Array<Date> = [];

  if (type === "day") return [v];

  if (type === "week" || type === "fortnight") {
    const dc = new Date(v);

    const dayOffset = 1 - v.getDay();

    const m = new Date(dc.setDate(v.getDate() + dayOffset));

    // if sunday, go back 7 days because weeks start on monday, not sunday.
    // if this is not done, sunday will be counted as part of the next week.
    if (v.getDay() === 0) {
      m.setDate(m.getDate() - 7);
      dc.setDate(dc.getDate() - 7);
    }

    const dayCount = periodLengths[type];

    // day, week, fortnight
    for (let i = 0; i < dayCount; i++) {
      const d = new Date(dc);
      const dd = new Date(d.setDate(m.getDate() + i));
      dates.push(dd);
    }
  } else if (type === "month") {
    const dc = new Date(v);
    // month
    const dm = new Date(dc.setDate(1));

    while (dm.getMonth() === v.getMonth()) {
      const d = new Date(dm);
      dates.push(d);
      dm.setDate(dm.getDate() + 1);
    }
  }

  return dates;
};

export const getWeekDatePeriod = (
  v: Date,
  offset: number,
  type: "day" | "week"
): { dateFrom: Date; dateTo: Date } => {
  const dates: Array<Date> = [];
  const inputDate = new Date(v);

  const offsetDate = new Date(inputDate.setDate(v.getDate() - v.getDay()));

  // if sunday, go back 7 days because weeks start on monday, not sunday.
  // if this is not done, sunday will be counted as part of the next week.
  if (v.getDay() === 0) {
    offsetDate.setDate(offsetDate.getDate() - 7);
    inputDate.setDate(inputDate.getDate() - 7);
  }

  const dayCount = periodLengths[type] * (offset < 0 ? offset * -1 : offset);

  for (let i = 0; i < dayCount; i++) {
    const instancedDate = new Date(inputDate);
    const calculatedDate =
      offset < 0
        ? new Date(instancedDate.setDate(offsetDate.getDate() - i))
        : new Date(instancedDate.setDate(offsetDate.getDate() + i));
    dates.push(calculatedDate);
  }

  return {
    dateFrom: dates[dates.length - 1],
    dateTo: v,
  };
};

const createDateObject = (date: Date): DateObject => {
  return {
    date: date,
    weekNumber: getWeekNumber(date),
    monthNumber: date.getMonth(),
  };
};
export const getDates = (props: GetDatesProps): GetDatesResult => {
  const dates = getDatesInPeriod(props.date, props.type);
  const dateObjs = dates.map((date) => {
    return createDateObject(date);
  });

  return {
    dates: dateObjs,
    count: dateObjs.length,
  };
};

export const getWeekNumbersInPeriod = (dates: Array<Date>): Array<number> => {
  const weekNumbers = dates.map((date) => {
    return getWeekNumber(date);
  });
  return weekNumbers.filter(onlyUniqueFilter);
};

/**
 * Returns the date part of ISOString.
 *
 * BEWARE: If you're using this to select a date, it's better to use `toLocalDateString()`
 * because it respects the local timezone. This one here will use UTC time and then return the date!
 *
 * @param date
 * @returns Date part of ISOString()
 */
export function getISOShortDate(date: Date): string {
  return date.toISOString().split("T")[0];
}

export const dateIsBeforeDate = (
  dateToCompare: Date,
  dateToBeBefore: Date,
  includeDateToBeBefore: boolean = false
): boolean => {
  if (includeDateToBeBefore) {
    return getDateWithoutTime(dateToCompare) <= getDateWithoutTime(dateToBeBefore);
  }
  return getDateWithoutTime(dateToCompare) < getDateWithoutTime(dateToBeBefore);
};

export const dateIsAfterDate = (
  dateToCompare: Date,
  dateToBeAfter: Date,
  includeDateToBeAfter: boolean = false
): boolean => {
  if (includeDateToBeAfter) {
    return getDateWithoutTime(dateToCompare) >= getDateWithoutTime(dateToBeAfter);
  }
  return getDateWithoutTime(dateToCompare) > getDateWithoutTime(dateToBeAfter);
};

/**
 * Converts a Date object to a local date string in the format "YYYY-MM-DD"
 * So given a "2020-01-01T00:00:00+1" will not result in a 2019-12-31 date when converted to ISO
 *
 * @param date
 * @returns Local date in format "YYYY-MM-DD"
 */
export function toLocalDateString(date?: Date): string {
  let d = date ?? new Date();
  const offset = d.getTimezoneOffset();
  d = new Date(d.getTime() - offset * 60 * 1000);
  return d.toISOString().split("T")[0];
}

export function timeAgo(
  date: Date = new Date(),
  locale = "da",
  formatterOptions?: Intl.RelativeTimeFormatOptions
) {
  const formatter = new Intl.RelativeTimeFormat(locale, formatterOptions);
  const ranges = [
    ["years", 3600 * 24 * 365],
    ["months", 3600 * 24 * 30],
    ["weeks", 3600 * 24 * 7],
    ["days", 3600 * 24],
    ["hours", 3600],
    ["minutes", 60],
    ["seconds", 1],
  ] as const;
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  for (const [rangeType, rangeVal] of ranges) {
    if (rangeVal < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / rangeVal;
      return formatter.format(Math.round(delta), rangeType);
    }
  }
}

/**
 * Pads a time string with zeros to ensure it has the format "HH:MM"
 * @param {string} time - Time string in the format "HH:MM"
 * @returns {string} Time string in the format "HH:MM"
 */
export function padTimeStrings(time?: string): string {
  if (!time) {
    return "00:00";
  }

  return time
    .split(":")
    .map((v) => v.padStart(2, "0"))
    .join(":");
}

/**
 * Splits a time string into hours and minutes
 * @param {string} time - Time string in the format "HH:MM"
 * @returns {Array<number>} Array with two numbers, hours and minutes
 */
export function splitTime(time: string): Array<number> {
  return time.split(":").map((v) => parseInt(v));
}

/**
 * Returns the time between two time strings as a time string
 * @param {string} time1 - Time string in the format "HH:MM"
 * @param time2 - Time string in the format "HH:MM"
 * @returns {string} Time string in the format "HH:MM"
 */
export function timeBetween(time1: string, time2: string): string {
  const [hours1, minutes1] = splitTime(time1);
  const [hours2, minutes2] = splitTime(time2);

  const date1 = new Date(0, 0, 0, hours1, minutes1);
  const date2 = new Date(0, 0, 0, hours2, minutes2);

  const diff = date2.getTime() - date1.getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);

  return hoursMinutesToTimeString(hours, minutes);
}

/**
 * Converts hours and minutes to a time string with leading zeros
 * @param {number} hours - Number of hours
 * @param {number} minutes - Number of minutes
 * @returns {string} Time string in the format "HH:MM"
 */
export function hoursMinutesToTimeString(hours: number, minutes: number): string {
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

export function hoursMinutesToLocaleString(hours: number, minutes: number, t: TFunction): string {
  return `${hours.toString().padStart(2, "0")}${t("common:short_hour")} ${minutes.toString().padStart(2, "0")}${t("common:short_minute")}`;
}

/**
 * Converts a time string to a decimal number
 * @param {string} time - Time string in the format "HH:MM"
 * @returns {number} Decimal number
 */
export function timeStringToDecimal(time: string): number {
  const [hours, minutes] = splitTime(time);
  return hours + minutes / 60;
}

export function decimalToTimeString(decimal: number): string {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return hoursMinutesToTimeString(hours, minutes);
}

export type Day = {
  date: Date;
  isWeekend: boolean;
  isToday: boolean;
  isHoliday: boolean;
  isOutOfRange?: boolean;
  holidayName?: string;
};

export type Week = {
  year: number;
  weekNumber: number;
  days: Array<Day>;
};

const hd = new ApactaHolidays("DK");

/**
 * Create an array of weeks in a given date range
 * @param {Date} from - The start date of the range
 * @param {Date} to - The end date of the range
 * @param {Date|undefined} startDate - Start day of the week of the period
 * @param {Date} endDate - End day of the week of the period
 * @returns {Array<Week>} An array of weeks in the given date range
 * ```
 * [
 *   {
 *     weekNumber: number,
 *     days: [
 *     { date: Date, isWeekend: boolean, isToday: boolean, isHoliday: boolean },
 *     { date: Date, isWeekend: boolean, isToday: boolean, isHoliday: boolean },
 *     ...
 *     ]
 *   }
 *   ...
 * ]
 * ```
 */
export function weeksInRange(from: Date, to: Date, startDate?: Date, endDate?: Date): Array<Week> {
  const weeks: Array<Week> = [];

  const fromDate = getDateWithoutTime(from);
  fromDate.setDate(fromDate.getDate() - (fromDate.getDay() === 0 ? 6 : fromDate.getDay() - 1));
  const toDate = getDateWithoutTime(to);
  toDate.setDate(toDate.getDate() + (toDate.getDay() === 0 ? 0 : 7 - toDate.getDay()));

  while (+fromDate <= +toDate) {
    const weekNumber = getWeekNumber(fromDate);
    const weekDates = datesInRange(fromDate, getOffsetDate(fromDate, 6), true);
    const days = weekDates.map((date) => {
      const holiday = hd.isHoliday(date);
      const outOfRange =
        startDate && endDate
          ? getDateWithoutTime(date) < getDateWithoutTime(startDate) ||
            getDateWithoutTime(date) > getDateWithoutTime(endDate)
          : false;

      return {
        date,
        isWeekend: dateIsWeekend(date),
        isToday: matchDates(date, new Date()),
        isHoliday: !!holiday,
        isOutOfRange: outOfRange,
        holidayName: holiday ? holiday[0].name : undefined,
      };
    });
    weeks.push({
      year: fromDate.getFullYear(),
      weekNumber,
      days: days,
    });

    fromDate.setDate(fromDate.getDate() + 7);
  }

  return weeks;
}

export const moveDateRetainTime = (from: Date, to: Date) => {
  const startTime = new Date(to);
  startTime.setHours(from.getHours());
  startTime.setMinutes(from.getMinutes());
  return startTime;
};
