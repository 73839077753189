import { Card } from "~/lib/planning";
import { useCallback } from "react";
import { CardContextMenu } from "~/pages/planning/_cmp/context/card-context-menu";
import { DraggableCard } from "~/pages/planning/_cmp/draggable-card";
import { AddCard } from "~/pages/planning/_cmp/add-card";

export const PlanningTableRowExpandedContent = ({
  cards,
  cardCanPaste,
  isDragging,
  onAddNew,
}: {
  cards: Array<Card>;
  cardCanPaste: boolean;
  isDragging: boolean;
  onAddNew: () => void;
}) => {
  const isLastCard = useCallback(
    (idx: number) => {
      return idx + 1 === (cards.length ?? 0);
    },
    [cards]
  );

  return (
    <>
      {cards.map((card, idx) => (
        <CardContextMenu key={`card-${card.id}-${idx}`} card={card} canPaste={cardCanPaste}>
          <DraggableCard card={card} isLast={isLastCard(idx)} index={idx} />
        </CardContextMenu>
      ))}
      {isDragging ? (
        <div className="h-8 w-full"></div>
      ) : (
        <AddCard isDragging={isDragging} onClick={onAddNew} />
      )}
    </>
  );
};
