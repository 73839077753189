import { GetMe200Response } from "@apacta/sdk";
import { useSession } from "./session";

export function useMe() {
  const { me } = useSession();
  // TODO: This was written before we had public pages and is causing issues
  // - Refactor to return undefined if undefined - 53 errors I try now while doing hotfix
  if (!me) {
    throw new Error(
      "Attempting to access 'me' from session and it's undefined. Session should be initialised by now."
    );
  }
  return me as GetMe200Response;
}
