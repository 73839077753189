import { Invoice } from "@apacta/sdk";

/**
 * Shared visibility logic for invoices page actions
 * Note: This isn't meant to be a full-blown solution for action abstractions.
 * That's on Andri's TODO for later
 */
type Types =
  | "view-action-button"
  | "edit-action-button"
  | "save-detail-button"
  | "send-action-button"
  | "activity-log-action-button"
  | "delete-action-button"
  | "credit-action-button"
  | "book-detail-button"
  | "preview-detail-inline"
  | "preview-detail-button";

export function isVisible(action: Types, invoice: Invoice): boolean {
  switch (action) {
    case "view-action-button":
      return !invoice.isDraft && !invoice.isLocked;
    case "send-action-button":
      return !invoice.isDraft;
    case "activity-log-action-button":
      return !invoice.isLocked;
    case "delete-action-button":
      // We can't delete invoices that are sent to an integration
      if (!!invoice.integrationId) return false;
      return !!invoice.isDraft && !invoice.isLocked;
    case "credit-action-button":
      return !invoice.isDraft;
    case "preview-detail-inline":
      if (!invoice.isDraft) return true; // Normal PDF view
      if (invoice.isDraft && !!invoice.integrationId) {
        // Draft and sent to integration, also show inline
        return true;
      }
      return false;
    case "preview-detail-button":
      if (isVisible("preview-detail-inline", invoice)) return false;
      return true;
    case "save-detail-button":
      return !!invoice.isDraft && !invoice.isLocked;
    case "book-detail-button":
      return !!invoice.isDraft && !invoice.isLocked;
    case "edit-action-button":
      return !!invoice.isDraft && !invoice.isLocked && !invoice.integrationId;
    default:
      throw new Error("Invalid action type provided: " + action);
  }
}
