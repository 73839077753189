import { GraphQLClient } from "graphql-request";
import { useSession } from "../auth/session";
import { getSdk } from "./generated/request";
import { BACKEND_URL } from "../auth/config";
import { gqlSerializer } from "./serializer"; // graphql-request doesn't automatically transform custom scalars (e.g. Date)
import { useMemo } from "react";

export function useGraphQL() {
  const client = useGraphQLClient();
  const sdk = getSdk(client);
  return sdk;
}

export function useGraphQLClient() {
  const { apiKey } = useSession();
  // client ref
  const client = useMemo(
    () =>
      new GraphQLClient(`${BACKEND_URL}/graphql`, {
        headers: {
          authorization: `Bearer ${apiKey}`,
        },
        jsonSerializer: gqlSerializer,
        errorPolicy: "none", // throw on all errors
      }),
    [apiKey]
  );

  return client;
}
