import { useFeatureFlags } from "../feature-flags";

import { useMemo } from "react";
import {
  linkToCustomer,
  linkToExpense,
  linkToExpensesV3,
  linkToInvoiceV1,
  linkToInvoiceV3,
  linkToProject,
} from "./linking";

// This is a custom hook that is used to generate links to different parts of the application.
// - NOTE: Maybe we should add the canAccess logic here? That way, the Link component doesn't have to do anything
// - NOTE: Unlike the functions in linking.ts, we allow undefined input here. So use OptionalLink with it
export function useLinking() {
  const flags = useFeatureFlags();

  const methods = useMemo(
    () => ({
      invoice: (id?: string) => {
        if (!id) return;
        return flags.has("cp3.invoices") ? linkToInvoiceV3(id) : linkToInvoiceV1(id);
      },
      project: (id?: string) => (id ? linkToProject(id) : undefined),
      customer: (id?: string) => (id ? linkToCustomer(id) : undefined),
      expense: (id?: string) => {
        if (!id) return;

        return flags.has("cp3.expenses") ? linkToExpensesV3(id) : linkToExpense(id);
      },
    }),
    [flags]
  );
  return methods;
}
