import { NavItem } from "../navigation-item/navigation-item.type";
import { NavLink } from "react-router-dom";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useRoles } from "~/lib/auth/use-roles";
import { FeaturesType, useSession } from "~/lib/auth/session";
import { twMerge } from "tailwind-merge";
import Collapsible from "~/lib/ui/collapsible";
import { Dialog } from "~/lib/ui";
import { iframeNavigationStateHack } from "~/lib/utils/iframe";
import { InnerItem } from "./navigation-item-inner";
import { NavigationPopoverMenu } from "./navigation-popover-menu";
import { useSidebarContext } from "../../../sidebar-context";
import Tooltip from "~/lib/ui/tooltip";
import { Badge } from "~/lib/ui/badge";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";

export type NavigationItemProps = {
  item: NavItem;
  isSubItem?: boolean;
};
export function NavigationItem({ item, isSubItem = false }: NavigationItemProps) {
  const { sidebarCollapsed, onSidebarCollapse, sidebarType } = useSidebarContext();
  const { isBreakpoint } = useBreakpoints();
  const isMobile = isBreakpoint("md"); // Check if we are at or below the md breakpoint

  const isActive = false; // TODO: Revisit when we are rid of frames

  const { me } = useSession();

  const features = useFeatureFlags();
  const roles = useRoles();

  // Skip any items that are locked behind a feature flag
  if (item.featureName && !features.has(item.featureName as FeaturesType)) {
    return null;
  }

  // Skip any items that are locked behind a role
  if (item.role && !roles.has(item.role)) {
    return null;
  }

  // Skip any items that should be hidden
  if (me && item.isHidden?.(me)) {
    return null;
  }

  // Return Disclosure if item has children
  if (item.children) {
    if (sidebarCollapsed) {
      return (
        <NavigationPopoverMenu subItems={item.children}>
          <button className={getItemClasses(isActive, isSubItem, isMobile, sidebarCollapsed)}>
            <InnerItem item={item} />
          </button>
        </NavigationPopoverMenu>
      );
    }
    return (
      <Collapsible
        asChild
        triggerRender={(open) => (
          <button className={getItemClasses(isActive, isSubItem, isMobile, sidebarCollapsed)}>
            <InnerItem item={item} open={open} />
          </button>
        )}
      >
        {item.children?.map((subItem, idx) => (
          <NavigationItem item={subItem} key={idx} isSubItem={true} />
        ))}
      </Collapsible>
    );
  }

  // Return anchor tag with link if item is targeting external website
  if (item.external) {
    return (
      <Tooltip
        disabled={!sidebarCollapsed}
        side="right"
        contentClassName="bg-hover text-white"
        arrowClassName="fill-hover"
        trigger={
          <a
            href={item.href}
            target="_blank"
            rel="noreferrer"
            className={getItemClasses(isActive, isSubItem, isMobile, sidebarCollapsed)}
          >
            <InnerItem item={item} />
          </a>
        }
      >
        <>
          {item.name}
          {item?.badge ? (
            <Badge
              className={twMerge("ml-4", item.badge.className)}
              size="sm"
              label={item.badge.label}
            />
          ) : null}
        </>
      </Tooltip>
    );
  }

  if (item.renderDialog) {
    return (
      <Tooltip
        disabled={!sidebarCollapsed}
        side="right"
        contentClassName="bg-hover text-white"
        arrowClassName="fill-hover"
        trigger={
          <div>
            <Dialog
              render={item.renderDialog}
              triggerAsChild={true}
              trigger={
                <button
                  className={twMerge(
                    "w-full",
                    getItemClasses(isActive, isSubItem, isMobile, sidebarCollapsed)
                  )}
                >
                  <InnerItem item={item} />
                </button>
              }
              // We override z-index here because the modal needs to overshadow the menu
              className={twMerge("z-sticky")}
              // Prevents the tooltip to trigger on the dialog content. FML
              onOpenAutoFocus={(e) => e.preventDefault()}
              onCloseAutoFocus={(e) => e.preventDefault()}
              onOpenChange={(dOpen) => {
                // Collapse the sidebar after the dialog closes when not on desktop
                // Note: This is a hack
                // - Because the different implementations of sidebar are mounting and unmounting this breaks dialogs.
                // - Ideally we'd have a better way to handle this.
                // If mobile, close the sidebar when the dialog closes
                // If laptop, close the sidebar when the dialog opens
                if (sidebarType !== "desktop" && sidebarCollapsed === false) {
                  if (dOpen === true) {
                    onSidebarCollapse(true);
                  }
                }
              }}
            />
          </div>
        }
      >
        <>
          {item.name}
          {item?.badge ? (
            <Badge
              className={twMerge("ml-4", item.badge.className)}
              size="sm"
              label={item.badge.label}
            />
          ) : null}
        </>
      </Tooltip>
    );
  }

  // Return NavLink if no other conditions are met
  return item.href ? (
    <Tooltip
      disabled={!sidebarCollapsed}
      side="right"
      contentClassName="bg-hover text-white"
      arrowClassName="fill-hover"
      trigger={
        <NavLink
          to={item.href}
          // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
          state={iframeNavigationStateHack()}
        >
          {(link) => (
            <span className={getItemClasses(link.isActive, isSubItem, isMobile, sidebarCollapsed)}>
              <InnerItem item={item} />
            </span>
          )}
        </NavLink>
      }
    >
      <>
        {item.name}
        {item?.badge ? (
          <Badge
            className={twMerge("ml-4", item.badge.className)}
            size="sm"
            label={item.badge.label}
          />
        ) : null}
      </>
    </Tooltip>
  ) : null;
}

export function getItemClasses(
  isActive: boolean = false,
  isSubItem: boolean = false,
  isMobile: boolean = false,
  sidebarCollapsed: boolean
) {
  return twMerge(
    "group my-1 flex w-full min-w-12 items-center rounded-lg py-2 text-sm",
    isActive ? "rounded-lg bg-hover text-white" : "text-gray-200 ",
    isMobile ? "" : "hover:bg-hover",
    isSubItem ? "pl-11 pr-2" : sidebarCollapsed ? "justify-center" : "pl-2"
  );
}
