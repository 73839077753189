import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "~/lib/ui";
import { DataFilterItem } from "~/lib/ui/data-table/data-filter/data-filter";
import { Lozenge } from "~/lib/ui/data-table/data-filter/filters/filter-components/lozenge";

const ApprovedFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<{ approved?: boolean }>();

  const clearSearchParam = () => setSearchParams("approved", undefined);

  return (
    <>
      <DropdownMenu.Label className="flex justify-between py-1.5 pl-1.5 text-xs font-semibold leading-6">
        <Lozenge
          label={t("common:approved")}
          iconName="selectedCheck"
          variant="green"
          size="small"
        />
        {searchParams.approved !== undefined && (
          <div
            onClick={clearSearchParam}
            className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
          >
            <Icon name="remove" />
            <span>{t("common:clear")}</span>
          </div>
        )}
      </DropdownMenu.Label>

      <DropdownMenu.RadioGroup
        value={
          searchParams.approved === true
            ? "true"
            : searchParams.approved === false
              ? "false"
              : "all"
        }
        onValueChange={(v) => setSearchParams("approved", v === "true")}
      >
        <DropdownMenu.RadioItem
          onSelect={(e) => e.preventDefault()}
          className="group relative flex h-6 select-none items-center gap-4 rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
          value="true"
        >
          <div className="absolute left-0 hidden w-6 items-center justify-center group-data-[state='checked']:inline-flex">
            <Icon name="bulletFilled" size="small" />
          </div>
          <div className="absolute left-0 hidden w-6 items-center justify-center group-data-[state='unchecked']:inline-flex">
            <Icon name="bulletClear" size="small" />
          </div>
          {`${t("common:show")} ${t("common:approved", { count: 2 }).toLowerCase()}`}
        </DropdownMenu.RadioItem>
        <DropdownMenu.RadioItem
          onSelect={(e) => e.preventDefault()}
          className="group relative flex h-6 select-none items-center gap-4 rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
          value="false"
        >
          <div className="absolute left-0 hidden w-6 items-center justify-center group-data-[state='checked']:inline-flex">
            <Icon name="bulletFilled" size="small" />
          </div>
          <div className="absolute left-0 hidden w-6 items-center justify-center group-data-[state='unchecked']:inline-flex">
            <Icon name="bulletClear" size="small" />
          </div>
          {`${t("common:show")} ${t("common:not_approved", { count: 2 }).toLowerCase()}`}
        </DropdownMenu.RadioItem>
      </DropdownMenu.RadioGroup>
    </>
  );
};

const ApprovedFilterLozenge = ({ values }: { values: boolean }) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useTypedSearchParams<{ approved?: boolean }>();

  return (
    <Lozenge
      label={t("common:approved")}
      iconName="selectedCheck"
      variant="green"
      values={[{ label: values ? t("common:yes") : t("common:no"), value: values }]}
      onRemove={() => setSearchParams("approved", undefined)}
      hideLabel={true}
    />
  );
};

export const APPROVED_FILTER: DataFilterItem<boolean> = {
  identifier: "approved",
  filterFn: (selectedState: boolean, stateToCheck: boolean) => selectedState === stateToCheck,
  Lozenge: ApprovedFilterLozenge,
  render: ApprovedFilter,
};
