import { useOutletContext } from "react-router-dom";
import {
  CreateProductRequest,
  DeleteProductVariantsRequest,
  PriceMargin,
  Product,
  ProductVariant,
} from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useToasts } from "~/lib/toast/use-toasts";
import Switch from "~/lib/ui/switch";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, Fragment, useRef } from "react";
import { Radio } from "~/lib/ui/form-elements/radio";
import ProductPopover, { ProductItem } from "~/lib/products/product-popover";
import { Button, getIcon } from "~/lib/ui";
import { formatDate } from "~/lib/utils/date";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { formatCurrency, formatDecimals } from "~/lib/utils/number";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useToastOnError } from "~/lib/utils/hooks";
import { useMe } from "~/lib/auth/use-me";
import { ActionButtons } from "~/lib/ui/action-buttons";

const CACHE_PRODUCT_VARIANTS = "variants";

export default function PricingProductPage() {
  const { product } = useOutletContext<{ product: Product }>();
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const toast = useToasts();
  const queryClient = useQueryClient();

  const sellingPriceRef = useRef<HTMLInputElement | null>(null);
  const contributionRatioRef = useRef<HTMLInputElement | null>(null);

  // This is different from the product specific one. We hide and change behavior from this.
  const isManualPricingSettingEnabled =
    me.companySettings.productPricesSetting === "Manuel prissætning";

  const { isModified, isValid, getValue, setValues } = useFormState({
    schema: {
      buying_price: z.number().optional(),
      sales_price: z.number().optional(),
      contribution_ratio: z.number().optional(),
      price_type: z.enum(["manual", "cost_based"]),
    },
    initialValues: {
      buying_price: product.buyingPrice ? Number(product.buyingPrice.toFixed(2)) : undefined,
      sales_price: product.sellingPrice ? Number(product.sellingPrice.toFixed(2)) : undefined,
      contribution_ratio: initialContributionRatio(),
      price_type: product.priceType ?? "cost_based",
    },
  });

  function initialContributionRatio() {
    const buyingPrice = product.buyingPrice ? Number(product.buyingPrice.toFixed(2)) : 0;
    const salesPrice = product.sellingPrice ? Number(product.sellingPrice.toFixed(2)) : 0;
    const contributionMargin =
      salesPrice > 0 && salesPrice > buyingPrice ? salesPrice - buyingPrice : 0;

    return salesPrice > 0 ? Math.round((contributionMargin / salesPrice) * 100) : 0;
  }

  const dataQuery = useSuspenseQuery({
    queryFn: () =>
      api.productVariants({
        productId: product.id,
      }),
    queryKey: [CACHE_PRODUCT_VARIANTS],
  });
  useToastOnError(dataQuery.error);

  const productVariants = dataQuery.data?.data ?? [];

  const updatePrimaryOption = function (row: ProductVariant) {
    const latestPrice = Number(row.latestPrice.toFixed(2));
    setValues({ buying_price: latestPrice });
    api
      .setPrimaryVariant({
        setPrimaryVariantRequest: {
          productId: product.id,
          variantId: row.id,
        },
      })
      .then(() => {
        refreshData();
      });
  };

  async function refreshPrimaryOption() {
    api.productsView({ productId: product.id as string, includeVariants: true }).then((res) => {
      const primaryVariant = res.data.variants?.find((e) => e.isPrimaryBuyingOption);
      if (primaryVariant) {
        updatePrimaryOption(primaryVariant);
      }
    });
  }

  const editProductMutation = useMutation({
    mutationFn: (args: CreateProductRequest) =>
      api.editProduct({ productId: product.id, createProductRequest: args }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED", { timeout: 10000 });
      // Update product price when primary option is selected
      refreshPrimaryOption();
      refreshData();
    },
  });

  function onAddVariant(productItem: ProductItem) {
    editProductMutation.mutate({
      variantType: productItem.type,
      variantId: productItem.item.id,
    });
  }

  function handleSave() {
    editProductMutation.mutate({
      buyingPrice: getValue("buying_price"),
      sellingPrice: getValue("sales_price"),
      priceType: getValue("price_type"),
    });
  }

  function handleManualSellingPrice(isChecked: boolean) {
    const buyingPrice = getValue("buying_price");
    if (!isChecked && buyingPrice !== undefined) {
      updateBuyingAndSellingPrice(buyingPrice);
    }
    setValues({ price_type: isChecked ? "manual" : "cost_based" });
  }

  function calculateByPriceMargin(buyingPrice: number): number {
    const priceMargins = me.priceMargins;
    const priceMargin = priceMargins.find(
      (m: PriceMargin) => m.amountFrom <= buyingPrice && m.amountTo >= buyingPrice
    );

    if (
      !priceMargin ||
      !priceMargin.percentageRatio ||
      Math.abs(100 - priceMargin.percentageRatio) < 0.01
    ) {
      return buyingPrice; // No change if no valid margin is found
    }

    const margin = 1 / (1 - priceMargin.percentageRatio / 100);

    return Number((margin * buyingPrice).toFixed(2));
  }

  function updateBuyingAndSellingPrice(buyingPrice: number | FormEvent<HTMLInputElement>) {
    if (typeof buyingPrice !== "number") {
      buyingPrice = 0;
    }
    if (isManualPricingSettingEnabled) {
      setValues({ buying_price: Number(buyingPrice) ?? 0 });
      return;
    }
    const sellingPrice = calculateByPriceMargin(buyingPrice);
    const contributionMargin =
      sellingPrice > 0 && sellingPrice > buyingPrice ? sellingPrice - buyingPrice : 0;
    const contributionRatio =
      sellingPrice > 0
        ? Math.round((contributionMargin / sellingPrice) * 100)
        : getValue("contribution_ratio");
    // Only update selling price if the price type is cost based
    if (getValue("price_type") === "cost_based") {
      setValues({
        buying_price: Number(buyingPrice) ?? 0,
        sales_price: Number(sellingPrice) ?? 0,
        contribution_ratio: Number(contributionRatio),
      });
    } else {
      setValues({
        buying_price: Number(buyingPrice) ?? 0,
        contribution_ratio: Number(contributionRatio),
      });
    }
    if (sellingPriceRef.current) {
      sellingPriceRef.current.value = formatDecimals(sellingPrice);
    }
    if (contributionRatioRef.current) {
      contributionRatioRef.current.value = contributionRatio ? contributionRatio.toString() : "0";
    }
  }

  function handleSalesPrice(val: number | FormEvent<HTMLInputElement>) {
    if (typeof val !== "number") {
      val = 0;
    }

    const buyingPrice = getValue("buying_price") ?? 0;
    const contributionMargin = val > 0 && val > buyingPrice ? val - buyingPrice : 0;
    const contributionRatio =
      val > 0 ? Math.round((contributionMargin / val) * 100) : getValue("contribution_ratio");
    if (contributionRatioRef.current) {
      contributionRatioRef.current.value = contributionRatio ? contributionRatio.toString() : "0";
    }
    setValues({ sales_price: val ?? 0, contribution_ratio: contributionRatio });
  }

  function handleContributionRatio(val: number | FormEvent<HTMLInputElement>) {
    if (typeof val !== "number") {
      val = 0;
    }
    const buyingPrice = getValue("buying_price") ?? 0;
    const salesPrice = buyingPrice > 0 ? Math.round(buyingPrice / (1 - val / 100)) : 0;
    if (sellingPriceRef.current) {
      sellingPriceRef.current.value = formatDecimals(salesPrice);
    }
    setValues({ contribution_ratio: val, sales_price: salesPrice ?? 0 });
  }

  const variantDelete = useMutation({
    mutationFn: (args: DeleteProductVariantsRequest) =>
      api.deleteProductVariants({ deleteProductVariantsRequest: args }),
    onSuccess: () => {
      refreshData();
    },
    onError: () => {
      toast.show({
        title: t("products:variant_delete_error_title"),
        description: t("products:variant_delete_error_description"),
        Icon: getIcon("warningCircle"),
        variant: "error",
        timeout: 5000,
      });
    },
  });

  const handleDeleteVariant = async (productVariant: ProductVariant) => {
    if (!productVariant.id) return;
    const variantType = productVariant.type === "expense_line" ? "expenseLines" : "vendorProducts";
    await variantDelete.mutateAsync({
      productId: product.id,
      [variantType]: [productVariant.id],
    });
    await queryClient.invalidateQueries({
      queryKey: [CACHE_PRODUCT_VARIANTS],
    });
  };

  const buyingPriceDisabled =
    productVariants.length > 0 ||
    (productVariants.length > 0 && getValue("price_type") === "cost_based");

  const onToggleBuyingOption = (e: ChangeEvent<HTMLInputElement>, row: ProductVariant) => {
    updatePrimaryOption(row);
  };

  function refreshData() {
    queryClient.invalidateQueries({
      queryKey: ["product", product.id],
    });
    queryClient.invalidateQueries({
      queryKey: [CACHE_PRODUCT_VARIANTS],
    });
  }

  const MANUAL_PRICING_DISABLED =
    (getValue("price_type") === "cost_based" && productVariants.length <= 0) ||
    (productVariants.length > 0 && getValue("price_type") === "cost_based");

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            disabled={!isModified || !isValid}
            variant="tertiary"
            tabIndex={0}
            onClick={handleSave}
          >
            {t("common:save")}
          </Button>
        }
      >
        {t("products:pricing")}
      </TabHeading>
      <div className="flex-1">
        <div className="mb-2 text-gray-500">{t("projects:pricing")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                {buyingPriceDisabled ? (
                  <NumberFormatInput
                    label={t("products:cost_price")}
                    className="text-right text-sm"
                    name="buying_price_disabled"
                    value={getValue("buying_price")}
                    onChange={(v) => updateBuyingAndSellingPrice(v)}
                    maximumDecimals={2}
                    disabled
                  />
                ) : (
                  <NumberFormatInput
                    label={t("products:cost_price")}
                    className="text-right text-sm"
                    name="buying_price"
                    defaultValue={getValue("buying_price")}
                    onChange={(v) => updateBuyingAndSellingPrice(v)}
                    maximumDecimals={2}
                  />
                )}
                {!isManualPricingSettingEnabled && (
                  <div className="flex-1 pb-4">
                    <Switch
                      label={t("products:manual_selling_price_label")}
                      defaultChecked={getValue("price_type") === "manual"}
                      onCheckedChange={(v) => handleManualSellingPrice(v)}
                      className="justify-between sm:justify-start"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  ref={sellingPriceRef}
                  label={t("products:selling_price")}
                  className="text-right text-sm"
                  name="edit_selling_price"
                  defaultValue={getValue("sales_price") ?? ""}
                  onChange={(v) => (MANUAL_PRICING_DISABLED ? undefined : handleSalesPrice(v))}
                  disabled={MANUAL_PRICING_DISABLED}
                />
              </div>
              <div className="w-1/16 pt-8 text-center">{t("common:or", "eller")}</div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  ref={contributionRatioRef}
                  defaultValue={getValue("contribution_ratio") ?? ""}
                  label={t("common:contribution_ratio")}
                  className="text-right text-sm"
                  onChange={(v) =>
                    MANUAL_PRICING_DISABLED ? undefined : handleContributionRatio(v)
                  }
                  disabled={MANUAL_PRICING_DISABLED}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-2">
          <div className="mb-10"></div>
          <div className="mb-2 mr-2 mt-5">
            <ProductPopover
              triggerRender={() => (
                <Button variant="secondary" className="flex items-center gap-3">
                  <span>{t("products:variant.add.title")}</span>
                </Button>
              )}
              onSelect={(item) => {
                onAddVariant(item);
              }}
              options={{ allowedProductTypes: ["expense_line", "vendor_product"] }}
            />
          </div>
        </div>
        <div className="-mb-5 text-gray-500">{t("products:buying_option")}</div>
        <div className="table">
          <table className="mt-0 text-left">
            <thead>
              <tr>
                <th>{t("products:variants.primary")}</th>
                <th>{t("common:name")}</th>
                <th>{t("products:variants.latest_update")}</th>
                <th>{t("products:variants.latest_price")}</th>
                <th>{t("common:vendor")}</th>
                <th>{t("common:type")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productVariants.map((item) => (
                <Fragment key={item.id}>
                  <tr>
                    <td>
                      <div className="flex items-center gap-4">
                        <Radio
                          checked={item.isPrimaryBuyingOption}
                          onChange={(e) => onToggleBuyingOption(e, item)}
                        />
                      </div>
                    </td>
                    <td className="font-semibold">
                      <div className="items-center">{item.name}</div>
                    </td>
                    <td>
                      <div className="items-center">
                        {formatDate(item.latestUpdated, getResolvedLanguage(), { shortDate: true })}
                      </div>
                    </td>
                    <td className="items-center">
                      {formatCurrency(item.latestPrice, { maximumFractionDigits: 2 })}
                    </td>
                    <td className="items-center">
                      <div>{item.vendor?.name}</div>
                    </td>
                    <td className="items-center">
                      <div>{t(`products:${item.type}`)}</div>
                    </td>
                    <td className="items-center">
                      <ActionButtons
                        actions={[
                          {
                            Icon: getIcon("delete"),
                            label: t("products:variant.modal.delete.title"),
                            confirm: {
                              action: "delete",
                              entity: "product",
                            },
                            onClick: () => handleDeleteVariant(item),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
