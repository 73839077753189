import { useLocale } from "~/lib/utils/date";
import { Day } from "~/lib/utils/date/date-utils";
import { twMerge } from "tailwind-merge";
import Tooltip from "~/lib/ui/tooltip";
import { useTranslation } from "react-i18next";

export function WeekSectionHeader({ weekDates }: { weekDates: Array<Day> }) {
  const { dayName, format } = useLocale();
  const { t } = useTranslation();

  return (
    <thead>
      <tr className="divide-x border-b-4 font-semibold print:divide-x-2">
        <th className="min-w-48 px-3 py-1.5 text-left text-sm">
          {t("common:project", { count: 1 })}
        </th>
        {weekDates.map((day) => (
          <th key={`d-${day.date.getTime()}`} className="w-24 min-w-24 p-0">
            <Tooltip
              disabled={!day.isHoliday}
              trigger={
                <div
                  className={twMerge(
                    "flex-col items-center justify-start gap-0.5 px-2 py-1.5",
                    day.isWeekend && "bg-shade-300",
                    day.isHoliday && "bg-yellow-50",
                    day.isOutOfRange && "bg-shade-300"
                  )}
                >
                  <div className="text-sm">{dayName(day.date, { capitalizeFirst: true })}</div>
                  <div className="text-xs font-normal">
                    {format(day.date, {
                      excludeTime: true,
                      excludeYear: true,
                      shortMonth: true,
                    })}
                  </div>
                </div>
              }
            >
              {day.holidayName ?? t("common:not_available")}
            </Tooltip>
          </th>
        ))}
      </tr>
    </thead>
  );
}
