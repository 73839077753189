import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "~/lib/ui";
import { Suspense } from "react";
import { ProjectFilterSubmenu } from "~/lib/ui/data-table/data-filter/filters/filter-components/project-filter-submenu";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { useDebouncedValue } from "~/lib/debounce/use-debounce";
import { DataFilterItem } from "~/lib/ui/data-table/data-filter/data-filter";
import { Lozenge } from "~/lib/ui/data-table/data-filter/filters/filter-components/lozenge";
import { Project } from "@apacta/sdk";
import useInfiniteProjects from "~/lib/projects/use-infinite-projects";

export const PROJECT_FILTER_IDENTIFIER = "projectIds";

const ProjectFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    [PROJECT_FILTER_IDENTIFIER]?: Array<string>;
  }>();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue("");

  const clearSearchParam = () => setSearchParams(PROJECT_FILTER_IDENTIFIER, undefined);

  return (
    <>
      <DropdownMenu.Label className="flex justify-between py-1.5 pl-1.5 text-xs font-semibold leading-6">
        <Lozenge
          label={t("common:project", { count: 2 })}
          size="small"
          iconName="project"
          variant="orange"
        />
        {searchParams[PROJECT_FILTER_IDENTIFIER] !== undefined && (
          <div
            onClick={clearSearchParam}
            className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
          >
            <Icon name="remove" />
            <span>{t("common:clear")}</span>
          </div>
        )}
      </DropdownMenu.Label>

      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger className=" group relative flex h-6 select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[highlighted]:data-[state=open]:bg-shade-100 data-[state=open]:bg-shade-100">
          <div className="absolute left-0 inline-flex w-6 items-center justify-center">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
              {searchParams[PROJECT_FILTER_IDENTIFIER]?.length ?? 0}
            </div>
          </div>
          {t("common:select_entity", { entity: t("common:project", { count: 2 }).toLowerCase() })}
          <div className=" group- ml-auto pl-[20px] ">
            <Icon name="chevronRight" />
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade min-w-64 rounded-md border bg-white p-1.5 shadow-md will-change-[opacity,transform]"
            sideOffset={2}
            alignOffset={-5}
          >
            <div className="relative flex items-center">
              <Icon name="search" className="absolute left-1.5 h-4 w-4" />
              <input
                type="text"
                onChange={(e) => setValue(e.currentTarget.value)}
                defaultValue={debouncedValue}
                placeholder={t("common:search")}
                className="w-full border-none bg-transparent pb-0.5 pl-7 pr-1.5 pt-1 text-sm outline-none ring-0 focus:outline-none focus:ring-0"
              />
            </div>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
            <Suspense fallback={<SkeletonLoader template="list" />}>
              <ProjectFilterSubmenu query={debouncedValue} />
            </Suspense>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
};

const ProjectFilterLozenge = ({ values }: { values: Array<string> }) => {
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    [PROJECT_FILTER_IDENTIFIER]?: Array<string>;
  }>();
  const { t } = useTranslation();

  const { projects } = useInfiniteProjects({ includedProjectIds: values });

  const shownProjects = projects?.filter((d) => values.includes(d.id));

  const handleRemoveProject = (v: string) => {
    const newValue = searchParams[PROJECT_FILTER_IDENTIFIER]?.filter((id) => id !== v);

    // If there are no values, remove the search param
    if (!newValue || newValue.length === 0) {
      setSearchParams(PROJECT_FILTER_IDENTIFIER, undefined);
      return;
    }

    // Otherwise, update the search param
    setSearchParams(PROJECT_FILTER_IDENTIFIER, newValue);
  };

  if (!shownProjects.length) return null;

  return (
    <Lozenge
      label={t("common:project", { count: 1 })}
      iconName="project"
      variant="orange"
      values={shownProjects?.map((d) => ({ label: d.fullName ?? "", value: d.id }))}
      onRemove={(v) => handleRemoveProject(v)}
      hideLabel={true}
    />
  );
};

export const PROJECT_FILTER: DataFilterItem<Array<string>> = {
  identifier: PROJECT_FILTER_IDENTIFIER,
  entity: "project",
  filterFn: (selectedValues: Array<string>, project: Project) =>
    !!project.id && selectedValues.includes(project.id),
  Lozenge: ProjectFilterLozenge,
  render: ProjectFilter,
};
