import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { RichTextEditor, RichTextEditorRef } from "~/lib/ui/rich-text-editor";
import { PencilIcon } from "@heroicons/react/24/outline";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { TextTemplate } from "@apacta/sdk";
import { useToastOnError } from "~/lib/utils/hooks";
import { useTranslation } from "react-i18next";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export default function TemplateModal({
  onSubmit,
  onClose,
}: {
  onSubmit: (data: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [templateQuery, setTemplateQuery] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<TextTemplate | undefined>(undefined);
  const rtRef = useRef<RichTextEditorRef>(null);
  const [editorValidState, setEditorValidState] = useState<boolean>(true);

  const api = useAPI();

  const templateQ = useQuery({
    queryFn: () => api.getTextTemplates({ q: templateQuery, sort: "created", direction: "desc" }),
    queryKey: ["offer-templates", templateQuery],
  });

  useToastOnError(templateQ.error);

  const handleSelectTemplate = (_: string | undefined, template?: TextTemplate) => {
    if (rtRef.current) {
      setSelectedTemplate(template);
      rtRef.current.setEditorMarkdown(template?.content ?? "");
    }
  };

  const handleSubmit = async () => {
    if (rtRef.current) {
      onSubmit(await rtRef.current.getEditorMarkdown());
    }
  };

  const availableTemplates = templateQ.data?.data || [];

  return (
    <div className="flex flex-col gap-8">
      <DialogHeader title={t("common:template", { count: 2 })} Icon={PencilIcon} />
      <div>
        <SelectionCombobox<TextTemplate>
          controlled
          required
          data={availableTemplates}
          loading={templateQ.isFetching}
          onSelect={handleSelectTemplate}
          valueFn={(e) => e.id}
          value={selectedTemplate?.id as string | undefined}
          placeholder={t("common:search_template_placeholder")}
          labelFn={(e) => e.title ?? ""}
          label={t("common:pick_entity", {
            entity: t("common:template", { count: 1 }).toLowerCase(),
          })}
        />
      </div>

      <RichTextEditor
        ref={rtRef}
        limitHeight={true}
        onChange={(text, html, valid) => setEditorValidState(valid)}
      />

      <DialogFooter
        primary={{
          label: t("common:insert"),
          onClick: () => handleSubmit(),
          disabled: !selectedTemplate || !editorValidState,
        }}
        onClose={onClose}
      />
    </div>
  );
}
