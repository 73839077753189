import { Dialog, Icon } from "~/lib/ui";
import { AISupportDialog } from "~/lib/support/ai-support";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "~/lib/navigation/navigation-layout/sidebar-context";
import { twMerge } from "tailwind-merge";

export default function AiSupportItem() {
  const { t } = useTranslation();
  const { sidebarCollapsed, onVisibilityChange } = useSidebarContext();

  return (
    <div className={twMerge(sidebarCollapsed ? "mb-4" : "mb-8 px-4")}>
      <Dialog
        render={() => <AISupportDialog />}
        className="w-full px-2"
        onOpenChange={() => onVisibilityChange(false)}
        trigger={
          <div role="button" tabIndex={0} className="group relative">
            <div className="animate-tilt absolute -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] opacity-70 blur-md transition-all duration-200 group-hover:-inset-1 group-hover:opacity-100 group-focus:opacity-100"></div>
            <div className="relative rounded-xl bg-gradient-to-r from-[#44BCFF]/50 via-[#FF44EC]/50 to-[#FF675E]/50 p-0.5 shadow-lg transition-all duration-200 group-hover:from-[#44BCFF] group-hover:via-[#FF44EC] group-hover:to-[#FF675E]">
              <div
                className={twMerge(
                  "flex w-full gap-4 rounded-xl bg-primary text-sm font-medium text-white",
                  sidebarCollapsed
                    ? "aspect-square items-center justify-center px-1 py-1"
                    : "px-3 py-2"
                )}
              >
                <Icon name="sparkles" className="h-5 w-auto" />
                {!sidebarCollapsed && <span>{t("common:ask_ai_support")}</span>}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
