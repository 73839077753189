import { twMerge } from "tailwind-merge";
import { Popover } from "~/lib/ui/popover/popover";
import { DateRangeSelect } from "~/lib/ui/calendar/date-range-select";
import { useLocale } from "~/lib/utils/date";
import { Button, getIcon, Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { DateRange } from "~/lib/utils/date/date-utils.types";

export function DateRangePopover({
  dateFrom,
  dateTo,
  resetLabel,
  onReset,
  onChange,
  allowUnset,
  compact = true,
  allowToDateInFuture = false,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  onReset?: () => void;
  resetLabel?: string;
  onChange: (range: DateRange) => void;
  allowUnset?: boolean;
  compact?: boolean;
  allowToDateInFuture?: boolean;
}) {
  return (
    <Popover
      config={{
        align: "start",
        side: "bottom",
      }}
      triggerRender={() =>
        compact ? (
          <DateRangeTriggerCompact dateFrom={dateFrom} dateTo={dateTo} />
        ) : (
          <DateRangeTriggerDefault dateFrom={dateFrom} dateTo={dateTo} />
        )
      }
    >
      {(close) => (
        <DateRangeSelect
          onSubmit={onChange}
          onClose={close}
          dateFrom={dateFrom}
          dateTo={dateTo}
          resetLabel={resetLabel}
          onReset={onReset}
          allowUnset={allowUnset}
          allowToDateInFuture={allowToDateInFuture}
        />
      )}
    </Popover>
  );
}

const DateRangeTriggerCompact = ({ dateFrom, dateTo }: { dateFrom?: Date; dateTo?: Date }) => {
  const { format } = useLocale();
  const { t } = useTranslation();
  return (
    <div>
      <div
        className={twMerge(
          "relative flex flex-row items-center text-nowrap rounded-md border border-gray-200 bg-white px-2 py-1 text-xs hover:border-hover hover:text-hover focus:border-transparent focus-visible:outline-none"
        )}
      >
        <Icon name="calendar" className="mr-2 h-4 w-4" />
        {!!dateFrom && !!dateTo ? (
          <span>{`${format(dateFrom, { shortDate: true })} - ${format(dateTo, { shortDate: true })}`}</span>
        ) : (
          <span>{t("ui:date_range_picker.entire_period")}</span>
        )}
      </div>
    </div>
  );
};

const DateRangeTriggerDefault = ({ dateFrom, dateTo }: { dateFrom?: Date; dateTo?: Date }) => {
  const { format } = useLocale();
  const { t } = useTranslation();
  return (
    <div>
      <Button as="span" variant="secondary" Icon={getIcon("calendar")}>
        {!!dateFrom && !!dateTo ? (
          <span>{`${format(dateFrom, { shortDate: true })} - ${format(dateTo, { shortDate: true })}`}</span>
        ) : (
          <span>{t("ui:date_range_picker.entire_period")}</span>
        )}
      </Button>
    </div>
  );
};
