import { CreateInvoiceOperationRequest } from "@apacta/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAPI } from "~/lib/api";
import { getIcon, Icon } from "~/lib/ui";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { linkToInvoiceV3 } from "~/lib/utils";
import { CreateInvoiceHourly } from "./create-invoice-hourly";
import { ComponentProps, useState } from "react";
import { Boundary } from "~/lib/ui/boundary";
import { CreateInvoiceFixed } from "./create-invoice-fixed";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { CreateInvoiceExistingDrafts } from "./create-invoice-existing-drafts";
import { ProjectStatusEnum } from "~/lib/gql";
import { CACHE_PROJECTS } from "~/pages/projects";

export function CreateInvoiceDialog({
  projectId,
  onClose,
  fromDate,
  toDate,
}: {
  onClose: () => void;
  projectId?: string;
  fromDate?: ComponentProps<typeof CreateInvoiceHourly>["fromDate"];
  toDate?: ComponentProps<typeof CreateInvoiceHourly>["toDate"];
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const navigate = useNavigate();

  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(projectId);

  const dataM = useMutation({
    mutationFn: (args: CreateInvoiceOperationRequest) => api.createInvoice(args),
    onSuccess: (res) => {
      if (res.data.id) {
        navigate(linkToInvoiceV3(res.data.id));
      }
      onClose();
    },
  });

  const projectQ = useQuery({
    queryKey: [CACHE_PROJECTS, selectedProjectId],
    queryFn: () => api.iGetProject({ projectId: selectedProjectId as string }),
    enabled: selectedProjectId !== undefined,
  });

  const project = projectQ.data?.data;

  // If no selection, and projectId is provided, select it

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          defaultValue: "Create {{entity}}",
          replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={getIcon("invoice")}
      />
      {!projectId && (
        <div className="mb-4">
          <ProjectSelection
            onSelect={(spid) => setSelectedProjectId(spid ?? undefined)}
            defaultValue={projectId}
            requestOverride={{
              projectStatus: [ProjectStatusEnum.Open, ProjectStatusEnum.ReadyForBilling],
            }}
          />
        </div>
      )}
      <div className="min-h-40">
        {selectedProjectId === undefined && (
          <div className="flex h-full w-full items-center justify-center text-gray-400">
            <Icon name="pointUp" className="mr-2 h-6 w-6" />
            {t(
              "invoices:select_project_to_create_invoice",
              "Select a project to create an invoice"
            )}
          </div>
        )}
        {selectedProjectId !== undefined && (
          <Boundary
            suspenseProps={{
              fallback: (
                <>
                  <p>{t("invoices:loading_additional_project_information")}...</p>
                  <SkeletonLoader className="h-[5.5em] w-full">
                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="1.5em" />
                    <rect x="0" y="2em" rx="4" ry="4" width="100%" height="1.5em" />
                    <rect x="0" y="4em" rx="4" ry="4" width="100%" height="1.5em" />
                  </SkeletonLoader>
                </>
              ),
            }}
          >
            {project && !project?.isFixedPrice && (
              <CreateInvoiceHourly
                projectId={selectedProjectId}
                onSubmit={dataM.mutateAsync}
                onClose={onClose}
                fromDate={fromDate}
                toDate={toDate}
                project={project}
              />
            )}
            {project && project?.isFixedPrice && (
              <CreateInvoiceFixed
                projectId={selectedProjectId}
                onSubmit={dataM.mutateAsync}
                onClose={onClose}
              />
            )}
            <CreateInvoiceExistingDrafts projectId={selectedProjectId} />
          </Boundary>
        )}
      </div>
    </>
  );
}
