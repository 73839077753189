import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";
import { Fragment, ReactElement } from "react";
import { IconProp } from "~/lib/ui/types";
import { twMerge } from "tailwind-merge";

type ContextMenuItem_Item = {
  type: "item";
  label: string;
  onSelect: () => void;
  disabled?: boolean;
  Icon?: IconProp;
};

type ContextMenuItem_Separator = {
  type: "separator";
};

export type ContextMenuItem = ContextMenuItem_Item | ContextMenuItem_Separator;

/**
 * Creates a Context Menu boundary using `children` with the supplied `items` as menu options
 * Commonly known as a right click menu...
 * @param items items to be shown in context menu
 * @param className additional classes to apply to trigger element
 * @param children the trigger element
 * @param triggerAsChild
 */
export default function ContextMenu({
  items,
  className,
  children,
  triggerAsChild = false,
}: {
  items: Array<ContextMenuItem>;
  className?: string;
  children: ReactElement;
  triggerAsChild?: boolean;
}) {
  return (
    <ContextMenuPrimitive.Root>
      <ContextMenuPrimitive.Trigger asChild={triggerAsChild} className={twMerge(className)}>
        {children}
      </ContextMenuPrimitive.Trigger>
      <ContextMenuPrimitive.Portal>
        <ContextMenuPrimitive.Content
          className={twMerge(
            "min-w-[12em] overflow-hidden rounded-lg border bg-white text-sm shadow-sm"
          )}
          onContextMenu={(e) => e.preventDefault()}
        >
          {items.map((item, index) => (
            <Fragment key={`context-menu-${index}`}>
              {item.type === "item" && (
                <ContextMenuPrimitive.Item
                  disabled={item.disabled}
                  onSelect={() => item.onSelect()}
                  className={twMerge(
                    "m-1 flex cursor-pointer items-center justify-between rounded-md highlighted:bg-shade-100 highlighted:outline-0",
                    item.disabled && "text-zinc-300"
                  )}
                >
                  <span className="flex px-3  py-2">{item.label}</span>
                  {item.Icon && <item.Icon className="mr-3 h-4 w-auto" />}
                </ContextMenuPrimitive.Item>
              )}
              {item.type === "separator" && (
                <ContextMenuPrimitive.Separator className="mx-1 my-2 h-px bg-zinc-200" />
              )}
            </Fragment>
          ))}
        </ContextMenuPrimitive.Content>
      </ContextMenuPrimitive.Portal>
    </ContextMenuPrimitive.Root>
  );
}
