import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "~/lib/ui";
import { Suspense } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { useDebouncedValue } from "~/lib/debounce/use-debounce";
import { DataFilterItem } from "~/lib/ui/data-table/data-filter/data-filter";
import { Lozenge } from "~/lib/ui/data-table/data-filter/filters/filter-components/lozenge";
import { TaskLabelFilterSubmenu } from "~/lib/ui/data-table/data-filter/filters/filter-components/task-label-submenu";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { LabelEntity } from "@apacta/sdk";
import { Task } from "~/lib/planning";

const identifier = "taskLabel";

const TaskLabelFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<{ [identifier]?: Array<string> }>();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue("");

  const clearSearchParam = () => setSearchParams(identifier, undefined);

  return (
    <>
      <DropdownMenu.Label className="flex justify-between py-1.5 pl-1.5 text-xs font-semibold leading-6">
        <Lozenge
          label={`${t("common:task", { count: 1 })} ${t("common:label", { count: 2 }).toLowerCase()}`}
          size="small"
          iconName="label"
          variant="yellow"
        />
        {searchParams[identifier] !== undefined && (
          <div
            onClick={clearSearchParam}
            className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
          >
            <Icon name="remove" />
            <span>{t("common:clear")}</span>
          </div>
        )}
      </DropdownMenu.Label>

      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger className=" group relative flex h-6 select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[highlighted]:data-[state=open]:bg-shade-100 data-[state=open]:bg-shade-100">
          <div className="absolute left-0 inline-flex w-6 items-center justify-center">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
              {searchParams[identifier]?.length ?? 0}
            </div>
          </div>
          {t("common:select_entity", { entity: t("common:label", { count: 2 }).toLowerCase() })}
          <div className=" group- ml-auto pl-[20px] ">
            <Icon name="chevronRight" />
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade min-w-64 rounded-md border bg-white p-1.5 shadow-md will-change-[opacity,transform]"
            sideOffset={2}
            alignOffset={-5}
          >
            <div className="relative flex items-center">
              <Icon name="search" className="absolute left-1.5 h-4 w-4" />
              <input
                type="text"
                onChange={(e) => setValue(e.currentTarget.value)}
                defaultValue={debouncedValue}
                placeholder={t("common:search")}
                className="w-full border-none bg-transparent pb-0.5 pl-7 pr-1.5 pt-1 text-sm outline-none ring-0 focus:outline-none focus:ring-0"
              />
            </div>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
            <Suspense fallback={<SkeletonLoader template="list" />}>
              <TaskLabelFilterSubmenu query={debouncedValue} />
            </Suspense>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
};

const TaskLabelFilterLozenge = ({ values }: { values: Array<string> }) => {
  const [searchParams, setSearchParams] = useTypedSearchParams<{ [identifier]?: Array<string> }>();
  const { t } = useTranslation();

  const { taskLabels } = useCategoryLabels("task");

  const data = taskLabels.filter((label) => values.includes(label.id));

  const handleRemoveTaskLabel = (v: string) => {
    const newValue = searchParams[identifier]?.filter((id) => id !== v);

    // If there are no values, remove the search param
    if (!newValue || newValue.length === 0) {
      setSearchParams(identifier, undefined);
      return;
    }

    // Otherwise, update the search param
    setSearchParams(identifier, newValue);
  };

  const getLabelText = (id: string) => {
    const label = taskLabels.find((l) => l.id === id);
    return label?.text ?? "";
  };

  const getLabelBgColor = (id: string) => {
    const label = taskLabels.find((l) => l.id === id);
    return label?.bgColor ?? "";
  };

  return (
    <Lozenge
      label={`${t("common:task", { count: 1 })} ${t("common:label", { count: 1 }).toLowerCase()}`}
      iconName="label"
      variant="yellow"
      values={data?.map((d) => ({ label: d.text, value: d.id }))}
      renderValue={({ value, onRemove }) => (
        <UICategoryLabel
          id={value.value}
          text={getLabelText(value.value)}
          bgColor={getLabelBgColor(value.value)}
          onRemove={onRemove}
        />
      )}
      onRemove={(v) => handleRemoveTaskLabel(v)}
      hideLabel={true}
    />
  );
};

export const TASK_LABEL_FILTER: DataFilterItem<Array<string>> = {
  identifier,
  entity: "task",
  filterFn: (selectedValues: Array<string>, task: Task) =>
    task.labels?.some((v: LabelEntity) => selectedValues.includes(v.id)) ?? false,
  Lozenge: TaskLabelFilterLozenge,
  render: TaskLabelFilter,
};
