import { Fragment } from "react";
import { Lozenge } from "~/lib/ui/data-table/data-filter/filters/filter-components/lozenge";
import { FilterType } from "~/lib/ui/data-table/data-filter/data-filter";
import { DataFilterLozenge } from "~/lib/ui/data-table/data-filter/data-filter-lozenge";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useTranslation } from "react-i18next";

export default function DataFilterDisplay({ filters }: { filters: Array<FilterType> }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<Record<string, any>>();

  const appliedFilters = filters.filter((filter) => searchParams[filter.identifier] !== undefined);

  const handleRemoveAllFilters = () => {
    filters.forEach((filter) => setSearchParams(filter.identifier, undefined));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {appliedFilters.map((filter) => (
        <Fragment key={filter.identifier}>
          <DataFilterLozenge filter={filter} />
        </Fragment>
      ))}
      {appliedFilters.length > 0 && (
        <Lozenge
          label={`${t("common:clear")} ${t("common:filter", { count: appliedFilters.length }).toLowerCase()}`}
          iconName="remove"
          variant="red"
          onClick={handleRemoveAllFilters}
        />
      )}
    </div>
  );
}
