import { useQuery } from "@tanstack/react-query";
import { Companies_Vendor_List_Sort_Fields, Pagination_Direction, useGraphQL } from "~/lib/gql";
import { DateInterval, VendorSelectionQuery } from "~/lib/gql/generated/graphql";
import { useMemo, useState } from "react";

/**
 * ONLY USED FOR PROCUREMENT
 * DO NOT USE IN OTHER PLACES
 */

const CACHE_COMPANIES_VENDORS = "companies-vendors";

export function useCompaniesVendors(dR?: DateInterval) {
  const [dateRange, setDateRange] = useState<DateInterval | undefined>(dR);
  const sdk = useGraphQL();

  const companiesVendorsQuery = useQuery({
    queryKey: [CACHE_COMPANIES_VENDORS, dateRange],
    queryFn: () =>
      sdk.vendorSelection({
        params: {
          sortBy: Companies_Vendor_List_Sort_Fields.VendorName,
          direction: Pagination_Direction.Asc,
          withExpensesWithinDateInterval: dateRange,
        },
      }),
  });

  const companiesVendors: VendorSelectionQuery["companiesVendors"]["edges"] = useMemo(() => {
    return companiesVendorsQuery.data?.companiesVendors.edges ?? [];
  }, [companiesVendorsQuery.data?.companiesVendors.edges]);

  return { companiesVendors, isFetching: companiesVendorsQuery.isFetching, setDateRange };
}
