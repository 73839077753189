import { useRef } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { Icon } from "~/lib/ui";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import Tooltip from "~/lib/ui/tooltip";
import { linkToEmployee } from "~/lib/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEmployees } from "~/lib/employees/use-employees";

export function SingleEmployeeFilterSubmenu({ query }: { query: string }) {
  const [searchParams, setSearchParams] = useTypedSearchParams<{ employeeId?: string }>();
  const { t } = useTranslation();

  const lastShownElement = useRef<HTMLDivElement | null>(null);

  const { employees } = useEmployees({
    requestOverride: {
      q: query,
    },
  });

  // Split projects into selected and not selected array based on searchParams.projectId using .reduce
  const selectedEmployee = employees.find((project) => searchParams.employeeId === project.id);
  const notSelectedEmployees = employees.filter(
    (project) => project.id !== searchParams.employeeId
  );

  const handleSelectEmployee = (employeeId: string) => {
    if (employeeId === searchParams.employeeId) {
      setSearchParams("employeeId", undefined);
      return;
    }

    setSearchParams("employeeId", employeeId);
  };

  return (
    <>
      <ScrollArea.Root>
        <ScrollArea.Viewport className="max-h-52 w-full max-w-64">
          <DropdownMenu.RadioGroup
            value={searchParams.employeeId}
            onValueChange={handleSelectEmployee}
          >
            <DropdownMenu.Label className="flex items-center justify-between pl-1.5 pr-4 text-xs font-semibold leading-6">
              <div>{`${t("common:selected", { count: 1 })} ${t("common:employee", { count: 1 }).toLowerCase()}`}</div>
            </DropdownMenu.Label>

            {!!selectedEmployee && (
              <DropdownMenu.RadioItem
                key={selectedEmployee.id}
                value={selectedEmployee.id}
                onSelect={(e) => e.preventDefault()}
                className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
              >
                <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                  <Icon name="selectedCheck" />
                </DropdownMenu.ItemIndicator>
                <Tooltip
                  trigger={<div className="line-clamp-1">{selectedEmployee.fullName}</div>}
                  delay={500}
                >
                  <Link
                    to={linkToEmployee(selectedEmployee.id)}
                    target="_blank"
                    className="flex items-center gap-2 text-sm hover:underline"
                  >
                    <div>{selectedEmployee.fullName}</div>
                    <Icon name="externalLink" className="h-4 w-4" />
                  </Link>
                </Tooltip>
              </DropdownMenu.RadioItem>
            )}

            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
            <DropdownMenu.Label className="flex justify-between pl-1.5 text-xs font-semibold leading-6">
              <div>{`${t("common:not")} ${t("common:selected", { count: 2 }).toLowerCase()} ${t("common:employee", { count: 2 }).toLowerCase()}`}</div>
            </DropdownMenu.Label>

            {notSelectedEmployees.map((employee, idx) => (
              <DropdownMenu.RadioItem
                key={employee.id}
                ref={idx === notSelectedEmployees.length - 1 ? lastShownElement : null}
                value={employee.id}
                onSelect={(e) => e.preventDefault()}
                className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
              >
                <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                  <Icon name="selectedCheck" />
                </DropdownMenu.ItemIndicator>
                <Tooltip
                  trigger={<div className="line-clamp-1">{employee.fullName}</div>}
                  delay={500}
                >
                  <Link
                    to={linkToEmployee(employee.id)}
                    target="_blank"
                    className="flex items-center gap-2 text-sm hover:underline"
                  >
                    <div>{employee.fullName}</div>
                    <Icon name="externalLink" className="h-4 w-4" />
                  </Link>
                </Tooltip>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="duration-[160ms] flex touch-none select-none bg-transparent p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
        >
          <ScrollArea.Thumb className="relative flex-1 rounded-sm bg-black/30 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-black/50" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </>
  );
}
