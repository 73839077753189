import { InvoiceActivityLog } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Icon, IconName } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";

export function InvoiceActivityLogItem({ item }: { item: InvoiceActivityLog }) {
  const locale = useLocale();
  const { t } = useTranslation();

  const icon: IconName = (() => {
    switch (item.action) {
      case "created":
        return "add";
      case "email_opened":
      case "email_delivered":
      case "email_sending_failed":
      case "sent":
        return "send";
      case "sent_to_erp":
        return "link";
      default:
        return "invoice";
    }
  })();
  const description = (() => {
    switch (item.action) {
      case "created":
        return t("invoices:activity_log.created_by_x", {
          replace: { x: item.user?.fullName ?? t("common:unknown") },
          defaultValue: "Created by {{x}}",
        });
      case "sent":
        return t("invoices:activity_log.sent_to_x_by_y", {
          replace: {
            x: item.recipient ?? t("common:unknown"),
            y: item.user?.fullName ?? t("common:unknown"),
          },
          defaultValue: "Sent to {{x}} by {{y}}",
        });
      case "sent_to_erp":
        return t("invoices:activity_log.sent_to_erp", {
          replace: {
            x: item.user?.fullName ?? t("common:unknown"),
          },
          defaultValue: "Sent to ERP by {{x}}",
        });
      case "email_opened":
        return t("common:email_opened_by", {
          defaultValue: "Email opened by {{x}}",
          replace: {
            x: item.recipient ?? t("common:unknown"),
          },
        });
      case "email_delivered":
        return t("common:email_delivered_to", {
          defaultValue: "Email delivered to {{x}}",
          replace: {
            x: item.recipient ?? t("common:unknown"),
          },
        });
      case "email_sending_failed":
        return t("common:email_delivery_failed_to", {
          defaultValue: "Email delivery failed to {{x}}",
          replace: {
            x: item.recipient ?? t("common:unknown"),
          },
        });
      default:
        return `${item.action}: missing text`;
    }
  })();

  return (
    <div className="flex flex-row items-center gap-6">
      <div className="rounded-full bg-blue-600 p-1 text-white">
        <Icon name={icon} className="h-5 w-5" />
      </div>
      <div className="flex flex-col gap-1 text-sm">{description}</div>
      <div className="hidden sm:block">
        <div className="shrink-0 text-nowrap rounded-lg border bg-shade-50 px-2 py-1 text-xs">
          {locale.format(item.date)}
        </div>
      </div>
    </div>
  );
}
