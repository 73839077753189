import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "../api";
import { useDebouncedValue } from "../debounce/use-debounce";
import { AutoCompleteInput } from "./autocomplete";
import { AddressLookup } from "@apacta/sdk";

export type StreetAddressResult = {
  addressWithNumber?: string;
  streetName?: string;
  streetNumber?: string;
  zipCode?: string;
  cityName?: string;
  cityId?: string;
  latitude?: number;
  longitude?: number;
};

// TextInput that looks up addresses, onSelect can be used to help fill out other fields
export function StreetAddressInput({
  onChange,
  onSelect,
  label,
  placeholder,
  initialValue,
  value,
  className,
}: {
  label?: string;
  onChange?: (val: string) => void;
  onSelect?: (item: StreetAddressResult) => void;
  value?: string;
  initialValue?: string;
  placeholder?: string;
  className?: string;
}) {
  if (value !== undefined && initialValue !== undefined)
    console.warn("Both value and initialValue are set, initialValue will be ignored");

  const { t } = useTranslation();
  const api = useAPI();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue(value);
  const q = useQuery({
    queryKey: ["addressLookup", debouncedValue],
    queryFn: ({}) => api.autoCompleteAddress({ address: debouncedValue as string }),
    enabled: !!debouncedValue,
  });

  function handleSelect(i: AddressLookup) {
    let street = `${i.streetName}`;
    if (i.streetNumber) street += ` ${i.streetNumber}`;
    if (i.subLocality) street += `, ${i.subLocality}`;
    setValue(street);
    onSelect?.({
      addressWithNumber: `${i.streetName} ${i.streetNumber}`,
      cityName: i.city as string,
      streetName: i.streetName as string,
      streetNumber: i.streetNumber ?? undefined,
      zipCode: i.zipCode ?? undefined,
      cityId: i.apactaCityId ?? undefined,
      latitude: i.latitude ?? undefined,
      longitude: i.longitude ?? undefined,
    });
  }

  return (
    <div>
      <AutoCompleteInput
        label={label}
        items={q.data?.data || []}
        loading={q.isLoading || isPending}
        value={value}
        defaultValue={initialValue}
        displayFn={(item) => {
          const postfix = `${item.zipCode}, ${item.city}`;
          let street = `${item.streetName}`;
          if (item.streetNumber) street += ` ${item.streetNumber}`;
          if (item.subLocality) street += `, ${item.subLocality}`;
          return `${street}, ${postfix}`;
        }}
        placeholder={placeholder ?? t("common:address")}
        onSelect={handleSelect}
        className={className}
        onChange={(val) => {
          setValue(val);
          onChange?.(val);
        }}
      />
    </div>
  );
}
