import { SkeletonLoader } from "../skeleton";

export function KPISkeletonItem() {
  return (
    <div className="flex-1 rounded-md border bg-white px-4 pt-5 sm:px-6 sm:pt-6">
      <SkeletonLoader
        className="h-36 w-full"
        options={{
          viewBox: "0 0 100 55",
          backgroundColor: "#f1f5f9",
          foregroundColor: "#94a3b8",
        }}
      >
        <circle cx="6" cy="6" r="6" />
        <rect x="16" y="3" rx="0" ry="0" width="100%" height="6" />
        <rect x="0" y="30" rx="2" ry="2" width="20" height="10" />
      </SkeletonLoader>
    </div>
  );
}
