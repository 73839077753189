import { useTranslation } from "react-i18next";
import { Invoice } from "@apacta/sdk";
import { Icon } from "~/lib/ui";

export function InvoiceImage({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  const image = invoice.integrationImageUrl ?? "/apacta-icon-blue.svg";
  const showLogo = (() => {
    if (invoice.integrationId) return true;
    if (invoice.isDraft) return false;
    return true;
  })();

  if (!showLogo && !invoice.isLocked) return null;

  return (
    <div className="flex flex-row items-center gap-1">
      {invoice.isLocked && <Icon name="lock" className="h-5 w-5" />}
      <div className="h-5 w-5">
        {image && showLogo ? (
          <img
            title={
              invoice.integrationId
                ? t("invoices:booked_by_partner_integration")
                : t("invoices:booked_by_apacta")
            }
            src={image}
            className="h-5 w-5"
          />
        ) : undefined}
      </div>
    </div>
  );
}
