import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "../ui/spinner";

export function AISupportDialog() {
  const [loading, setLoading] = useState(true);

  function handleOnLoad() {
    setLoading(false);
  }

  return (
    <div className="flex min-h-[35rem] w-full grow flex-col">
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      ) : null}
      <iframe
        src="https://chat.applai.io/chatbot/bJD4qBuAh1sdpn8Ly8PC?isClosable=false"
        className={twMerge(loading ? "hidden" : "flex h-full w-full grow")}
        onLoad={handleOnLoad}
      />
    </div>
  );
}
