import { KeyboardEvent, useState } from "react";

/**
 * @description Abstracts away index state and handles keyboard events
 */
export function useItemSelection({
  items,
  onSelectIndex,
  selectionOpen = false,
  onOpenChange,
}: {
  items: Array<unknown>;
  selectionOpen: boolean;
  onSelectIndex: (idx: number) => void;
  onOpenChange: (open: boolean) => void;
}) {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const onInputKeydown = (e?: KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.code === "Tab" && currentIndex >= 0) {
      onSelectIndex(currentIndex);
    }
  };

  const onInputKeyUp = (e?: KeyboardEvent<HTMLInputElement> | undefined) => {
    const length = items.length - 1;
    switch (e?.code) {
      case "Enter":
        e?.preventDefault();
        e?.stopPropagation();
        onSelectIndex(currentIndex);
        break;
      case "ArrowUp":
        if (!selectionOpen) {
          onOpenChange(true);
        } else {
          setCurrentIndex((prev) => {
            const newIdx = (prev - 1).clamp(0, length);
            return newIdx;
          });
        }
        break;
      case "ArrowDown":
        if (!selectionOpen) {
          onOpenChange(true);
        } else {
          setCurrentIndex((prev) => {
            const newIdx = (prev + 1).clamp(0, length);
            return newIdx;
          });
        }
        break;
      case "Escape":
        onOpenChange(false);
        break;
      default:
        return;
    }
  };

  const onInputFocus = () => {
    setCurrentIndex(-1);
  };

  const onInputBlur = () => {
    setCurrentIndex(-1);
  };

  const onInputClick = () => {
    // Do nothing.. We don't want this opening until you actually type anything
  };

  return {
    currentIndex,
    onInputKeydown,
    onInputKeyUp,
    onInputFocus,
    onInputBlur,
    onInputClick,
  };
}
