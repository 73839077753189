import { DropZoneOrientation } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { usePlanning } from "~/lib/planning";

type AddCardHeight = "h-8" | "h-24";

export function AddCard({
  height = "h-8",
  orientation = DropZoneOrientation.HORIZONTAL,
  isDragging,
  onClick,
  limitWidth,
}: {
  height?: AddCardHeight;
  orientation?: DropZoneOrientation;
  isDragging: boolean;
  onClick: () => void;
  limitWidth?: boolean;
}) {
  const { t } = useTranslation();
  const { view } = usePlanning();

  const isDayView = view === "day";

  return (
    <span
      className={twMerge(
        "flex flex-shrink",
        height,
        "px-1 py-1",
        isDragging ? "hidden" : "flex",
        isDayView ? "w-40" : "w-full"
      )}
    >
      <button
        onClick={() => onClick()}
        type="button"
        className={twMerge(
          isDragging ? "hidden" : "hidden group-hover:block",
          "group h-full w-full border-2 border-dashed text-center opacity-0 transition-opacity duration-300 hover:border-gray-400 focus:outline-none group-hover:text-gray-400 group-hover:opacity-100"
        )}
      >
        <div className="flex items-center justify-center gap-2">
          <Icon name="add" className="h-4 w-4" />
          <span className="text-sm ">
            {`${t("common:create", {
              replace: { entity: t("common:task", { count: 1 }).toLocaleLowerCase() },
            })}`}
          </span>
        </div>
      </button>
    </span>
  );
}
