import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";

export function SideBarScrollButtons({
  scrollRef,
  navRef,
}: {
  scrollRef: MutableRefObject<HTMLElement | null>;
  navRef: MutableRefObject<HTMLElement | null>;
}) {
  const { t } = useTranslation();
  const scrollToTopRef = useRef<HTMLButtonElement | null>(null);
  const scrollToBottomRef = useRef<HTMLButtonElement | null>(null);

  function handleScrollToTop() {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }
  }

  function handleScrollToBottom() {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: 0,
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  function flipVisibility(el: HTMLButtonElement | null, visibility: "show" | "hide") {
    if (el === null) return;

    if (visibility === "show") {
      el.classList.remove("hidden");
      el.classList.add("flex");
    } else {
      el.classList.remove("flex");
      el.classList.add("hidden");
    }
  }

  const manageButtonVisibility = useCallback(() => {
    if (!scrollRef.current) return;
    const scrollTop = scrollRef.current.scrollTop;
    const scrollDelta = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;

    // Animation frame to avoid ResizeObserver errors
    // - https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
    window.requestAnimationFrame(() => {
      // We're no longer at the top, show top arrow
      if (scrollTop > 5) {
        flipVisibility(scrollToTopRef.current, "show");
      }
      // We're no longer at the bottom, show bottom arrow
      if (scrollTop < scrollDelta - 5) {
        flipVisibility(scrollToBottomRef.current, "show");
      }

      // We're at the top. Hide top arrow
      if (scrollTop === 0) {
        flipVisibility(scrollToTopRef.current, "hide");
      }

      // We're at the bottom. Hide bottom arrow
      if (scrollDelta - scrollTop < 5) {
        // Allow for some minor space between. Chrome uses floats, so we can't expect exact values
        flipVisibility(scrollToBottomRef.current, "hide");
      }
    });
  }, [scrollRef]);

  useEffect(() => {
    if (!scrollRef.current) return;
    if (!navRef.current) return;

    const ob = new ResizeObserver(manageButtonVisibility);

    // Element copied to scope in case it changes before unmount
    const scroll = scrollRef.current;
    scroll.addEventListener("scroll", manageButtonVisibility);

    ob.observe(navRef.current);
    manageButtonVisibility();

    return () => {
      if (navRef.current) {
        ob.disconnect();
      }

      scroll.removeEventListener("scroll", manageButtonVisibility);
    };
  }, [scrollRef.current, navRef.current]);

  return (
    <>
      <button
        ref={scrollToTopRef}
        onClick={handleScrollToTop}
        className={twMerge(
          "group absolute top-0 z-10 h-12 w-full cursor-pointer items-center justify-center bg-gradient-to-b from-primary to-primary/50 text-white",
          "flex"
        )}
      >
        <div className="absolute whitespace-nowrap bg-primary p-1 text-xs opacity-0 transition-all duration-200 group-hover:translate-y-2 group-hover:opacity-100">
          {t("ui:navigation.scroll.scroll-top")}
        </div>
        <Icon
          name="chevronDoubleUp"
          className="h-5 w-5 transition-all duration-200 group-hover:-translate-y-2"
        />
      </button>
      <button
        ref={scrollToBottomRef}
        onClick={handleScrollToBottom}
        className={twMerge(
          "group absolute bottom-0 z-10 h-12 w-full cursor-pointer items-center justify-center bg-gradient-to-t from-primary to-primary/50 text-white",
          "flex"
        )}
      >
        <div className="absolute whitespace-nowrap bg-primary p-1 text-xs opacity-0 transition-all duration-200 group-hover:-translate-y-2 group-hover:opacity-100">
          {t("ui:navigation.scroll.scroll-bottom")}
        </div>
        <Icon
          name="chevronDoubleDown"
          className="h-5 w-5 transition-all duration-200 group-hover:translate-y-2"
        />
      </button>
    </>
  );
}
