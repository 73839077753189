import { Button, getIcon, Icon } from "~/lib/ui";
import { Dropdown } from "~/lib/ui/dropdown";
import { DataFilter } from "~/lib/ui/data-table/data-filter/data-filter";
import DataFilterDisplay from "~/lib/ui/data-table/data-filter/data-filter-display";
import { useTranslation } from "react-i18next";
import { usePlanning } from "~/lib/planning";

type ViewControlsProps = {
  collapsed: boolean;
  onCollapseToggle: () => void;
};

export default function ViewControls({ collapsed, onCollapseToggle }: ViewControlsProps) {
  const { t } = useTranslation();
  const { sorting, filters, view } = usePlanning();
  const { sortKey, sortDirection, sortBy } = sorting;

  const isDayView = view === "day";

  return (
    <div className="flex gap-4 px-8 py-4">
      {!isDayView && (
        <Button
          variant="secondary"
          size="small"
          className="flex gap-2 self-start px-3 py-2.5"
          onClick={() => onCollapseToggle()}
        >
          {collapsed ? (
            <Icon name="expandRow" className="h-4 w-4" />
          ) : (
            <Icon name="retractRow" className="h-4 w-4" />
          )}
        </Button>
      )}
      <div className="w-fit">
        <Dropdown
          trigger={
            <Button
              variant="secondary"
              className="flex w-full shrink-0 whitespace-nowrap"
              tabIndex={-1}
            >
              <div className="flex w-full flex-grow items-center justify-between gap-2 text-sm">
                <span className="">{t(`planning:sort.options.${sortKey}.${sortDirection}`)}</span>
                <Icon name="chevronDown" className="h-4 w-4" />
              </div>
            </Button>
          }
          options={{
            trigger: { asChild: true, className: "flex flex-grow flex-shrink-0" },
          }}
        >
          <Dropdown.Item
            className="text-sm"
            onSelect={() => sortBy("name", "asc")}
            Icon={getIcon("sortAsc")}
          >
            {t("planning:sort.options.name.asc")}
          </Dropdown.Item>
          <Dropdown.Item
            className="text-sm"
            onSelect={() => sortBy("name", "desc")}
            Icon={getIcon("sortDesc")}
          >
            {t("planning:sort.options.name.desc")}
          </Dropdown.Item>
          <Dropdown.Item
            className="text-sm"
            onSelect={() => sortBy("estimate", "asc")}
            Icon={getIcon("sortAsc")}
          >
            {t("planning:sort.options.estimate.asc")}
          </Dropdown.Item>
          <Dropdown.Item
            className="text-sm"
            onSelect={() => sortBy("estimate", "desc")}
            Icon={getIcon("sortDesc")}
          >
            {t("planning:sort.options.estimate.desc")}
          </Dropdown.Item>
        </Dropdown>
      </div>
      <DataFilter filters={filters} hideFilter={true} />
      <DataFilterDisplay filters={filters} />
    </div>
  );
}
