import { IntegrationSetting } from "@apacta/sdk";
import { LabelInput } from "~/lib/ui/form-elements";

export function IntegrationSettingInput({
  onChange,
  integrationSetting,
  initialIntegrationValue,
}: {
  onChange: (value: string | null) => void;
  integrationSetting: IntegrationSetting;
  initialIntegrationValue?: string;
}) {
  const integrationSettingId = integrationSetting?.users?.[0]?.joinData?.integrationSettingId;

  return (
    <>
      {integrationSetting !== undefined && (
        <>
          <LabelInput
            onChange={(e) => onChange(e.currentTarget.value)}
            key={integrationSettingId}
            label={integrationSetting?.name}
            id={integrationSettingId}
            defaultValue={initialIntegrationValue}
          />
          <div className="text-sm-left">{integrationSetting.description}</div>
        </>
      )}
    </>
  );
}
