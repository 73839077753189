// Reads a string and replaces anything matching single curly braces with the value from the replacements object.
export function replaceTemplate(
  origin: string,
  replacements: Record<string, string | undefined>
): string {
  let template = origin;
  for (const [key, value] of Object.entries(replacements)) {
    template = template.replace(new RegExp(`{${key}}`, "g"), value ?? `{${key}}`);
  }
  return template;
}
