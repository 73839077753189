import { ReactNode } from "react";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { DataFilterDropdown } from "~/lib/ui/data-table/data-filter/data-filter-dropdown";
import DataFilterDisplay from "~/lib/ui/data-table/data-filter/data-filter-display";

type DataFilterEntity = "user" | "task" | "card" | "project";

export type DataFilterItem<TValue> = {
  entity?: DataFilterEntity;
  filterFn: (...args: Array<any>) => boolean;
  identifier: string;
  Lozenge: ({ values }: { values: TValue }) => ReactNode;
  render: () => ReactNode;
};

export type FilterType =
  | DataFilterItem<string>
  | DataFilterItem<number>
  | DataFilterItem<boolean>
  | DataFilterItem<Array<string>>;

export const DataFilter = ({
  filters,
  hideFilter = false,
}: {
  filters: Array<FilterType>;
  hideFilter?: boolean;
}) => {
  const [, setSearchParams] = useTypedSearchParams<Record<string, any>>();

  const filterKeys = filters.map((filter) => filter.identifier);
  const handleRemoveAllFilters = () => {
    filterKeys.forEach((key) => setSearchParams(key, undefined));
  };

  return (
    <div className="flex items-start gap-4">
      <DataFilterDropdown filters={filters} onRemoveAll={handleRemoveAllFilters} />
      {!hideFilter && <DataFilterDisplay filters={filters} />}
    </div>
  );
};
