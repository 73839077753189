import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetLabelsDirectionEnum, LabelEntity, PaginationDetails } from "@apacta/sdk";
import { captureMessage } from "~/lib/error-reporting";

export type UseCategoryLabelsEntity = "employee" | "task";
const defaultLimit = 1000;

export function useCategoryLabels(entity?: UseCategoryLabelsEntity) {
  const api = useAPI();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const labels = useQuery({
    queryKey: ["category-labels", entity],
    queryFn: () =>
      api.getLabels({
        entity,
        q: searchQuery,
        sort: "text",
        direction: GetLabelsDirectionEnum.Asc,
        limit: defaultLimit,
      }),
  });

  const data = labels.data?.data ?? [];
  const pagination = labels.data?.pagination ?? ({} as PaginationDetails);

  useEffect(() => {
    if (pagination?.hasNextPage) {
      captureMessage(
        `[use-category-labels.ts] :: Fetched ${defaultLimit} labels but there are more labels to fetch`
      );
    }
  }, [pagination.hasNextPage]);

  const employeeLabels = data.filter((label: LabelEntity) => label.entity === "employee");
  const taskLabels = data.filter((label: LabelEntity) => label.entity === "task");

  return {
    labels: labels.data?.data ?? [],
    employeeLabels,
    taskLabels,
    setSearchQuery,
    isLoadingLabels: labels.isLoading,
    refetchLabels: async () => labels.refetch(),
  };
}
