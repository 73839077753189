import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { Suspense } from "react";
import { DataFilterItem, FilterType } from "~/lib/ui/data-table/data-filter/data-filter";

type ExtractGeneric<T> = T extends DataFilterItem<infer U> ? U : never;

export const DataFilterLozenge = ({ filter }: { filter: FilterType }) => {
  const [searchParams] = useTypedSearchParams<Record<string, any>>();

  const ActualFilter = filter as DataFilterItem<ExtractGeneric<FilterType>>;
  const data = searchParams[filter.identifier] as ExtractGeneric<FilterType>;

  if (!data) return null;

  return (
    <Suspense>
      <ActualFilter.Lozenge values={data} />
    </Suspense>
  );
};
