import { useTranslation } from "react-i18next";
import { KPIBetaCard } from "~/pages/projects/_cmp/kpi-beta-card";
import { InvoiceOutlet } from "../[invoiceId]";
import { useOutletContext } from "react-router";
import { getIcon } from "~/lib/ui";
import { ContributionMarginCard, ContributionRatioCard } from "~/pages/projects/_kpi-cards";

export function InvoiceKPISection() {
  const { kpi } = useOutletContext<InvoiceOutlet>();
  const { t } = useTranslation();
  return (
    <div className="mb-4 grid grid-cols-4 gap-4">
      <KPIBetaCard
        label={t("finance:cost_price")}
        current={{
          label: t("common:work_hour_usage"),
          value: kpi.hourUsageAmount ?? 0,
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:cost_price"),
          value: (kpi.hourUsageAmount ?? 0) + (kpi.productUsageAmount ?? 0),
        }}
        remainder={{
          label: t("common:product_usage"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={getIcon("currency")}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />
      <KPIBetaCard
        label={t("finance:total")}
        current={{
          label: t("finance:cost_price"),
          value: kpi.costPriceTotal,
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:sales_price"),
          value: kpi.grossSalesPriceTotal,
        }}
        remainder={{
          label: t("finance:contribution"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={getIcon("currency")}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />
      <ContributionMarginCard
        label={t("finance:contribution_margin_per_hour")}
        paddingXClassname=""
        contributionMargin={kpi.contributionMarginPerHour}
      />
      <ContributionRatioCard
        label={t("common:contribution_ratio")}
        paddingXClassname=""
        contributionRatio={kpi.contributionRate}
      />
    </div>
  );
}
