import { useTranslation } from "react-i18next";
import ViewTabs from "~/lib/ui/tabs/view-tabs";
import TextInput from "~/lib/ui/form-elements/text-input";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { CVRInput } from "~/lib/ui/cvr-input";
import { useFormState } from "~/lib/form-state";
import { customerSchema } from "./customer-schema";
import { useAPI } from "~/lib/api";
import { ZipInput } from "~/lib/ui/zip-input";
import { useMutation } from "@tanstack/react-query";
import { CreateContactOperationRequest } from "@apacta/sdk";
import { useToastOnError } from "~/lib/utils/hooks";
import { ContactType } from "~/lib/enums/contact-type.enum";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { UserPlusIcon } from "@heroicons/react/24/outline";

type PropsCommon = {
  onCustomerCreated?: (customerId: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  defaultCustomerName?: string;
};

export function CreateCustomerDialog(props: PropsCommon) {
  const { t } = useTranslation();
  const api = useAPI();
  const initialValues = {
    name: props.defaultCustomerName || "",
    address: undefined,
    cityName: undefined,
    email: undefined,
    phone: undefined,
    cvr: undefined,
    ean: undefined,
    zip_code: undefined,
    payment_term_id: undefined,
    cityId: undefined,
  };
  const {
    isPending,
    isValid,
    getField,
    getValue,
    register,
    setValues,
    parseBackendErrors,
    reset,
    onChange,
  } = useFormState({
    schema: customerSchema,
    initialValues,
  });

  const zipToCityId = async (zipCode?: string): Promise<string | undefined> => {
    if (!zipCode) return undefined;
    return api.zipCodeLookup({ zipCode }).then((res) => res.data.id ?? undefined);
  };

  const customerCreate = useMutation({
    mutationFn: (args: CreateContactOperationRequest) => api.createContact(args),
    onSuccess: (data) => {
      if (!data.data.id) {
        console.warn("Customer created, but no id returned", data.data);
        return;
      }
      props.onCustomerCreated?.(data.data.id);
      handleOpenChange(false);
      reset(initialValues);
    },
    onError: async (error) => {
      await parseBackendErrors(error);
    },
  });

  useToastOnError(customerCreate.error);

  function handleOpenChange(isOpen: boolean) {
    props.onOpenChange?.(isOpen);
  }

  function handleSubmit() {
    if (!isValid) {
      return;
    }

    customerCreate.mutate({
      createContactRequest: {
        name: getValue("name"),
        address: getValue("address"),
        phone: getValue("phone"),
        email: getValue("email"),
        cvr: getValue("cvr")?.toString(),
        ean: getValue("ean"),
        cityName: getValue("cityName"),
        zipCode: getValue("zip_code"),
        contactTypes: { ids: [ContactType.CUSTOMER] },
        cityId: getValue("cityId"),
      },
    });
  }

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          defaultValue: "Create {{entity}}",
          replace: { entity: t("common:customer", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={UserPlusIcon}
      />

      <div className="mt-2">
        <ViewTabs
          tabs={[
            {
              id: "private",
              label: t("customers:modal.create_customer.tabs.private"),
            },
            {
              id: "company",
              label: t("customers:modal.create_customer.tabs.company"),
            },
          ]}
        >
          <div id="private" className="flex flex-col gap-4">
            <TextInput
              {...register("name")}
              label={t("customers:modal.create_customer.input.name")}
              onSubmit={handleSubmit}
              onBlur={() => null} // fix blur issue
            />
            <StreetAddressInput
              label={t("customers:modal.create_customer.input.address")}
              {...register("address")}
              onSelect={async (i) => {
                setValues({
                  address: i.addressWithNumber,
                  cityName: i.cityName,
                  zip_code: i.zipCode,
                  cityId: await zipToCityId(i.zipCode),
                });
              }}
            />

            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <ZipInput
                label={t("common:zip_code")}
                placeholder={t("common:zip_code")}
                {...register("zip_code")}
                value={getValue("zip_code")}
                onSelect={async (item) => {
                  setValues({
                    zip_code: item.zipCode,
                    cityName: item.cityName,
                    cityId: await zipToCityId(item.zipCode),
                  });
                }}
              />
              <TextInput
                {...register("cityName")}
                label={t("customers:modal.create_customer.input.city")}
                onSubmit={handleSubmit}
              />
            </div>
            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <TextInput
                type="email"
                {...register("email")}
                label={t("customers:modal.create_customer.input.email")}
                onSubmit={handleSubmit}
              />
              <TextInput
                {...register("phone")}
                label={t("customers:modal.create_customer.input.phone")}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
          <div id="company" className="flex flex-col gap-4">
            <CVRInput
              label={t("customers:modal.create_customer.input.cvr")}
              onChange={(val) => onChange("cvr", val)}
              value={getValue("cvr")?.toString() ?? ""}
              error={getField("cvr").errors?.[0]}
              onSelect={async (i) => {
                setValues({
                  cvr: i.vat?.toString(),
                  email: i.email,
                  phone: i.phone,
                  name: i.name,
                  address: i.address,
                  cityName: i.city,
                  zip_code: i.zipcode.toString(),
                  cityId: await zipToCityId(i.zipcode),
                });
              }}
            />
            <TextInput
              {...register("name")}
              label={t("customers:modal.create_customer.input.name")}
              onSubmit={handleSubmit}
              onBlur={() => null} // fix blur issue
            />
            <StreetAddressInput
              label={t("customers:modal.create_customer.input.address")}
              {...register("address")}
              onSelect={async (i) => {
                setValues({
                  address: i.addressWithNumber,
                  cityName: i.cityName,
                  zip_code: i.zipCode,
                  cityId: await zipToCityId(i.zipCode),
                });
              }}
            />

            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <ZipInput
                label={t("common:zip_code")}
                placeholder={t("common:zip_code")}
                {...register("zip_code")}
                value={getValue("zip_code")}
                onSelect={async (item) => {
                  setValues({
                    zip_code: item.zipCode,
                    cityName: item.cityName,
                    cityId: await zipToCityId(item.zipCode),
                  });
                }}
              />
              <TextInput
                {...register("cityName")}
                label={t("customers:modal.create_customer.input.city")}
                onSubmit={handleSubmit}
              />
            </div>
            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <TextInput
                type="email"
                {...register("email")}
                label={t("customers:modal.create_customer.input.email")}
                onSubmit={handleSubmit}
              />
              <TextInput
                {...register("phone")}
                label={t("customers:modal.create_customer.input.phone")}
                onSubmit={handleSubmit}
              />
            </div>
            <TextInput
              {...register("ean")}
              label={t("customers:modal.create_customer.input.ean")}
              onSubmit={handleSubmit}
            />
          </div>
        </ViewTabs>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            replace: { entity: t("common:customer", { count: 1 }).toLowerCase() },
          }),
          loading: customerCreate.isPending,
          disabled: !isValid || isPending,
          onClick: handleSubmit,
        }}
        onClose={() => handleOpenChange(false)}
      />
    </>
  );
}
