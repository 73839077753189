import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { getIcon, Icon } from "~/lib/ui";
import { CACHE_PROJECTS } from "..";

export default function MergeProjectModal({
  targetProjectId,
  onClose,
  onSubmit,
}: {
  targetProjectId: string;
  onClose: () => void;
  onSubmit?: () => void;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [mergeFromId, setMergeFromId] = useState<string | undefined>();
  const [targetId, setTargetId] = useState<string | undefined>(targetProjectId);

  const mergeM = useMutation({
    mutationFn: async () => {
      if (!mergeFromId || !targetId) return;
      await api.mergeProjects({
        mergeProjectsRequest: {
          originalProjectId: targetId,
          mergingProjectId: mergeFromId,
        },
      });
      // This change will affect the current view, but also the index
      await queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS] });
    },
  });

  const handleSubmit = async () => {
    if (mergeFromId && targetId) {
      await mergeM.mutateAsync();
      onSubmit?.();
    }
  };

  return (
    <div>
      <DialogHeader title="Merge Project" Icon={getIcon("copy")} />
      <div className="flex flex-col gap-8">
        <div className="flex items-end">
          <div className="w-full">
            <ProjectSelection
              label={t("projects:merge.merge_project")}
              placeholder={t("projects:merge.merge_project.placeholder")}
              filterFn={(project) => project.id !== targetId}
              onSelect={(spid) => setMergeFromId(spid)}
            />
          </div>
          <div className="mx-4 h-10 w-10 rounded-full bg-tertiary p-2 text-white">
            <Icon name="moveRight" className="h-6 w-6" />
          </div>
          <div className="w-full">
            <ProjectSelection
              controlled
              label={t("projects:merge.original_project")}
              placeholder={t("projects:merge.original_project.placeholder")}
              value={targetProjectId}
              readonly={!!targetId}
              onSelect={(spid) => setTargetId(spid)}
            />
          </div>
        </div>

        <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
          <div className="flex items-center gap-4">
            <div className="rounded-full bg-red-500 p-1.5 text-white">
              <Icon name="warningTriangle" className="h-6 w-6" />
            </div>
            <div className="text-sm">{t("projects:merge_project_warning")}</div>
          </div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:confirm"),
          onClick: () => handleSubmit(),
          disabled: !mergeFromId || !targetId || mergeM.isPending,
        }}
        onClose={() => onClose()}
      />
    </div>
  );
}
