import { useEffect } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { KeyboardShortcut } from "./keyboard-shortcut";
import template from "./issue-template.md?raw";
import { replaceTemplate } from "./template";
import { BACKEND_URL } from "~/lib/auth/config";
import { useSession } from "~/lib/auth/session";
import { GetMe200ResponseIntegrationsEnabled } from "@apacta/sdk";

function useSupportTemplate() {
  const toast = useToasts();
  const { me } = useSession();

  useEffect(() => {
    const sc = new KeyboardShortcut();
    // Copy template to clipboard

    sc.onShortCut(["AltLeft", "KeyL"], () => {
      const integrations = getEnabledIntegrations(me?.integrationsEnabled);

      navigator.clipboard
        .writeText(
          replaceTemplate(template, {
            COMPANY_NAME: me?.company.name,
            COMPANY_CVR: me?.company.cvr,
            ADMIN_LINK: `${BACKEND_URL}/admin/companies/view/${me?.company.id}`,
            DATE_AND_TIME: new Date().toLocaleString(),
            ENABLED_INTEGRATIONS: integrations.length > 0 ? integrations.join(", ") : "None",
          })
        )
        .then(() => {
          toast.show({
            title: "Issue Template Copied",
            description:
              "A support template has been copied to your clipboard. Paste it into a new issue.",
          });
        })
        .catch(() => {
          console.warn("Failed to copy issue template to the clipboard. Requires HTTPS.");
        });
    });

    return () => {
      sc.close();
    };
  }, [toast]);
}

export default useSupportTemplate;

function getEnabledIntegrations(
  integrations: GetMe200ResponseIntegrationsEnabled | undefined
): Array<string> {
  if (integrations === undefined) return [];
  return Object.keys(integrations).filter(
    (key) => integrations[key as keyof GetMe200ResponseIntegrationsEnabled]
  );
}
