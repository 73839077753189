import { useNavigate, useParams } from "react-router";
import { FormEditView } from "~/lib/json-forms/form-edit-view";

export default function RegistrationPage() {
  const { formId } = useParams<{
    formId: string;
  }>();

  const navigate = useNavigate();

  return <FormEditView mode="stand-alone" formId={formId!} onBackClick={() => navigate(-1)} />;
}
