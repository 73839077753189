import { getIcon, IconName } from "~/lib/ui";
import { ReactNode } from "react";
import { KPICardWrapper } from "~/pages/projects/_cmp/kpi-card-wrapper";

export type ProcurementKpiCardProps = {
  title: string;
  iconName: IconName;
  value: number;
  valueRender?: (value: number) => ReactNode;
  description: string;
  loading: boolean;
};

export function ProcurementKpiCard({
  title,
  iconName,
  value,
  valueRender,
  description,
  loading,
}: ProcurementKpiCardProps) {
  return (
    <KPICardWrapper label={title} Icon={getIcon(iconName)}>
      <div className="text-2xl font-medium text-primary">
        {valueRender ? valueRender(value) : value}
      </div>
      <div className="text-gray-400">{description}</div>
    </KPICardWrapper>
  );
}
