import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { StreetAddressInput, StreetAddressResult } from "~/lib/ui/street-address-input";
import { useTranslation } from "react-i18next";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button, LabelInput } from "~/lib/ui";
import { ZipInput } from "~/lib/ui/zip-input";
import { useAPI } from "~/lib/api";

export default function WorkAddressInput({
  formState,
  editMode,
}: {
  formState: OfferFormState;
  editMode: boolean;
}) {
  const { t } = useTranslation();
  const api = useAPI();

  const [showEditWorkAddress, setShowEditWorkAddress] = useState<boolean>(false);

  // Introduce local state for the form values to allow for editing and aborting changes
  const [streetName, setStreetName] = useState<string | undefined>(
    formState.getValue("streetName")
  );
  const [zipCode, setZipCode] = useState<string | undefined>(formState.getValue("zipCode"));
  const [cityName, setCityName] = useState<string | undefined>(formState.getValue("cityName"));
  const [cityId, setCityId] = useState<string | undefined>(formState.getValue("cityId"));

  const cityRef = useRef<HTMLInputElement>(null);

  const zipToCityId = async (zip?: string): Promise<string | undefined> => {
    if (!zip) return undefined;
    return api.zipCodeLookup({ zipCode: zip }).then((res) => res.data.id ?? undefined);
  };

  // Have to pass along override values for onSelect on Autocomplete
  // Otherwise the formState will be updated with the wrong values because React is.. React
  const submitChanges = (override?: {
    streetName?: string;
    zipCode?: string;
    cityName?: string;
    cityId?: string;
  }) => {
    const _streetName = override?.streetName ?? streetName;
    const _zipCode = override?.zipCode ?? zipCode;
    const _cityName = override?.cityName ?? cityName;
    const _cityId = override?.cityId ?? cityId;

    formState.setValues({
      streetName: _streetName,
      zipCode: _zipCode,
      cityName: _cityName,
      cityId: _cityId,
    });

    setShowEditWorkAddress(false);
  };

  const updateCityName = (name: string | undefined) => {
    if (cityRef.current) {
      cityRef.current.value = name ?? "";
    }
    setCityName(name);
  };

  const handleSelectAddress = async (item: StreetAddressResult) => {
    const _streetName = item.streetNumber
      ? `${item.streetName} ${item.streetNumber}`
      : item.streetName;

    // Set state values
    setStreetName(_streetName);
    setZipCode(item.zipCode);
    setCityName(item.cityName);
    setCityId(item.cityId);

    // Submit with override values because React..
    submitChanges({
      streetName: _streetName,
      zipCode: item.zipCode,
      cityName: item.cityName,
      cityId: item.cityId,
    });
  };

  return (
    <div className="flex items-center gap-2">
      {formState.getValue("streetName") && !showEditWorkAddress && editMode && (
        <div
          onClick={() => setShowEditWorkAddress(true)}
          className="-ml-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-tertiary p-1 text-white hover:bg-tertiary-hover"
        >
          <PencilIcon className="h-4 w-4" />
        </div>
      )}
      <div className={twMerge("flex w-full flex-col", editMode ? "pl-2" : "")}>
        {formState.getValue("streetName") && !showEditWorkAddress ? (
          <>
            <span className="font-bold">
              <span>{t("common:work_address")}: </span>
              <span className="font-normal">
                {formState.getValue("streetName")}, {formState.getValue("zipCode")}{" "}
                {formState.getValue("cityName")}
              </span>
            </span>
          </>
        ) : (
          <div className="w-3/4">
            {editMode && (
              <>
                {showEditWorkAddress ? (
                  <div className="flex w-full">
                    <div className="w-full rounded-lg border bg-white p-4">
                      <div className="flex flex-col gap-6">
                        <StreetAddressInput
                          label={t("common:address")}
                          initialValue={streetName}
                          onChange={setStreetName}
                          onSelect={handleSelectAddress}
                        />
                        <div className="flex gap-6">
                          <div>
                            <ZipInput
                              initialValue={zipCode}
                              placeholder={t("common:zip_code")}
                              label={t("common:zip_code")}
                              onChange={(v) => setZipCode(v)}
                              onSelect={async (item) => {
                                setZipCode(item.zipCode);
                                updateCityName(item.cityName);
                                setCityId(await zipToCityId(item.zipCode));
                              }}
                            />
                          </div>
                          <LabelInput
                            ref={cityRef}
                            placeholder={t("common:city_name")}
                            label={t("common:city_name")}
                            defaultValue={cityName}
                            onChange={(e) => setCityName(e.currentTarget.value)}
                          />
                        </div>
                        <div className="flex items-center justify-end gap-6">
                          <Button variant="primary" onClick={() => submitChanges()}>
                            {t("common:confirm")}
                          </Button>
                          <span
                            onClick={() => setShowEditWorkAddress(false)}
                            className="cursor-pointer justify-center underline hover:text-hover"
                          >
                            {t("common:cancel")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div
                      onClick={() => setShowEditWorkAddress(true)}
                      className="cursor-pointer rounded-lg p-2 hover:bg-shade-200"
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex h-7 w-7 items-center justify-center rounded-full bg-tertiary text-white hover:bg-tertiary-hover">
                          <PlusIcon className="h-4 w-4" />
                        </div>
                        <div>
                          {t("common:add")} {t("projects:worksite_address").toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
