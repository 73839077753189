import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function LanguagesInput({
  onChange,
  initialLanguageId,
  hideLabel = false,
}: {
  onChange: (languageId?: string) => void;
  initialLanguageId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ["languages"],
    queryFn: () => api.getLanguages(),
  });

  const languages = data?.data ?? [];

  const handleSelectLanguage = (id?: string) => {
    onChange?.(id);
  };

  return (
    <SelectionCombobox
      label={hideLabel ? "" : t("common:language")}
      data={languages}
      defaultValue={initialLanguageId}
      valueFn={(e) => e.id}
      labelFn={(e) => e.name}
      onSelect={handleSelectLanguage}
    />
  );
}
