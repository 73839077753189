import { useTranslation } from "react-i18next";
import { Icon } from "../../icons/icon";

export function DefaultEmptyComponent() {
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col items-center justify-center gap-1">
      <Icon name="search" className="h-12 w-12" />
      <span className="text-gray-400">{t("common:no_results")}</span>
    </div>
  );
}
