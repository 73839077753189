import { useOutletContext } from "react-router-dom";
import { InvoiceEditPage } from "../_cmp/invoice-edit";
import { InvoiceView } from "../_cmp/invoice-view";
import { Invoice } from "@apacta/sdk";

export default function InvoiceDetailPage() {
  const { invoice } = useOutletContext<{ invoice: Invoice }>();

  if (invoice.integrationId) return <InvoiceView />;
  if (!invoice.isDraft) return <InvoiceView />;
  return <InvoiceEditPage />;
}
