import { Card } from "~/lib/planning";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { formatDecimals } from "~/lib/utils/number";

export const PlanningTableRowCollapsedContent = ({
  cards,
  onAddNew,
}: {
  cards: Array<Card>;
  onAddNew: () => void;
}) => {
  const { t } = useTranslation();

  const cellEstimates: number = useMemo(
    () =>
      cards.reduce((sum: number, card) => {
        return sum + (card.estimate ?? 0);
      }, 0),
    [cards]
  );

  const cardCount = cards.length;

  return (
    <div className="relative flex h-16 w-full justify-between text-sm">
      <div className="flex flex-col justify-center">
        <div
          className={twMerge(
            "flex h-8 items-end px-3",
            cardCount > 0 ? "font-medium" : "text-shade-400"
          )}
        >{`${cardCount} ${t("common:task", { count: cardCount }).toLowerCase()}`}</div>
        <div
          className={twMerge(
            "flex h-8 items-start gap-2 px-3 text-xs",
            cardCount === 0 && "text-shade-400"
          )}
        >
          <div className="flex items-center gap-2">
            <Icon name="time" className="h-4 w-4" />
            <div>{formatDecimals(cellEstimates)}</div>
          </div>
        </div>
      </div>
      <div className="flex items-center pr-2">
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddNew();
          }}
          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-shade-200 hover:bg-shade-300"
        >
          <Icon name="add" className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
};
