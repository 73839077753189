import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button, Icon } from "~/lib/ui";
import { Fragment } from "react";
import { FilterType } from "~/lib/ui/data-table/data-filter/data-filter";

export const DataFilterDropdown = ({
  filters,
  onRemoveAll,
}: {
  filters: Array<FilterType>;
  onRemoveAll: () => void;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams<Record<string, any>>();

  const filterKeys = filters.map((filter) => filter.identifier);
  const hasAnyFiltersApplied = filterKeys.some((key) => searchParams[key] !== undefined);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button variant="secondary" className="shrink-0 grow-0">
          <Icon name="filter" size="small" />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={0}
          side="right"
          align="start"
          className="animate-slideLeftAndFade"
        >
          <div className="min-w-64 rounded-lg border bg-white p-1.5 shadow-md">
            <DropdownMenu.Label className="flex justify-between pl-1.5 text-xs font-semibold leading-6">
              <div>{t("common:filters")}</div>
              {hasAnyFiltersApplied && (
                <div
                  onClick={onRemoveAll}
                  className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
                >
                  <Icon name="remove" />
                  <span>{`${t("common:clear")} ${t("common:all_nonspecific").toLowerCase()}`}</span>
                </div>
              )}
            </DropdownMenu.Label>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />

            {filters.map((filter, idx) => (
              <Fragment key={filter.render.name}>
                {idx !== 0 && <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />}
                <filter.render />
              </Fragment>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
