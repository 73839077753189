import { RegistrationsDTO } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { getIcon } from "../../icons/icon";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { FormCell } from "~/lib/ui/table/cells/form-cell";
import { Badge } from "../../badge";
import { linkToInvoiceV3 } from "~/lib/utils";

export function RegistrationTypeCell({
  registration,
  projectId,
}: {
  registration: RegistrationsDTO;
  projectId?: string;
}) {
  const { t } = useTranslation();

  const name = (() => {
    if (registration.template) return registration.template;

    if (registration.type === "invoice") {
      if (!registration.invoiced) {
        return `${t(`common:invoice`, { count: 1 })} - ${t("common:draft")}`;
      }

      return t(`common:invoice`, { count: 1 });
    }
    if (registration.type === "expense") {
      return (
        <>
          {t(`common:${registration.type}`, { count: 1 })}
          {registration.vendorName && (
            <div className="mt-2 block text-gray-400">{registration.vendorName}</div>
          )}
        </>
      );
    }
    if (registration.type === "form") {
      return t(`common:form`, { count: 1 });
    }
  })();
  let link: string | undefined = undefined;

  const Icon = (() => {
    if (registration.type === "form") {
      return getIcon("form");
    }
    if (registration.type === "invoice") {
      return getIcon("invoice");
    }
    if (registration.type === "expense") {
      return getIcon("expense");
    }
    return getIcon("unknownType");
  })();

  if (registration.type === "form") {
    return (
      <FormCell
        formId={registration.id}
        formTemplateName={registration.template}
        workDescription={registration.workDescription}
        projectId={projectId}
        deleted={registration.deletedDate ?? undefined}
        invoiced={registration.invoicedDate ?? undefined}
      />
    );
  }
  if (registration.type === "invoice") {
    link = linkToInvoiceV3(registration.id);
  }
  if (registration.type === "expense") {
    link = `/companies/expenses/edit/${registration.id}`;
  }

  return (
    <div className="flex flex-row items-center justify-between">
      <OptionalLink to={link} openInNewTab={link?.includes("/companies/")}>
        <span>
          <Icon className="mr-2 inline h-5 w-5" aria-hidden="true" />
          <span>{name}</span>
        </span>
      </OptionalLink>
      <div className="flex gap-x-2">
        {registration.invoiced ? (
          <Badge size="sm" variant="green">
            {t("common:invoiced", "Invoiced")}
          </Badge>
        ) : null}
        {registration.approved ? (
          <Badge size="sm" variant="green">
            {t("common:approved")}
          </Badge>
        ) : null}
        {registration.deleted && (
          <Badge size="sm" variant="red">
            {t("common:deleted", "Deleted")}
          </Badge>
        )}
      </div>
    </div>
  );
}
