import {
  ComponentProps,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CardReducerState,
  initialCardReducerState,
  useCardReducer,
} from "~/lib/planning/card-reducer";
import { getDatesInPeriod, getDateWithoutTime } from "~/lib/utils/date/date-utils";
import { useAPI } from "~/lib/api";
import { CardUpdateDTO, TaskCreateOrUpdateDTO, User } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "~/lib/ui/dialog";
import { CardAndTaskMutationArgs, PlanningSortOptionDirection, PlanningSortOptionType } from "./";
import { Card, CardPasteAction, CardTypeEnum, Context, PlanningContextView, Task } from "./types";
import DeleteTaskDialog, {
  DeleteDialogProps,
  DeleteOperation,
} from "~/pages/planning/_cmp/crud/delete-task-dialog";
import { Dialog, getIcon } from "../ui";
import useCards from "~/lib/planning/use-cards";
import { endOfDay, startOfDay } from "date-fns";
import { CreateTaskDialog } from "~/pages/planning/_cmp/crud/create-task-dialog";
import { EditTaskPanel } from "~/pages/planning/_cmp/crud/edit-task-panel";
import { useToasts } from "~/lib/toast/use-toasts";
import { SUPPORTED_PARAM_TYPES, useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { MULTI_EMPLOYEE_FILTER } from "~/lib/ui/data-table/data-filter/filters/multi-employee-filter";
import { EMPLOYEE_LABEL_FILTER } from "~/lib/ui/data-table/data-filter/filters/employee-label-filter";
import { TASK_LABEL_FILTER } from "~/lib/ui/data-table/data-filter/filters/task-label-filter";
import { useEmployees } from "~/lib/employees/use-employees";
import { toArray } from "~/lib/planning/utils";
import { PROJECT_FILTER } from "~/lib/ui/data-table/data-filter/filters";

const PlanningContextDefaults: Context = {
  dateRange: undefined,
  selectedDate: new Date(),
  view: "day",
  viewDates: [],
  filters: [],
  isLoadingCards: true,
  isLoadingUsers: true,
  cardState: initialCardReducerState(),
  cardStateViewsRaw: initialCardReducerState().views,
  cardStateViews: initialCardReducerState().views,
  updateCard: (card: CardUpdateDTO, task: TaskCreateOrUpdateDTO | Partial<TaskCreateOrUpdateDTO>) =>
    undefined,
  users: [],
  sortedUsers: [],
  modalCreate: () => undefined,
  cardGet: () => undefined,
  cardGetTask: () => undefined,
  cardGetUser: () => undefined,
  cardCopy: () => undefined,
  cardPaste: () => undefined,
  cardCanPaste: false,
  selectedCard: undefined,
  setSelectedCard: () => undefined,
  showDeleteDialog: (card?: Card) => undefined,
  sorting: {
    sortKey: "name",
    sortDirection: "asc",
    sortBy: () => undefined,
  },
};

export const PlanningContext = createContext<Context>(PlanningContextDefaults);

const filters = [MULTI_EMPLOYEE_FILTER, EMPLOYEE_LABEL_FILTER, TASK_LABEL_FILTER, PROJECT_FILTER];

export default function PlanningProvider({
  children,
  selectedDate,
  view,
}: {
  children: ReactNode;
  selectedDate: Date;
  view: PlanningContextView;
}) {
  const [searchParams] = useTypedSearchParams<{ [key: string]: SUPPORTED_PARAM_TYPES }>();
  const api = useAPI();
  const [cardState, dispatch] = useCardReducer();
  const [copiedCard, setCopiedCard] = useState<{ card: Card; task: Task } | undefined>();
  const [selectedCard, setSelectedCard] = useState<Card | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const toast = useToasts();

  const viewDates = useMemo(() => getDatesInPeriod(selectedDate, view), [selectedDate, view]);

  const [sortKey, setSortKey] = useState<PlanningSortOptionType>("name");
  const [sortDirection, setSortDirection] = useState<PlanningSortOptionDirection>("asc");

  const { employees, isFetching: isFetchingEmployees } = useEmployees({
    requestOverride: { limit: 200 },
  });

  const sortBy = (key?: PlanningSortOptionType, direction?: PlanningSortOptionDirection): void => {
    if (!key || !direction) return;
    setSortKey(key);
    setSortDirection(direction);
  };

  const userFilters = useMemo(
    () =>
      filters.filter(
        (filter) => filter.entity === "user" && searchParams[filter.identifier] !== undefined
      ),
    [searchParams]
  );

  // Projects are included in the task filters as they are a part of the task entity in this scenario
  const taskFilters = useMemo(
    () =>
      filters.filter(
        (filter) =>
          (filter.entity === "task" || filter.entity === "project") &&
          searchParams[filter.identifier] !== undefined
      ),
    [searchParams]
  );

  const filteredCardState: CardReducerState = useMemo(() => {
    const tasks = toArray(cardState.tasks);
    const applyFilters = (cards: Array<Card>): Array<Card> => {
      return cards.filter((card) => {
        const task = tasks.find((t) => t.id === card.taskId);
        const project = task?.project;
        if (!task) return false;
        return taskFilters.every((filter) => {
          const values: Array<string> = searchParams[filter.identifier] as Array<string>;
          switch (filter.entity) {
            case "task":
              return filter.filterFn(values, task);
            case "project":
              return filter.filterFn(values, project);
            default:
              return true;
          }
        });
      });
    };

    const filteredAll = applyFilters(cardState.views.allCards);
    const filteredPlannedAssigned = applyFilters(cardState.views.plannedAssigned);
    const filteredPlannedUnassigned = applyFilters(cardState.views.plannedUnassigned);
    const filteredUnplanned = applyFilters(cardState.views.unplanned);

    return {
      ...cardState,
      views: {
        allCards: filteredAll,
        plannedAssigned: filteredPlannedAssigned,
        plannedUnassigned: filteredPlannedUnassigned,
        unplanned: filteredUnplanned,
      },
    };
  }, [cardState, taskFilters]);

  const filteredUsers = useMemo(() => {
    if (!userFilters.length) return employees;

    let fUsers = [...employees];

    userFilters.forEach((filter) => {
      const values: Array<string> = searchParams[filter.identifier] as Array<string>;
      fUsers = fUsers.filter((user) => {
        if (filter.identifier === EMPLOYEE_LABEL_FILTER.identifier) {
          return filter.filterFn(values, user.labels);
        } else if (filter.identifier === MULTI_EMPLOYEE_FILTER.identifier) {
          return filter.filterFn(user.id, values);
        } else {
          return false;
        }
      });
    });

    return fUsers;
  }, [employees, userFilters]);

  const sortUsers = (): Array<User> => {
    if (sortKey === "name") {
      return filteredUsers.toSorted((a, b) => {
        if (sortDirection === "asc") {
          return a.firstName.localeCompare(b.firstName);
        } else {
          return b.firstName.localeCompare(a.firstName);
        }
      });
    } else {
      return filteredUsers.toSorted((a, b) => {
        const aCards = cardState.views.plannedAssigned.filter((card) => card.userId === a.id);
        const bCards = cardState.views.plannedAssigned.filter((card) => card.userId === b.id);
        const aSum = aCards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
        const bSum = bCards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
        if (sortDirection === "asc") {
          return aSum - bSum;
        } else {
          return bSum - aSum;
        }
      });
    }
  };

  const sortedUsers = useMemo<Array<User>>(
    () => sortUsers(),
    [sortKey, sortDirection, filteredUsers]
  );

  const dateRange = {
    start: viewDates[0],
    end: viewDates[viewDates.length - 1],
  };

  const { cards, tasks, cardCreate, cardUpdate, cardRemove, cardRefetch, isFetching, isFetched } =
    useCards({
      dateRange,
    });

  useEffect(() => {
    if (!cards || !tasks) return;
    if (isFetched) {
      dispatch({ type: "SET_INITIAL", cards, tasks });
    }
  }, [isFetched]);

  const { t } = useTranslation();

  /* MODAL CONTROL START */

  function closeTaskModal() {
    setTaskModal({
      open: false,
      props: {},
    });
  }
  const [taskModal, setTaskModal] = useState<{
    props: Omit<ComponentProps<typeof CreateTaskDialog>, "onClose" | "onSubmit">;
    open: boolean;
  }>({
    open: false,
    props: {},
  });

  const modalCreate = (args?: { userId?: string | null; index?: number; date?: Date | null }) => {
    setTaskModal({
      open: true,
      props: {
        selectedEmployee: args?.userId,
        selectedIndex: args?.index,
        selectedStartDate: args?.date,
        dateRange,
      },
    });
  };

  const [deleteDialogProps, setDeleteDialogProps] = useState<{
    type: "single" | "multiple";
    props: DeleteDialogProps;
  }>({
    type: "single",
    props: {
      card: undefined,
      employeeCards: [],
      allCards: [],
      onSubmit: () => undefined,
      onClose: () => undefined,
    },
  });

  const deleteDialogOnSubmit = async (
    employeeCards: Array<Card>,
    allCards: Array<Card>,
    card?: Card,
    operation?: DeleteOperation
  ) => {
    if (!operation) return;
    if (operation === "all") {
      await api.cardsDeleteMany({
        cardsDeleteManyRequest: {
          cardIds: allCards?.map((c) => c.id) || [],
          deleteCardsForAllUsers: true,
        },
      });
      allCards?.forEach((c) => {
        dispatch({
          type: "REMOVE_CARD",
          card: c as Card,
        });
      });
      await cardRefetch();
    } else if (operation === "employee") {
      await api.cardsDeleteMany({
        cardsDeleteManyRequest: {
          cardIds: employeeCards?.map((c) => c.id) || [],
        },
      });
      employeeCards?.forEach((c) => {
        dispatch({
          type: "REMOVE_CARD",
          card: c as Card,
        });
      });
      await cardRefetch();
    } else if (!!card) {
      await handleDelete(card);
    }
    setSelectedCard(undefined);
    setDeleteDialogOpen(false);
  };

  const showDeleteDialog = async (card?: Card) => {
    const localCard = card ?? selectedCard;

    if (!localCard?.taskId) return;

    const { employeeCards, allCards, taskData } = await api
      .iGetTask({ taskId: localCard.taskId })
      .then((res) => {
        return {
          employeeCards: res.data?.cards?.filter(
            (c) =>
              c.userId === localCard.userId &&
              c.date &&
              localCard.date &&
              +c.date >= +getDateWithoutTime(localCard.date)
          ),
          // Note: Removed the filter (https://github.com/Apacta/issues/issues/1272#issue-2439332807)
          allCards: res.data?.cards ?? [],
          taskData: res.data,
        };
      });
    if ((allCards && allCards.length > 1) || (taskData.repeat && taskData.interval)) {
      setDeleteDialogProps({
        type: "multiple",
        props: {
          card: localCard,
          employeeCards: employeeCards ?? [],
          allCards: allCards ?? [],
          onSubmit: async (c, o) =>
            await deleteDialogOnSubmit(
              (employeeCards ?? []) as Array<Card>,
              (allCards ?? []) as Array<Card>,
              c,
              o
            ),
          onClose: () => setDeleteDialogOpen(false),
        },
      });
    } else {
      setDeleteDialogProps({
        type: "single",
        props: {
          card: localCard,
          employeeCards: employeeCards ?? [],
          allCards: allCards ?? [],
          onSubmit: async () => await handleDelete(localCard),
          onClose: () => setDeleteDialogOpen(false),
        },
      });
    }
    setDeleteDialogOpen(true);
  };

  /* MODAL CONTROL END */

  /* PRIVATE METHODS START */

  const setStartTime = (
    date: Date | null | undefined,
    startTime: Date | null | undefined
  ): Date | null | undefined => {
    const newTime: Date = date ?? new Date();
    if (startTime) {
      newTime.setHours(startTime.getHours());
      newTime.setMinutes(startTime.getMinutes());
    }

    return newTime;
  };

  /* PRIVATE METHODS END */

  /* PUBLIC METHODS START */

  const cardCopy = useCallback(
    ({ card }: { card: Card }): void => {
      if (!card) return;
      const task = cardGetTask({ card });

      if (!task) return;
      setCopiedCard({ card, task });
    },
    [cardState.cards, copiedCard]
  );

  const cardPaste = useCallback(
    ({ userId, index, date }: CardPasteAction): void => {
      if (!copiedCard) return;

      const updatedCards: Partial<Card> = {
        date,
        startTime: setStartTime(date, copiedCard.card.startTime),
        estimate: copiedCard.card.estimate,
        listIndex: index ?? null,
        userId,
        taskId: copiedCard.task?.id,
      };

      const taskUpdate = {
        id: copiedCard.task?.id ?? "",
        description: copiedCard.task?.description || null,
        projectId: copiedCard.task?.projectId || null,
        name: copiedCard.task?.name || "",
      };

      const products = copiedCard.task?.products || [];
      const formTemplateIds = copiedCard.task?.formTemplates?.map((f) => f.id!) || [];
      const status = copiedCard.card?.status || "to_do";

      cardCreate({
        cards: [updatedCards],
        task: taskUpdate,
        products,
        formTemplateIds,
        status,
      }).then(({ cards: cs }) => {
        cs.forEach((card) => {
          dispatch({
            type: "ADD_CARD",
            card: card,
          });
          dispatch({
            type: "ADD_TASK",
            task: { ...copiedCard.task, ...taskUpdate } as Task,
          });
        });
      });
    },
    [cardState.cards, copiedCard]
  );

  const cardGet = useCallback(
    ({ cardId }: { cardId: string }): Card | undefined => {
      return cardState.cards[cardId];
    },
    [cardState.cards]
  );

  const cardGetTask = useCallback(
    ({ card }: { card: Card }): Task | undefined => {
      if (!card || !card.taskId) return;
      return cardState.tasks[card.taskId];
    },
    [cardState.cards]
  );

  const cardGetUser = ({ card }: { card: Card }): User | undefined => {
    if (!card || !card.userId) return;
    return employees.find((u) => u.id === card.userId);
  };

  const handleSubmit = async (args: CardAndTaskMutationArgs & { showToast?: boolean }) => {
    if (args.type === "CREATE") {
      if (args.deleteExistingCards) {
        const cardsToRemove = Object.values(cardState.cards).filter(
          (c) => c.taskId === args.tasks[0].id || c.id === args.originalCardId
        );
        cardsToRemove.forEach((c) => {
          dispatch({
            type: "REMOVE_CARD",
            card: c,
          });
        });
      }
      args.cards.forEach((card) => {
        // If date is outside the selected range, don't add card to card reducer
        if (
          card.date &&
          (card.date < startOfDay(dateRange.start) || card.date > endOfDay(dateRange.end))
        ) {
          return;
        }

        dispatch({
          type: "ADD_CARD",
          card: card,
        });

        if (card.taskId) {
          const task = args.tasks?.find((eachTask) => eachTask.id === card.taskId);
          if (task) {
            dispatch({
              type: "ADD_TASK",
              task: task,
            });
          }
        }

        dispatch({
          type: "UPDATE_CARD",
          data: { card },
        });
      });
    } else if (args.type === "UPDATE") {
      const nCard: Partial<Card> = {
        ...args.card,
        id: args.card.id,
        type: CardTypeEnum.Task,
      };

      nCard.date = nCard.date ? startOfDay(nCard.date) : null;

      const nTask: Partial<Task> = {
        ...args.task,
        id: args.card.taskId || undefined,
        formTemplates: args.task.formTemplateIds?.map((id) => ({ id })) ?? [], // Difference in in/out from endpoint requires us to do this
      };

      await new Promise<{
        toIndexes: Array<string>;
        fromIndexes: Array<string>;
        card: Card;
        task: Task;
        originalCard: Card;
        originalTask: Task;
      }>((resolve) => {
        /**
         * Dispatch is needed before any mutation of the data can happen.
         * This is because we need the new positions of the cards in the list to be able to update the card
         */
        dispatch({
          type: "UPDATE_CARD",
          data: { card: nCard, task: nTask },
          onFinish: ({ from, to, newCard, oldCard, newTask, oldTask }) => {
            const toIndexes = to.map((c) => c.id);
            const fromIndexes = from.map((c) => c.id);

            resolve({
              toIndexes,
              fromIndexes,
              card: newCard,
              task: newTask,
              originalCard: oldCard,
              originalTask: oldTask,
            });
          },
        });
      }).then(
        ({
          toIndexes,
          fromIndexes,
          card: promiseCard,
          task: promiseTask,
          originalCard,
          originalTask,
        }) => {
          cardUpdate({
            cardId: originalCard.id,
            updateCardListingRequest: {
              card: promiseCard as CardUpdateDTO,
              task: promiseTask as TaskCreateOrUpdateDTO,
              fromIndexes,
              toIndexes,
            },
            showToast: args.showToast === undefined ? true : args.showToast,
          })
            .then((data) => {
              /**
               * This only triggers when moving a recurring card to a new date or employee.
               * It triggers the backend to create a separated card and task, not to break the recurrence chain.
               * We need to reflect this properly in the frontend.
               */
              const newCard = data.data.card as Card;
              const newTask = data.data.task as Task;
              if (newCard.id !== originalCard.id) {
                dispatch({
                  type: "ADD_TASK",
                  task: newTask,
                });
                dispatch({
                  type: "REMOVE_CARD",
                  card: originalCard,
                });
                dispatch({
                  type: "ADD_CARD",
                  card: newCard,
                });
              }
            })
            .catch(() => {
              toast.showTemplate("OPERATION_FAILED");
              console.debug("Error updating card, reverting changes");
              dispatch({ type: "UPDATE_CARD", data: { card: originalCard, task: originalTask } });
            });
        }
      );
    }

    if (args.forceRefresh) {
      await cardRefetch();
    }
    closeTaskModal();
  };

  const handleDelete = async (card: Card) => {
    await new Promise<{ originalCard: Card; from: Array<string> }>((resolve) => {
      dispatch({
        type: "REMOVE_CARD",
        card: card,
        onFinish: async ({ from, originalCard }) => {
          resolve({ originalCard, from: from.map((f) => f.id) });
        },
      });
    }).then(async ({ originalCard, from }) => {
      await cardRemove({ originalCard, from });
      setSelectedCard(undefined);
      setDeleteDialogOpen(false);
    });
  };

  /* PUBLIC METHODS END */

  return (
    <PlanningContext.Provider
      value={{
        dateRange,
        selectedDate,
        view,
        viewDates,
        filters,
        isLoadingCards: isFetching,
        isLoadingUsers: isFetchingEmployees,
        cardState,
        cardStateViewsRaw: cardState.views,
        cardStateViews: filteredCardState.views,
        updateCard: (
          card: CardUpdateDTO,
          task: TaskCreateOrUpdateDTO | Partial<TaskCreateOrUpdateDTO>,
          showToast?: boolean
        ) =>
          handleSubmit({
            type: "UPDATE",
            card: card as Card,
            task: task as Task,
            showToast,
          }),
        modalCreate,
        cardCopy,
        cardPaste,
        cardGet,
        cardGetTask,
        cardGetUser,
        users: employees,
        sortedUsers,
        cardCanPaste: !!copiedCard,
        selectedCard,
        setSelectedCard,
        showDeleteDialog,
        sorting: {
          sortKey: sortKey,
          sortDirection: sortDirection,
          sortBy,
        },
      }}
    >
      {children}
      <EditTaskPanel
        open={!!selectedCard}
        onClose={() => setSelectedCard(undefined)}
        card={selectedCard}
        onSubmit={async (e) => {
          await handleSubmit(e);
          setSelectedCard(undefined);
        }}
        onDelete={async () => {
          if (selectedCard) {
            await showDeleteDialog();
          }
        }}
      />
      <Dialog
        open={taskModal?.open}
        onOpenChange={closeTaskModal}
        className="md:max-w-2xl"
        render={() => (
          <CreateTaskDialog
            {...taskModal.props}
            dateRange={dateRange}
            onSubmit={handleSubmit}
            onClose={closeTaskModal}
          />
        )}
      />
      <Dialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        className="md:max-w-2xl"
        render={() =>
          deleteDialogProps.type === "single" ? (
            <ConfirmationDialog
              title={t("planning:delete_modal.title")}
              description={t("planning:delete_modal.description")}
              Icon={getIcon("delete")}
              variant="warning"
              onSubmit={() => deleteDialogProps.props.onSubmit()}
              onClose={() => setDeleteDialogOpen(false)}
            />
          ) : (
            <DeleteTaskDialog {...deleteDialogProps.props} />
          )
        }
      />
    </PlanningContext.Provider>
  );
}
