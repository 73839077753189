import { castDraft, produce } from "immer";
import { Dispatch, useReducer } from "react";
import { MockUser } from "./mock";
import { getISODate, toArray } from "./utils";
import { Card, Task } from "./types";
import {
  cardReducerFilter,
  isSameContainer,
  isUnplanned,
  naiveViewGenerator,
} from "~/lib/planning/card-reducer.helpers";

export type CardReducerAction =
  | {
      type: "SET_INITIAL";
      cards: Record<string, Card>;
      tasks: Record<string, Task>;
    }
  | {
      type: "UPDATE_CARD";
      data: { card: Partial<Card>; task?: Partial<Task> };
      onFinish?: ({
        from,
        to,
      }: {
        newCard: Card;
        oldCard: Card;
        newTask: Task;
        oldTask: Task;
        from: Array<Card>;
        to: Array<Card>;
      }) => void;
    }
  | {
      type: "ADD_CARD";
      card: Card;
      onFinish?: ({ to }: { to: Array<Card> }) => void;
    }
  | {
      type: "REMOVE_CARD";
      card: Partial<Card>;
      onFinish?: ({ from, originalCard }: { from: Array<Card>; originalCard: Card }) => void;
    }
  | {
      type: "ADD_TASK";
      task: Task;
    }
  | {
      type: "SET_USERS";
      users: Record<string, MockUser>;
    };

export type CardReducerViewState = {
  allCards: Array<Card>;
  plannedAssigned: Array<Card>;
  plannedUnassigned: Array<Card>;
  unplanned: Array<Card>;
};

export type CardReducerState = {
  isReady: boolean; // False until first set of cards
  cards: Record<string, Card>;
  tasks: Record<string, Task>;
  users: Record<string, MockUser>;
  views: CardReducerViewState;
};

export function initialCardReducerState(): CardReducerState {
  return {
    isReady: false,
    cards: {},
    tasks: {},
    users: {},
    views: {
      allCards: [],
      plannedAssigned: [],
      plannedUnassigned: [],
      unplanned: [],
    },
  };
}

export function cardReducer(
  state: CardReducerState = initialCardReducerState(),
  action: CardReducerAction
): CardReducerState {
  switch (action.type) {
    case "SET_INITIAL":
      const newState = {
        ...state,
        cards: { ...action.cards },
        tasks: { ...action.tasks },
        isReady: true,
      };
      newState.views = naiveViewGenerator(newState);
      return newState;
    case "UPDATE_CARD":
      if (!action.data.card.id) {
        throw new Error("Cannot update card without id");
      }

      return produce(state, (draft) => {
        const fromCard = { ...state.cards[action.data.card.id!] } as Card;
        const toCard = {
          ...fromCard,
          ...action.data.card,
        } as Card;

        const sameContainer = isSameContainer(fromCard, toCard);
        const toIsUnplanned = isUnplanned(toCard);
        const fromIsUnplanned = isUnplanned(fromCard);

        // If we are moving an unplanned card to the unplanned container, we should not do anything
        if (fromIsUnplanned && toIsUnplanned) {
          return state;
        }

        const cardArr = toArray(draft.cards);

        let fromTask = {} as Task;
        let toTask = {} as Task;

        if (action.data.task?.id) {
          fromTask = { ...state.tasks[action.data.task.id] } as Task;
          toTask = {
            ...fromTask,
            ...action.data.task,
          } as Task;
        }

        const from = cardReducerFilter(cardArr, fromCard);
        const to = cardReducerFilter(cardArr, toCard);

        // Remove from old list
        from.splice(
          from.findIndex((v) => v.id === fromCard.id),
          1
        );

        // Some annoyance from the original code here
        // If we are in the same container, we should splice the new card into the old list instead of the new one
        if (sameContainer) {
          from.splice(toCard.listIndex ?? 0, 0, castDraft(toCard));
        } else {
          to.splice(toCard.listIndex ?? 0, 0, castDraft(toCard));
        }

        const fromList = fromIsUnplanned ? [] : from;
        const toList = sameContainer ? [] : to;

        // Assign new indexes to old list
        fromList.forEach((c, idx) => {
          draft.cards[c.id].listIndex = !!c.date ? idx : null;
        });

        // Assign new indexes to new list
        toList.forEach((c, idx) => {
          draft.cards[c.id].listIndex = !!c.date ? idx : null;
        });

        // Assign new index to new card
        toCard.listIndex = draft.cards[toCard.id].listIndex;

        // Run onFinish function
        action.onFinish?.({
          from: fromList,
          to: toList,
          newCard: { ...toCard },
          oldCard: fromCard,
          oldTask: fromTask,
          newTask: toTask,
        });

        // Assign new data to draft
        draft.cards[toCard.id] = toCard;
        draft.tasks[toTask.id] = toTask;
        draft.views = naiveViewGenerator(draft);
      });

    case "ADD_CARD":
      if (!action.card.id) {
        throw new Error("Cannot update card without id");
      }

      return produce(state, (draft) => {
        draft.cards[action.card.id] = action.card;
        draft.views = naiveViewGenerator(draft);
      });

    case "REMOVE_CARD":
      if (!action.card.id) {
        throw new Error("Cannot remove card without id");
      }

      const card = { ...state.cards[action.card.id] } as Card;
      const originalCard = Object.assign({}, card);

      return produce(state, (draft) => {
        const cards = toArray(draft.cards);

        const from = card.date
          ? cards.filter((c) => {
              return c.userId === card.userId && getISODate(c.date) === getISODate(card.date);
            })
          : cards.filter((c) => !c.date);

        from.splice(
          from.findIndex((v) => v.id === card.id),
          1
        );

        from.forEach((c, idx) => {
          draft.cards[c.id].listIndex = idx;
        });

        action.onFinish?.({ from, originalCard });

        delete draft.cards[card.id];

        draft.views = naiveViewGenerator(draft);
      });

    case "ADD_TASK":
      return produce(state, (draft) => {
        if (action.task && action.task.id) {
          draft.tasks[action.task.id] = action.task;
        }
      });

    case "SET_USERS":
      return {
        ...state,
        users: action.users,
        views: {
          ...state.views,
        },
      };
    default:
      throw new Error("Unsupported action");
  }
}

export function useCardReducer(): [CardReducerState, Dispatch<CardReducerAction>] {
  return useReducer(cardReducer, initialCardReducerState());
}
