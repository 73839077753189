import { useEffect, useRef, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Icon } from "~/lib/ui";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useTranslation } from "react-i18next";
import { LabelEntity } from "@apacta/sdk";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";

const identifier = "employeeLabel";

export function EmployeeLabelFilterSubmenu({ query }: { query: string }) {
  const [searchParams, setSearchParams] = useTypedSearchParams<{ [identifier]?: Array<string> }>();
  const { t } = useTranslation();

  const lastShownElement = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const { employeeLabels, setSearchQuery, isLoadingLabels } = useCategoryLabels("employee");

  const handleSelectEmployeeLabel = (labelId: string) => {
    if (searchParams[identifier]?.includes(labelId)) {
      setSearchParams(
        identifier,
        searchParams[identifier].filter((id) => id !== labelId)
      );
    } else {
      setSearchParams(identifier, [...(searchParams[identifier] ?? []), labelId]);
    }
  };

  // Split projects into selected and not selected array based on searchParams.projectId using .reduce
  const [selectedEmployeeLabels, notSelectedEmployeeLabels] = employeeLabels.reduce(
    (acc: Array<Array<LabelEntity>>, employeeLabel) => {
      if (searchParams[identifier]?.includes(employeeLabel.id)) {
        return [[...acc[0], employeeLabel], acc[1]];
      }
      return [acc[0], [...acc[1], employeeLabel]];
    },
    [[], []]
  );

  useEffect(() => {
    if (query !== undefined) {
      setSearchQuery(query);
    }

    return () => setSearchQuery("");
  }, [query]);

  return (
    <>
      <ScrollArea.Root>
        <ScrollArea.Viewport className="max-h-52 w-full max-w-64">
          <Collapsible.Root open={open} onOpenChange={setOpen}>
            <Collapsible.Trigger asChild>
              <DropdownMenu.Label className="flex items-center justify-between pl-1.5 pr-4 text-xs font-semibold leading-6 hover:bg-shade-100">
                <div className="flex items-center gap-2">
                  <div>{`${t("common:selected", { count: 2 })} ${t("common:label", { count: 2 }).toLowerCase()}`}</div>
                  <div className="w-6 items-center justify-center">
                    <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
                      {searchParams[identifier]?.length ?? 0}
                    </div>
                  </div>
                </div>
                <Icon
                  onClick={() => setOpen(!open)}
                  name={open ? "chevronUp" : "chevronDown"}
                  className="h-4 w-4"
                />
              </DropdownMenu.Label>
            </Collapsible.Trigger>
            <Collapsible.Content className="animate-slide-down">
              {selectedEmployeeLabels.map((employeeLabel) => (
                <DropdownMenu.CheckboxItem
                  key={employeeLabel.id}
                  onSelect={(e) => e.preventDefault()}
                  className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
                  checked={searchParams[identifier]?.includes(employeeLabel.id)}
                  onCheckedChange={() => handleSelectEmployeeLabel(employeeLabel.id)}
                >
                  <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                    <Icon name="selectedCheck" />
                  </DropdownMenu.ItemIndicator>
                  <UICategoryLabel
                    readonly
                    id={employeeLabel.id}
                    bgColor={employeeLabel.bgColor}
                    text={employeeLabel.text}
                  />
                </DropdownMenu.CheckboxItem>
              ))}
            </Collapsible.Content>
          </Collapsible.Root>
          <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
          <DropdownMenu.Label className="flex justify-between pl-1.5 text-xs font-semibold leading-6">
            <div>{`${t("common:not")} ${t("common:selected", { count: 2 }).toLowerCase()} ${t("common:label", { count: 2 }).toLowerCase()}`}</div>
          </DropdownMenu.Label>

          {isLoadingLabels && notSelectedEmployeeLabels.length === 0 && (
            <SkeletonLoader template="list" />
          )}
          {notSelectedEmployeeLabels.map((employeeLabel, idx) => (
            <DropdownMenu.CheckboxItem
              key={employeeLabel.id}
              ref={idx === notSelectedEmployeeLabels.length - 1 ? lastShownElement : null}
              onSelect={(e) => e.preventDefault()}
              className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
              checked={searchParams[identifier]?.includes(employeeLabel.id)}
              onCheckedChange={() => handleSelectEmployeeLabel(employeeLabel.id)}
            >
              <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                <Icon name="selectedCheck" />
              </DropdownMenu.ItemIndicator>
              <UICategoryLabel
                readonly
                id={employeeLabel.id}
                bgColor={employeeLabel.bgColor}
                text={employeeLabel.text}
              />
            </DropdownMenu.CheckboxItem>
          ))}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="duration-[160ms] flex touch-none select-none bg-transparent p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
        >
          <ScrollArea.Thumb className="relative flex-1 rounded-sm bg-black/30 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-black/50" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </>
  );
}
