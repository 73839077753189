import {
  UsersGetWeekly200ResponseWeeklyViewInner,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner,
} from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { Fragment, useCallback } from "react";
import { Week, weeksInRange } from "~/lib/utils/date/date-utils";
import { sub } from "date-fns";
import { WeekSection } from "~/pages/_cmp/weekly-registration-table/week-section";
import { UserAvatar } from "~/lib/ui/avatar";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { CACHE_FORMS } from "~/pages/forms";
import { Button, getIcon, Icon } from "~/lib/ui";
import PageSection from "~/lib/ui/page-section";
import { Spinner } from "~/lib/ui/spinner";

// For simplicity....
export type WeeklyProject = UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner;
export interface WeeklyEmployeeRes extends UsersGetWeekly200ResponseWeeklyViewInner {}

type WeeklyEmployeeWithWeeks = UsersGetWeekly200ResponseWeeklyViewInner["user"] & {
  weeks: Array<Week & { projects: Array<WeeklyProject> }>;
};

export default function EmployeesWeeklyTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const employeeParams = useEmployeesParams();
  const startDate = employeeParams.startDate ?? sub(new Date(), { days: 14 }).toISOString();
  const endDate = employeeParams.endDate ?? new Date().toISOString();

  const dataQ = useQuery({
    queryKey: [CACHE_EMPLOYEES, "weekly", employeeParams],
    queryFn: () =>
      api.usersGetWeekly({
        startDate: startDate,
        endDate: endDate,
        employeeIds: employeeParams.employeeIds,
      }),
    placeholderData: keepPreviousData,
  });

  const dateRange = dataQ?.data?.dateRange ?? [];
  const _fromDate = dateRange.length > 0 ? dateRange[dateRange.length - 1].fromDate : undefined;
  const _toDate = dateRange.length > 0 ? dateRange[0].toDate : undefined;

  const weeks: Array<Week> =
    _fromDate && _toDate
      ? weeksInRange(_fromDate, _toDate, new Date(startDate), new Date(endDate)).reverse()
      : [];
  const employees = dataQ?.data?.weeklyView ?? [];

  const canApproveForms = dataQ?.data?.canApproveForms ?? false;

  const getWeekProjects = useCallback(
    (employee: WeeklyEmployeeRes, week: Week): Array<WeeklyProject> => {
      return (
        employee.weeks.find((w) => {
          return +w.year === week.year && +w.week === week.weekNumber;
        })?.projects ?? []
      );
    },
    [employees, weeks]
  );

  const queryClient = useQueryClient();

  const updateFormMutation = useMutation({
    mutationKey: [CACHE_FORMS, CACHE_EMPLOYEES, employeeParams],
    mutationFn: (data: { projectId: string; state: boolean; formsIds: Array<string> }) =>
      api.changeStatus({
        projectId: data.projectId,
        changeStatusRequest: {
          approve: data.state,
          forms: data.formsIds,
        },
      }),
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: [CACHE_FORMS],
      }),
  });

  const handleToggleFormState = (projectId: string, state: boolean, formIds: Array<string>) => {
    if (formIds.length < 1) {
      return;
    }
    updateFormMutation.mutate({
      projectId: projectId,
      state: state,
      formsIds: formIds,
    });
  };

  const isLoading = dataQ.isLoading;
  const displayEmployeeData = !!employees.length && !dataQ.isLoading;

  return (
    <div className="flex flex-col gap-2">
      <div className="inline-flex items-center justify-between gap-4">
        <h2 className="mb-2"> {t("users:weekly_overview", "Weekly overview")}</h2>
        <Button
          className="print:hidden"
          variant="secondary"
          Icon={getIcon("print")}
          onClick={() => window.print()}
        />
      </div>

      {isLoading && (
        <div className="relative flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}

      <div className="flex flex-col gap-12 print:gap-6">
        {displayEmployeeData
          ? employees.map((employee) => {
              if (!employee.weeks.length) return null;
              return (
                <Fragment key={`employee-section-${employee.user.id}`}>
                  <div className="flex flex-col gap-4 print:gap-0">
                    <HorizontalDivider
                      lineClassName="border-t-4 border-shade-600 print:hidden"
                      lineLeftWrapperClassName="w-12 print:hidden"
                    >
                      <div className="flex items-center gap-4">
                        <div className="print:hidden">
                          <UserAvatar user={employee.user} className="h-10 w-10" />
                        </div>
                        <div className="whitespace-nowrap text-xl print:text-lg">
                          {employee.user.fullName}
                        </div>
                      </div>
                    </HorizontalDivider>

                    <div className="flex flex-col gap-12 print:gap-6">
                      {weeks.map((week) => {
                        const weekProjects = getWeekProjects(employee, week);
                        if (weekProjects.length === 0) return null;
                        return (
                          <WeekSection
                            key={`week-section-${employee.user.id}-${week.year}-${week.weekNumber}`}
                            week={week}
                            projects={getWeekProjects(employee, week)}
                            canApproveForms={canApproveForms}
                            onToggle={handleToggleFormState}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              );
            })
          : !isLoading && (
              <PageSection>
                <div className="flex flex-col items-center justify-center gap-1">
                  <div>
                    <Icon name="infoCircle" className="h-18 w-18" />{" "}
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="font-medium">{t("users:weekly.no_registrations_title")}</div>
                    <div className="whitespace-pre-line text-center text-sm">
                      {t("users:weekly.no_registrations")}
                    </div>
                  </div>
                </div>
              </PageSection>
            )}
      </div>
    </div>
  );
}
