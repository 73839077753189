import { z } from "zod";
import { UserAdminAccessEnum } from "@apacta/sdk";

export const userSettingsSchema = {
  projectIds: z.array(z.string().uuid()),
  timeEntryRuleGroupId: z.string().optional().nullable(),
  adminAccess: z.nativeEnum(UserAdminAccessEnum).default(UserAdminAccessEnum.NoAccessToAdmin),
  userIntegrationSetting: z.array(
    z.object({
      id: z.string().uuid(),
      value: z.string().nullable(),
    })
  ),
};
