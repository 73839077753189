import { Link } from "react-router-dom";
import { NavigationItem } from "../navigation-item/navigation-item";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ReleaseNotesNavItem } from "~/lib/release-notes";
import { useSidebarContext } from "../../../sidebar-context";
import { useNavigationContent } from "../navigation-item/navigation-item.content";

import { Dialog, Icon } from "~/lib/ui";
import { useRoles } from "~/lib/auth/use-roles";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { LanguageModal } from "~/lib/navigation/navigation-layout/sidebar/components/modal/language-modal";
import { BACKEND_URL } from "~/lib/auth/config";
import { useSession } from "~/lib/auth/session";
import AiSupportItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/ai-support-item";
import LanguagePopoverItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/language-popover-item";
import { QuickCreate } from "~/lib/navigation/navigation-layout/sidebar/components/quick-create/quick-create";
import { SideBarScrollButtons } from "~/lib/navigation/navigation-layout/sidebar/components/sidebar-scroll-buttons";

export function SidebarExpanded() {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const roles = useRoles();
  const isAdmin = roles.has("admin");
  const { apiKey } = useSession();

  const [languageOpen, setLanguageOpen] = useState<boolean>(false);

  const { sidebarType, onSidebarCollapse, sidebarCollapsed } = useSidebarContext();
  const { groupedNavigation } = useNavigationContent();

  return (
    <div
      className={twMerge(
        "fixed inset-y-0 flex w-64  flex-col print:hidden",
        sidebarType === "laptop" ? "z-navigation-menu" : "",
        sidebarCollapsed && "hidden"
      )}
    >
      <Dialog
        open={languageOpen}
        onOpenChange={setLanguageOpen}
        render={({ onClose }) => <LanguageModal onClose={onClose} />}
      />
      {isAdmin && (
        <div className="absolute left-0 top-0">
          <Link
            to={`${BACKEND_URL}/admin?api_key=${apiKey}`}
            target="_blank"
            className="absolute left-0 top-0 -ml-10 -mt-10 h-20 w-20 rotate-45 cursor-pointer bg-hover"
          >
            <Icon
              name="superadmin"
              className="absolute bottom-7 right-2 h-5 w-auto -rotate-45 text-white"
            />
          </Link>
        </div>
      )}
      {/* [END] Show super admin link if user is admin */}
      <div className="pointer-events-none absolute top-2 flex h-10 w-full justify-end">
        <div
          className="pointer-events-auto -mr-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-hover text-white"
          onClick={() => onSidebarCollapse(true)}
        >
          <Icon name="chevronLeft" className="h-4 w-4" aria-hidden="true" />
        </div>
      </div>
      <SideBarScrollButtons scrollRef={scrollRef} navRef={navRef} />
      <div
        ref={scrollRef}
        className="scrollbar-hide flex flex-grow flex-col overflow-y-auto bg-primary pt-8"
      >
        <div className="flex flex-shrink-0 items-center justify-center px-4">
          <Link
            to="/"
            state={{ refreshKey: crypto.getRandomValues(new Uint8Array(1))[0] }} // workaround while we have iframes
          >
            <img className="h-10 w-auto" src="/logo.png" alt={t("ui:navigation.logo_alt")} />
          </Link>
        </div>
        <div className="flex flex-1 flex-col pt-2">
          <nav
            ref={(e) => (navRef.current = e)}
            className="flex h-full flex-col justify-between space-y-1 pt-4"
          >
            {/* TOP PART OF NAVIGATION */}
            <div className="flex flex-col gap-6 px-4">
              {/* Quick Create Menu */}
              <QuickCreate />
              <div>
                {groupedNavigation.map((group) => (
                  <div key={group.identifier} className="flex flex-col gap-2 pb-4">
                    <HorizontalDivider
                      lineClassName="border-white/30"
                      lineLeftWrapperClassName="w-5"
                    >
                      <span className="text-sm text-white/80">{group.title}</span>
                    </HorizontalDivider>
                    <div>
                      {group.items.map((item, idx) => (
                        <Fragment key={`${item.name}-${idx}`}>
                          {!!item.componentIdentifier ? (
                            <>{item.componentIdentifier === "news" && <ReleaseNotesNavItem />}</>
                          ) : (
                            <NavigationItem item={item} />
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* BOTTOM PART OF NAVIGATION */}
            <div>
              <AiSupportItem />
              <div className="flex divide-x divide-white/30 border-t border-t-white/30 text-sm text-white">
                <Link
                  to="/logout"
                  className="flex basis-1/2 cursor-pointer justify-center gap-2 py-3 hover:bg-hover"
                >
                  <Icon name="logout" className="h-5 w-auto" />
                  <span>{t("common:logout")}</span>
                </Link>

                <LanguagePopoverItem />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
