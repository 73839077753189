import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "~/lib/ui";
import { Suspense, useRef } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { useDebouncedValue } from "~/lib/debounce/use-debounce";
import { DataFilterItem } from "~/lib/ui/data-table/data-filter/data-filter";
import { Lozenge } from "~/lib/ui/data-table/data-filter/filters/filter-components/lozenge";
import { FormTemplateFilterSubmenu } from "~/lib/ui/data-table/data-filter/filters/filter-components/form-template-filter-submenu";
import useInfiniteFormTemplates from "~/lib/form-templates/use-infinite-form-templates";

export const FORM_TEMPLATE_IDENTIFIER = "formTemplateIds";

const FormTemplateFilter = () => {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    [FORM_TEMPLATE_IDENTIFIER]?: Array<string>;
  }>();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue("");

  const clearSearchParam = () => setSearchParams(FORM_TEMPLATE_IDENTIFIER, undefined);

  return (
    <>
      <DropdownMenu.Label className="flex justify-between py-1.5 pl-1.5 text-xs font-semibold leading-6">
        <Lozenge
          label={t("planning:form", { count: 2 })}
          size="small"
          iconName="formTemplate"
          variant="blue"
        />
        {searchParams[FORM_TEMPLATE_IDENTIFIER] !== undefined && (
          <div
            onClick={clearSearchParam}
            className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
          >
            <Icon name="remove" />
            <span>{t("common:clear")}</span>
          </div>
        )}
      </DropdownMenu.Label>

      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger className=" group relative flex h-6 select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[highlighted]:data-[state=open]:bg-shade-100 data-[state=open]:bg-shade-100">
          <div className="absolute left-0 inline-flex w-6 items-center justify-center">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
              {searchParams[FORM_TEMPLATE_IDENTIFIER]?.length ?? 0}
            </div>
          </div>
          {t("common:select_entity", { entity: t("planning:form", { count: 2 }).toLowerCase() })}
          <div className=" group- ml-auto pl-[20px] ">
            <Icon name="chevronRight" />
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade min-w-64 rounded-md border bg-white p-1.5 shadow-md will-change-[opacity,transform]"
            sideOffset={2}
            alignOffset={-5}
          >
            <div className="relative flex items-center" onKeyDown={(e) => console.log(e)}>
              <Icon name="search" className="absolute left-1.5 h-4 w-4" />
              <input
                type="text"
                ref={searchInputRef}
                onChange={(e) => setValue(e.currentTarget.value)}
                onKeyDown={(e) => e.stopPropagation()}
                defaultValue={debouncedValue}
                placeholder={t("common:search")}
                className="w-full border-none bg-transparent pb-0.5 pl-7 pr-1.5 pt-1 text-sm outline-none ring-0 focus:outline-none focus:ring-0"
              />
            </div>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
            <Suspense fallback={<SkeletonLoader template="list" />}>
              <FormTemplateFilterSubmenu query={debouncedValue} />
            </Suspense>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
};

const FormTemplateFilterLozenge = ({ values }: { values: Array<string> }) => {
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    [FORM_TEMPLATE_IDENTIFIER]?: Array<string>;
  }>();
  const { t } = useTranslation();

  const { formTemplates } = useInfiniteFormTemplates({ includedFormTemplateIds: values });

  const shownFormTemplates = formTemplates?.filter((d) => values.includes(d.id));

  const handleRemoveFormTemplate = (v: string) => {
    const newValue = searchParams[FORM_TEMPLATE_IDENTIFIER]?.filter((id) => id !== v);

    // If there are no values, remove the search param
    if (!newValue || newValue.length === 0) {
      setSearchParams(FORM_TEMPLATE_IDENTIFIER, undefined);
      return;
    }

    // Otherwise, update the search param
    setSearchParams(FORM_TEMPLATE_IDENTIFIER, newValue);
  };

  if (!shownFormTemplates.length) return null;

  return (
    <Lozenge
      label={t("planning:form", { count: 1 })}
      iconName="formTemplate"
      variant="blue"
      values={shownFormTemplates?.map((d) => ({ label: d.name, value: d.id }))}
      onRemove={(v) => handleRemoveFormTemplate(v)}
      hideLabel={true}
    />
  );
};

export const FORM_TEMPLATE_FILTER: DataFilterItem<Array<string>> = {
  identifier: FORM_TEMPLATE_IDENTIFIER,
  filterFn: (templateId: string, selectedTemplateId: Array<string>) =>
    selectedTemplateId.includes(templateId),
  Lozenge: FormTemplateFilterLozenge,
  render: FormTemplateFilter,
};
