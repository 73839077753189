import { PageLayout } from "~/lib/ui/page-layout";
import { ProcurementTable } from "~/pages/procurement/_cmp/table/procurement-table";
import { Button, Dialog, getIcon, Label } from "~/lib/ui";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { useMemo, useState } from "react";
import KpiSection from "~/pages/procurement/_cmp/kpi/kpi-section";
import { useTranslation } from "react-i18next";
import CreatePriceAnalysisDialog from "~/pages/procurement/_cmp/dialog/create-price-analysis-dialog";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { toLocalDateString } from "~/lib/utils/date";
import { getOffsetDate } from "~/lib/utils/date/date-utils";
import CompaniesVendorSelection from "~/lib/ui/vendor/companies-vendor-selection";

export type ProcurementPageSearchParams = {
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  vendorIds?: Array<string>;
};

export const CACHE_PROCUREMENT = "procurement";

export default function ProcurementPage() {
  const [searchParams, setSearchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleDateRangeChange = ({ from, to }: DateRange) => {
    setSearchParams("dateFrom", from ? toLocalDateString(from) : undefined);
    setSearchParams("dateTo", to ? toLocalDateString(to) : undefined);
  };

  const handleVendorChange = (vendorIds: Array<string>) => {
    setSearchParams("vendorIds", vendorIds.length > 0 ? vendorIds : undefined);
  };

  const vendorIds = searchParams.vendorIds ?? [];

  const dateRange = useMemo(
    () => ({
      from: searchParams.dateFrom
        ? new Date(searchParams.dateFrom)
        : new Date(getOffsetDate(new Date(), -365)),
      to: searchParams.dateTo ? new Date(searchParams.dateTo) : new Date(),
    }),
    [searchParams.dateFrom, searchParams.dateTo]
  );

  return (
    <PageLayout
      title={t("procurement:title")}
      renderDescription={() => (
        <div className="flex w-full gap-4">
          <div className="shrink-0">
            <Label>{t("common:date_range")}</Label>
            <DateRangePopover
              compact={false}
              dateFrom={dateRange.from}
              dateTo={dateRange.to}
              onChange={handleDateRangeChange}
              allowToDateInFuture={true}
            />
          </div>
          <div className="min-w-60 max-w-xl">
            <CompaniesVendorSelection
              defaultSelected={vendorIds}
              onSelect={handleVendorChange}
              dateRange={{ from: dateRange.from, to: dateRange.to }}
            />
          </div>
        </div>
      )}
      renderActions={() => (
        <Dialog
          triggerAsChild
          trigger={
            <Button Icon={getIcon("add")} variant="tertiary" onClick={() => setOpen(true)}>
              {t("common:create", {
                entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
              })}
            </Button>
          }
          render={({ onClose }) => (
            <CreatePriceAnalysisDialog
              onClose={onClose}
              dateFrom={dateRange.from}
              dateTo={dateRange.to}
            />
          )}
        />
      )}
    >
      <KpiSection dateFrom={dateRange.from} dateTo={dateRange.to} />
      <ProcurementTable dateFrom={dateRange.from} dateTo={dateRange.to} />
    </PageLayout>
  );
}
