import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { Table, TBody, TD, TH, THead, TR } from "~/lib/ui/table/table";
import { linkToInvoiceV3 } from "~/lib/utils";
import { useLocale } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";
import { isVisible } from "./invoice-logic";

export function CreateInvoiceExistingDrafts({ projectId }: { projectId: string }) {
  const api = useAPI();
  const { format } = useLocale();
  const { t } = useTranslation();
  const invoiceQ = useSuspenseQuery({
    queryKey: ["invoices", "drafts", projectId],
    queryFn: () =>
      api.iListInvoices({
        filter: "drafted",
        projectId,
        sort: "issued_date",
        direction: "desc",
      }),
  });

  const deleteDraft = useMutation({
    mutationFn: (id: string) => api.deleteInvoice({ invoiceId: id }),
    onSuccess: () => {
      invoiceQ.refetch();
    },
  });

  if (invoiceQ.data.data.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="font-semibold">{t("invoices:existing_drafts")}</div>
      <div className="max-h-64 overflow-y-scroll">
        <Table>
          <THead>
            <TR>
              <TH>{t("invoices:issued_date", "Issued date")}</TH>
              <TH className="text-right">{t("finance:total_with_vat", "Total w. VAT")}</TH>
              <TH></TH>
            </TR>
          </THead>
          <TBody>
            {invoiceQ.data?.data.map((draft) => (
              <TR key={draft.id}>
                <TD>{format(draft.issuedDate, { shortDate: true })}</TD>
                <TD className="text-right">{formatCurrency(draft.netPayment ?? 0)}</TD>
                <TD className="text-right">
                  <ActionButtons
                    size="small"
                    actions={[
                      {
                        label: t("common:edit"),
                        Icon: getIcon("edit"),
                        onClick: () => {},
                        href: linkToInvoiceV3(draft.id),
                        isHidden: !isVisible("edit-action-button", draft),
                      },
                      {
                        label: t("common:delete"),
                        Icon: getIcon("delete"),
                        confirm: {
                          action: "delete",
                          entity: "draft",
                        },
                        onClick: () => deleteDraft.mutateAsync(draft.id),
                        isHidden: !isVisible("delete-action-button", draft),
                      },
                    ]}
                  />
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </div>
    </div>
  );
}
