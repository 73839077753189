import { useInfiniteQuery } from "@tanstack/react-query";
import { useAPI } from "../api";
import { useEffect } from "react";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { GetUsersRequest } from "@apacta/sdk";

const DefaultEmployeesRequestOptions: GetUsersRequest = {
  sort: "name",
  direction: "asc",
  limit: 100,
  isActive: true,
};

type UseEmployeesOptions = {
  requestOverride?: GetUsersRequest;
};

/**
 * Lists all employees. Will automatically traverse all the pages.
 */
export function useEmployees(options?: UseEmployeesOptions) {
  const api = useAPI();
  const uq = useInfiniteQuery({
    queryKey: [CACHE_EMPLOYEES, "selection", options?.requestOverride],
    queryFn: ({ pageParam }) =>
      api.listUsers({
        ...DefaultEmployeesRequestOptions,
        ...options?.requestOverride,
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.hasNextPage === false) return null;
      return lastPage.pagination.currentPage + 1;
    },
    initialPageParam: 1,
  });

  // React to a page fetch, and fetches next page is available
  useEffect(() => {
    if (!uq.isSuccess) return;
    if (uq.hasNextPage) {
      uq.fetchNextPage();
    }
  }, [uq.isSuccess]);

  const items = (() => {
    return uq.data?.pages.flatMap((page) => page.data) || [];
  })();

  return {
    employees: items,
    isFetching: uq.isFetching,
    refetch: uq.refetch,
  };
}
