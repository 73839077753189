import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigationContent } from "./components/navigation-item/navigation-item.content";
import { useLocation } from "react-router";
import { Button, Icon } from "~/lib/ui";
import { twMerge } from "tailwind-merge";
import { NavigationItem } from "./components/navigation-item/navigation-item";
import { useSidebarContext } from "../sidebar-context";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { ReleaseNotesNavItem } from "~/lib/release-notes";
import AiSupportItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/ai-support-item";
import LanguagePopoverItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/language-popover-item";
import { useTranslation } from "react-i18next";
import { QuickCreate } from "~/lib/navigation/navigation-layout/sidebar/components/quick-create/quick-create";

export function SidebarMobile() {
  const { groupedNavigation } = useNavigationContent();
  const { sidebarOpen, onVisibilityChange } = useSidebarContext();
  const location = useLocation();
  const { t } = useTranslation();

  // Disable background scrolling when sidebar is open
  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebarOpen]);

  // Close sidebar when location changes
  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  // Show sidebar when triggered
  useEffect(() => {
    if (sidebarOpen) {
      onVisibilityChange(true);
    }
  }, [sidebarOpen]);

  const handleClose = () => {
    onVisibilityChange(false);
  };

  return (
    <>
      <div
        className={twMerge(
          "fixed bottom-0 left-0 right-0 top-0 bg-black/30",
          sidebarOpen ? "z-modal-backdrop" : "hidden"
        )}
        onAnimationEnd={() => !sidebarOpen && onVisibilityChange(false)}
      >
        <div className="flex h-screen">
          <div
            className={twMerge(
              "flex h-screen w-4/5 flex-col bg-primary pt-4 text-white",
              sidebarOpen ? "animate-enter-from-left" : "animate-exit-to-left"
            )}
          >
            <div className="flex flex-shrink-0 items-center px-4">
              <Link to="/">
                <img className="h-12 w-auto" src="/logo.png" alt="Apacta logo" />
              </Link>
            </div>

            <nav className="scrollbar-hide mt-4 flex h-full shrink flex-col justify-between gap-8 space-y-1 overflow-y-auto">
              <div className="flex flex-col px-4">
                <div className="py-2">
                  <QuickCreate />
                </div>
                {groupedNavigation.map((group) => (
                  <div key={group.identifier} className="flex flex-col gap-2 pb-4">
                    <HorizontalDivider
                      lineClassName="border-white/30"
                      lineLeftWrapperClassName="w-5"
                    >
                      <span className="text-sm text-white/80">{group.title}</span>
                    </HorizontalDivider>
                    <div>
                      {group.items.map((item, idx) => (
                        <Fragment key={`${item.name}-${idx}`}>
                          {!!item.componentIdentifier ? (
                            <>{item.componentIdentifier === "news" && <ReleaseNotesNavItem />}</>
                          ) : (
                            <NavigationItem item={item} />
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {/* BOTTOM PART OF NAVIGATION */}
              <div>
                <AiSupportItem />
                <div className="flex divide-x divide-white/30 border-t border-t-white/30 text-sm text-white">
                  <Link
                    to="/logout"
                    className="flex basis-1/2 cursor-pointer justify-center gap-2 py-3 hover:bg-hover"
                  >
                    <Icon name="logout" className="h-5 w-auto" />
                    <span>{t("common:logout")}</span>
                  </Link>

                  <LanguagePopoverItem />
                </div>
              </div>
            </nav>
          </div>
          <div className="relative flex w-1/5 justify-center pt-4">
            <Button
              onClick={() => handleClose()}
              className={twMerge(
                "absolute flex h-12 w-12 items-center justify-center rounded-full bg-white text-zinc-700",
                sidebarOpen ? "animate-enter-from-right" : "animate-exit-to-right"
              )}
            >
              <Icon name="close" className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
