import { ProcurementIndexQuery } from "~/lib/gql";
import { Badge } from "~/lib/ui/badge";

type CompanyVendorSelectionType =
  ProcurementIndexQuery["procurementAnalyses"]["edges"][0]["node"]["companiesVendors"];
export function VendorCell({ companiesVendors }: { companiesVendors: CompanyVendorSelectionType }) {
  if (!companiesVendors) return null;

  const sortedCompaniesVendors = companiesVendors
    ?.filter((companyVendor) => !!companyVendor)
    ?.sort((a, b) => a!.vendor.name.localeCompare(b.vendor.name));

  return (
    <div className="flex flex-wrap gap-2">
      {sortedCompaniesVendors.map((companyVendor) => (
        <Badge key={companyVendor.id} variant="gray">
          <span className="mr-2">{companyVendor.vendor.name}</span>
        </Badge>
      ))}
    </div>
  );
}
