import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { Ref } from "react";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";

export function OrderLinesBuilderQuantityPart({
  line,
  quantityRef,
  editMode,
  parentId,
  width,
  allowNegativeNumbers,
}: {
  line: Partial<OrderLine>;
  quantityRef: Ref<HTMLInputElement>;
  editMode: boolean;
  parentId?: string | null;
  width: string;
  allowNegativeNumbers?: boolean;
}) {
  const { updateLine } = useOrderLinesBuilder();
  return (
    <div style={{ width }} className="flex-shrink-0 px-3 py-5 text-right text-zinc-900">
      {editMode ? (
        <NumberFormatInput
          ref={quantityRef}
          className="text-right text-sm"
          defaultValue={line.quantity}
          onChange={(v) => updateLine(["quantity"], { quantity: v }, line.identifier, parentId)}
          allowNegative={allowNegativeNumbers}
        />
      ) : (
        <span>{line.quantity}</span>
      )}
    </div>
  );
}
