import { Invoice } from "@apacta/sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { getIcon, Icon } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { CACHE_INVOICES } from "..";
import { useState } from "react";
import { InvoiceSendFragment, SendData } from "./invoice-send-fragment";

export function InvoiceSendDialog({
  onClose,
  pendingSave,
  invoice,
  recipientEmail,
}: {
  onClose: () => void;
  /** If a save is pending, we show message and can call it from here */
  pendingSave?: () => Promise<void>;
  invoice: Invoice;
  recipientEmail: string | undefined;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();

  const [sendData, setSendData] = useState<SendData>();

  async function handleSendInvoice() {
    if (!sendData) return;
    await pendingSave?.();
    await api.iInvoiceSendEmail({
      invoiceId: invoice.id,
      recipientEmail: sendData.recipientEmail,
      body: sendData.body,
      subject: sendData.subject,
      projectOverviewAttached: sendData.attachProjectOverviewPDF,
    });
    queryClient.invalidateQueries({
      queryKey: [CACHE_INVOICES, invoice.id],
    });
    onClose();
  }

  return (
    <div className="">
      <DialogHeader title={t("invoices:send_invoice")} Icon={getIcon("send")} />
      <div className="flex flex-col gap-4">
        {pendingSave && (
          <div className="flex gap-4">
            <div>
              <Icon name="warningTriangle" className="h-8 w-8 text-orange-600" />
            </div>
            <div className="whitespace-pre-line">{t("offers:send_offer_modal.description")}</div>
          </div>
        )}
        <div className="flex flex-col gap-4">
          <InvoiceSendFragment
            invoice={invoice}
            onChange={setSendData}
            recipientEmail={recipientEmail}
          />
        </div>
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          label: t("invoices:send_invoice"),
          onClick: handleSendInvoice,
          disabled: !sendData?.isValid,
        }}
      />
    </div>
  );
}
