import { Popover } from "~/lib/ui/popover/popover";
import { availableLanguagesList, getResolvedLanguage, LanguageDefinition } from "~/lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useSession } from "~/lib/auth/session";
import { useState } from "react";
import { Spinner } from "~/lib/ui/spinner";
import { useSidebarContext } from "~/lib/navigation/navigation-layout/sidebar-context";
import { twMerge } from "tailwind-merge";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";
import { Icon } from "~/lib/ui";

const languageIconMap: { [key: string]: string } = {
  da: "dk",
  de: "de",
  en: "gb",
};

export default function LanguagePopoverItem() {
  const resolvedLanguage = getResolvedLanguage();
  const { t } = useTranslation();
  const { sidebarCollapsed } = useSidebarContext();
  const { isBreakpoint } = useBreakpoints();
  const isMobile = isBreakpoint("md"); // Check if we are at or below the md breakpoint

  const { setLanguage } = useSession();
  const [loadingKey, setLoadingKey] = useState<string | undefined>();

  async function handleSetLanguage(language: LanguageDefinition, callback: () => void) {
    setLoadingKey(language.id);
    try {
      await setLanguage(language);
    } catch (err) {
      console.error("Error happened");
    } finally {
      setLoadingKey(undefined);
      callback();
    }
  }

  return (
    <Popover
      config={{
        side: "right",
        align: "start",
        sideOffset: sidebarCollapsed ? 16 : 0,
        arrow: true,
        arrowColor: "#213449",
      }}
      useMobilePopover
      triggerAsChild
      triggerRender={() => (
        <div
          role="button"
          tabIndex={0}
          className={twMerge(
            "basis-1/2 cursor-pointer py-3 hover:bg-hover",
            sidebarCollapsed && "rounded-lg"
          )}
        >
          <div className="flex w-full justify-center gap-2">
            <img
              src={`/flags/${languageIconMap[resolvedLanguage]}.png`}
              className={twMerge(sidebarCollapsed ? "h-6 w-7" : "h-5 w-6")}
              alt={resolvedLanguage}
            />
            {!sidebarCollapsed && <span>{t(`common:lang.${resolvedLanguage}`)}</span>}
          </div>
        </div>
      )}
    >
      {(close) => (
        <div
          className={twMerge(
            "relative overflow-hidden",
            isMobile
              ? "-ml-px w-screen rounded-t-lg bg-white p-6 text-zinc-700"
              : "min-w-40 rounded-lg bg-primary p-2 text-white shadow-md"
          )}
        >
          {isMobile && (
            <>
              <div className="text-lg font-medium">{t("ui:navigation.change_language")}</div>
              <div className="absolute right-2 top-2 p-2" onClick={close}>
                <Icon name="close" className="h-6 w-6" />
              </div>
            </>
          )}
          <div className="flex cursor-pointer flex-col text-sm font-normal">
            {availableLanguagesList.map(([key, value]) => (
              <div
                key={`lang-${key}`}
                role="button"
                tabIndex={0}
                className={twMerge(
                  "flex items-center gap-3 rounded-lg px-3",
                  isMobile ? "py-4" : "py-2 hover:bg-hover"
                )}
                onClick={() => handleSetLanguage(value, close)}
              >
                {loadingKey === value.id ? (
                  <div className="h-5 w-6">
                    <Spinner className="h-5 w-5" />
                  </div>
                ) : (
                  <img
                    src={`/flags/${value.flag}.png`}
                    className="h-5 w-6"
                    alt={t(`common:lang.${key}`)}
                  />
                )}
                <span>{value.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </Popover>
  );
}
