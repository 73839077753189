import { useMemo } from "react";
import { useSession } from "./session";
import { RoleIdentifierEnum } from "@apacta/sdk";

export function useRoles() {
  const { me } = useSession();

  const roles = useMemo(() => {
    let roleSet = new Set<RoleIdentifierEnum>();
    if (me?.user.roles) {
      roleSet = new Set(me.user.roles.map((role) => role.identifier));
    }
    return roleSet;
    // Will now refresh if `ME` is invalidated
  }, [me?.user.roles]);

  return roles;
}
