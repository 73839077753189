import { SelectionCombobox } from "~/lib/ui/selection-combobox";
import { useTranslation } from "react-i18next";
import { DateInterval, VendorSelectionQuery } from "~/lib/gql/generated/graphql";
import { useCompaniesVendors } from "~/lib/products/use-companies-vendors";
import { useEffect } from "react";

type CompaniesVendorSelectionType = VendorSelectionQuery["companiesVendors"]["edges"][0];

export default function CompaniesVendorSelection({
  required = false,
  defaultSelected,
  dateRange,
  onSelect,
}: {
  required?: boolean;
  defaultSelected?: Array<string>;
  dateRange?: DateInterval;
  onSelect: (vendorIds: Array<string>) => void;
}) {
  const { t } = useTranslation();
  const { companiesVendors, isFetching, setDateRange } = useCompaniesVendors(dateRange);

  useEffect(() => {
    setDateRange(dateRange);
  }, [dateRange]);

  return (
    <SelectionCombobox<CompaniesVendorSelectionType>
      multiple
      loading={isFetching}
      required={required}
      label={t("common:vendor", { count: 2 })}
      data={companiesVendors}
      defaultValue={defaultSelected}
      onSelectValues={onSelect}
      placeholder={t("common:select_entity", {
        entity: t("common:vendor", { count: 2 }).toLowerCase(),
      })}
      valueFn={(vendor) => vendor.node.id}
      labelFn={(vendor) => vendor.node.vendor.name}
      popover={{
        config: {
          align: "start",
        },
      }}
    />
  );
}
