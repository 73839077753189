import { useEffect, useRef, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Collapsible from "@radix-ui/react-collapsible";
import useInfiniteProjects from "~/lib/projects/use-infinite-projects";
import { Button, Icon } from "~/lib/ui";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import Tooltip from "~/lib/ui/tooltip";
import { linkToProject } from "~/lib/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Project } from "@apacta/sdk";
import { PROJECT_FILTER_IDENTIFIER } from "~/lib/ui/data-table/data-filter/filters/project-filter";

export function ProjectFilterSubmenu({ query }: { query: string }) {
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    [PROJECT_FILTER_IDENTIFIER]?: Array<string>;
  }>();
  const { t } = useTranslation();

  const lastShownElement = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const { projects, setQuery, hasNextPage, fetchNextPage, isFetching } = useInfiniteProjects({
    includedProjectIds: searchParams[PROJECT_FILTER_IDENTIFIER],
  });

  const handleSelectProject = (projectId: string) => {
    if (searchParams[PROJECT_FILTER_IDENTIFIER]?.includes(projectId)) {
      setSearchParams(
        PROJECT_FILTER_IDENTIFIER,
        searchParams[PROJECT_FILTER_IDENTIFIER].filter((id) => id !== projectId)
      );
    } else {
      setSearchParams(PROJECT_FILTER_IDENTIFIER, [
        ...(searchParams[PROJECT_FILTER_IDENTIFIER] ?? []),
        projectId,
      ]);
    }
  };

  // Split projects into selected and not selected array based on searchParams.projectId using .reduce
  const [selectedProjects, notSelectedProjects] = projects.reduce(
    (acc: Array<Array<Project>>, project) => {
      if (searchParams[PROJECT_FILTER_IDENTIFIER]?.includes(project.id)) {
        return [[...acc[0], project], acc[1]];
      }
      return [acc[0], [...acc[1], project]];
    },
    [[], []]
  );

  useEffect(() => {
    if (query !== undefined) {
      setQuery(query);
    }

    return () => setQuery("");
  }, [query]);

  return (
    <>
      <ScrollArea.Root>
        <ScrollArea.Viewport className="max-h-52 w-full max-w-64">
          <Collapsible.Root open={open} onOpenChange={setOpen}>
            <Collapsible.Trigger asChild>
              <DropdownMenu.Label className="flex items-center justify-between pl-1.5 pr-4 text-xs font-semibold leading-6 hover:bg-shade-100">
                <div className="flex items-center gap-2">
                  <div>{`${t("common:selected", { count: 2 })} ${t("common:project", { count: 2 }).toLowerCase()}`}</div>
                  <div className="w-6 items-center justify-center">
                    <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
                      {searchParams[PROJECT_FILTER_IDENTIFIER]?.length ?? 0}
                    </div>
                  </div>
                </div>
                <Icon
                  onClick={() => setOpen(!open)}
                  name={open ? "chevronUp" : "chevronDown"}
                  className="h-4 w-4"
                />
              </DropdownMenu.Label>
            </Collapsible.Trigger>
            <Collapsible.Content className="animate-slide-down">
              {selectedProjects.map((project) => (
                <DropdownMenu.CheckboxItem
                  key={project.id}
                  onSelect={(e) => e.preventDefault()}
                  className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
                  checked={searchParams[PROJECT_FILTER_IDENTIFIER]?.includes(project.id)}
                  onCheckedChange={() => handleSelectProject(project.id)}
                >
                  <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                    <Icon name="selectedCheck" />
                  </DropdownMenu.ItemIndicator>
                  <Tooltip
                    trigger={<div className="line-clamp-1">{project.fullName}</div>}
                    delay={500}
                  >
                    <Link
                      to={linkToProject(project.id)}
                      target="_blank"
                      className="flex items-center gap-2 text-sm hover:underline"
                    >
                      <div>{project.fullName}</div>
                      <Icon name="externalLink" className="h-4 w-4" />
                    </Link>
                  </Tooltip>
                </DropdownMenu.CheckboxItem>
              ))}
            </Collapsible.Content>
          </Collapsible.Root>
          <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
          <DropdownMenu.Label className="flex justify-between pl-1.5 text-xs font-semibold leading-6">
            <div>{`${t("common:not")} ${t("common:selected", { count: 2 }).toLowerCase()} ${t("common:project", { count: 2 }).toLowerCase()}`}</div>
          </DropdownMenu.Label>

          {notSelectedProjects.map((project, idx) => (
            <DropdownMenu.CheckboxItem
              key={project.id}
              ref={idx === notSelectedProjects.length - 1 ? lastShownElement : null}
              onSelect={(e) => e.preventDefault()}
              className="group relative flex h-8 w-full select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[disabled]:text-shade-400"
              checked={searchParams[PROJECT_FILTER_IDENTIFIER]?.includes(project.id)}
              onCheckedChange={() => handleSelectProject(project.id)}
            >
              <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-6 items-center justify-center">
                <Icon name="selectedCheck" />
              </DropdownMenu.ItemIndicator>
              <Tooltip trigger={<div className="line-clamp-1">{project.fullName}</div>} delay={500}>
                <Link
                  to={linkToProject(project.id)}
                  target="_blank"
                  className="flex items-center gap-2 text-sm hover:underline"
                >
                  <div>{project.fullName}</div>
                  <Icon name="externalLink" className="h-4 w-4" />
                </Link>
              </Tooltip>
            </DropdownMenu.CheckboxItem>
          ))}
          {hasNextPage && (
            <DropdownMenu.Item
              asChild
              className="group flex justify-center py-2 focus-visible:outline-none data-[highlighted]:outline-none"
              onSelect={(e) => e.preventDefault()}
            >
              <Button
                size="small"
                variant="primary"
                loading={isFetching}
                onClick={() => fetchNextPage().then(() => lastShownElement.current?.focus())}
                className="mx-auto h-8 data-[highlighted]:bg-hover"
              >
                {t("common:load_more")}
              </Button>
            </DropdownMenu.Item>
          )}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="duration-[160ms] flex touch-none select-none bg-transparent p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
        >
          <ScrollArea.Thumb className="relative flex-1 rounded-sm bg-black/30 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-black/50" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </>
  );
}
