import { useSearchParams } from "react-router-dom";
import { useNavigate, useOutletContext } from "react-router";
import { useRef, useState } from "react";
import { usePlanning } from "~/lib/planning";
import { useMount } from "~/lib/lifecycle-helpers";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { ScrollLayer } from "~/pages/planning/_cmp/drag/scroll-layer";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { twMerge } from "tailwind-merge";
import { dateIsWeekend, getDayName, matchDates } from "~/lib/utils/date/date-utils";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { HolidayBadge } from "~/lib/ui/badges/holiday-badge";
import PlanningTableRow from "./table/planning-table-row";
import PlanningTableRowUnsassigned from "~/pages/planning/_cmp/table/planning-table-row-unsassigned";
import { useLocale } from "~/lib/utils/date";

export default function PlanningViewMultipleDays() {
  const { format } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);

  const { isDragging, collapsed } = useOutletContext<{ isDragging: boolean; collapsed: boolean }>();

  const { isLoadingCards, cardStateViews, selectedDate, viewDates, sortedUsers } = usePlanning();
  const { holidays, isHoliday } = useHolidays(viewDates[0]);

  const navigateToDayView = (date: Date) => {
    const dateString = date.toISOString().split("T")[0];
    searchParams.set("date", dateString);
    navigate({ pathname: "/planning", search: searchParams.toString() });
  };

  useMount(() => {
    setLoading(true);
  });

  // Fix flickering when switching between weeks that have cards, and weeks that do not
  useDebounce(
    () => {
      if (!isLoadingCards) {
        setLoading(false);
      }
    },
    [cardStateViews.plannedUnassigned, cardStateViews.plannedAssigned],
    1
  );

  const boundingRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div
        ref={boundingRef}
        className="planning-scrollbar relative flex flex-grow flex-col overflow-x-auto overflow-y-auto scroll-smooth"
      >
        <ScrollLayer boundingRef={boundingRef} isDragging={isDragging} />
        <table className="w-fit table-fixed border-separate border-spacing-0 border-b">
          <thead>
            <tr className="sticky top-0 bg-white">
              <td className="sticky left-0 top-0 w-72 bg-white p-0">
                <div className="h-20 w-72 border-b border-r border-t"></div>
              </td>
              {viewDates.map((date, index) => (
                <td className="h-20 w-40 p-0" key={`day-${index}`}>
                  <div
                    className={twMerge(
                      "flex h-full w-full shrink-0 cursor-pointer flex-col items-center border-b border-t py-1 text-center",
                      isHoliday(date)
                        ? "bg-yellow-50"
                        : dateIsWeekend(date)
                          ? "bg-gray-300"
                          : matchDates(selectedDate, date, { ignoreTimestamp: true })
                            ? "bg-white"
                            : "bg-white",
                      index < viewDates.length - 1 && "border-r-0"
                    )}
                    onClick={() => navigateToDayView(date)}
                  >
                    <div className="text-md">
                      {getDayName(date, getResolvedLanguage(), { capitalizeFirst: true })}
                    </div>
                    <div className="text-xs">
                      {format(date, {
                        excludeTime: true,
                        excludeYear: true,
                        shortMonth: true,
                      })}
                    </div>
                    <div className="h-5 pt-1">
                      <HolidayBadge date={date} holidays={holidays} />
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <PlanningTableRowUnsassigned
              isLoading={loading || isLoadingCards}
              collapsed={collapsed}
            />
            {sortedUsers.map((user, idx) => (
              <PlanningTableRow
                key={`employee-week-${user.id}-${idx}-1`}
                user={user}
                collapsed={collapsed}
                isLoading={loading || isLoadingCards}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
