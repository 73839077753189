import { useAPI } from "~/lib/api";
import { linkToProductNew } from "~/lib/utils";
import { useTranslation } from "react-i18next";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { CreateProductDialog } from "~/pages/products/_cmp/create-product-dialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteProductRequest, ListUsersDirectionEnum, Product } from "@apacta/sdk";
import { CACHE_PRODUCTS } from "~/pages/products";
import { Currency } from "~/lib/ui/currency";
import { useNavigator } from "~/lib/utils/use-navigator";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { ActionButtons } from "~/lib/ui/action-buttons";

export default function ProductsIndex() {
  const api = useAPI();

  const { copyToClipboard } = useNavigator();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const columns = useDataColumns<Product>((columnHelper) => {
    return [
      columnHelper.accessor("name", {
        header: t("products:product_name"),
        enableSorting: true,
        cell: ({ row }) => (
          <OptionalLink to={linkToProductNew(row.original.id)}>
            <span className="font-semibold">{row.original.name}</span>
          </OptionalLink>
        ),
      }),
      columnHelper.accessor("buyingPrice", {
        header: t("products:cost_price"),
        cell: ({ row }) => (
          <Currency>{row.original.buyingPrice ? row.original.buyingPrice : 0}</Currency>
        ),
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("sellingPrice", {
        header: t("products:selling_price"),
        cell: ({ row }) => (
          <Currency>{row.original.sellingPrice ? row.original.sellingPrice : 0}</Currency>
        ),
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("unit", {
        header: t("products:product_unit"),
        cell: ({ row }) => <span>{row.original.unit}</span>,
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => (
          <ActionButtons
            collapseAt={4}
            size="small"
            actions={[
              {
                Icon: getIcon("clipboard"),
                label: t("products:copy_erp_to_clipboard", {
                  defaultValue: "Copy ERP ID to clipboard",
                }),
                isHidden: !row.original.erpId,
                onClick: () =>
                  copyToClipboard({
                    value: row.original.erpId!,
                    toastTitle: t("products:copied_to_clipboard.title"),
                    toastDescription: t("products:copied_to_clipboard.description"),
                  }),
              },
              {
                Icon: getIcon("delete"),
                label: t("common:delete"),
                confirm: {
                  entity: "product",
                  action: "delete",
                },
                onClick: () => handleDeleteProduct(row.original.id),
              },
            ]}
          />
        ),
      }),
    ];
  });

  const tableState = useDataTableState();

  const dataQ = useQuery({
    queryKey: [
      CACHE_PRODUCTS,
      tableState.pageNumber,
      tableState.sortBy,
      tableState.sortingDirection,
      tableState.state.search,
    ],
    queryFn: () =>
      api.productsList({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection as ListUsersDirectionEnum,
      }),
  });

  const items = (dataQ.data?.data ?? []).filter((d) => d !== null);

  const table = useDataTable(
    {
      mode: "server",
      columns,
      data: items,
      tableState,
      backendPagination: dataQ.data?.pagination,
      isLoading: dataQ.isLoading,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: true,
    }
  );

  const deleteMutation = useMutation({
    mutationFn: (args: DeleteProductRequest) => api.deleteProduct({ productId: args.productId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_PRODUCTS],
      });
    },
  });

  const handleDeleteProduct = async (id: string): Promise<void> => {
    await deleteMutation.mutateAsync({ productId: id });
    return;
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("common:own_products", { count: 2 })}</h2>
        <div>
          <Dialog
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("create_new")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:product", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
            render={({ onClose }) => <CreateProductDialog onProductCreated={onClose} />}
          />
        </div>
      </div>
      <DataTable table={table} />
    </div>
  );
}
