import { RegistrationsDTO } from "@apacta/sdk";
import { useDataColumns } from "~/lib/ui/data-table";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { RegistrationTypeCell } from "~/lib/ui/table/cells/registration-type-cell";
import { BadgeLabel } from "~/lib/ui/badge-label";
import { FormApprovalCell } from "~/lib/ui/table/cells/form-approval-cell";
import { EmployeeCell } from "~/lib/ui/table/cells/employee-cell";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { getIcon } from "~/lib/ui";
import { useNavigate } from "react-router";
import { linkToExpense, linkToProjectForm } from "~/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CACHE_REGISTRATIONS } from "~/pages/projects/[id]/registrations";
import { useLinking } from "~/lib/utils/use-linking";

export function useRegistrationTableColumns(opts: {
  projectId: string;
  onPreviewPDF: (url: string) => void;
}) {
  const link = useLinking();
  const api = useAPI();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteM = useMutation({
    mutationFn: async (entity: RegistrationsDTO) => {
      switch (entity.type) {
        case "form":
          await api.deleteForm({ formId: entity.id });
          break;
        case "invoice":
          await api.deleteInvoice({ invoiceId: entity.id });
          break;
        case "expense":
          await api.deleteExpense({ expenseId: entity.id });
          break;
      }
      await queryClient.invalidateQueries({
        queryKey: [CACHE_REGISTRATIONS],
      });
    },
  });

  const undeleteFormM = useMutation({
    mutationFn: async (formId: string) => {
      const res = await api.undeleteForm({
        formId,
        projectId: opts.projectId,
      });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_REGISTRATIONS],
      });
      return res;
    },
  });

  function handleLinkEntity(entity: RegistrationsDTO) {
    switch (entity.type) {
      case "form":
        navigate(linkToProjectForm(opts.projectId, entity.id));
        break;
      case "invoice":
        navigate(link.invoice(entity.id)!);
        break;
      case "expense":
        navigate(linkToExpense(entity.id));
        break;
    }
  }

  const columns = useDataColumns<RegistrationsDTO>((columnHelper) => [
    columnHelper.accessor("date", {
      header: t("common:date"),
      cell: ({ row }) => <DateCell date={row.original.date} />,
    }),
    columnHelper.accessor("type", {
      header: t("common:type"),
      cell: ({ row }) => (
        <RegistrationTypeCell registration={row.original} projectId={opts.projectId} />
      ),
    }),
    columnHelper.accessor("activity", {
      header: t("common:activity", { count: 2 }),
      cell: ({ row }) =>
        row.original.activity ? (
          <BadgeLabel backgroundColor={`#${row.original.activity?.hexCode}`}>
            {row.original.activity?.name}
          </BadgeLabel>
        ) : null,
    }),
    columnHelper.accessor("employee", {
      header: t("common:employee", { count: 1 }),
      cell: ({ row }) => <EmployeeCell fullName={row.original.employee} />,
      meta: {
        skeletonType: "avatar",
      },
    }),
    columnHelper.accessor("approved", {
      header: t("common:approved"),
      meta: {
        className: "text-center",
      },
      cell: ({ row }) =>
        row.original.type === "form" &&
        row.original.id && (
          <FormApprovalCell
            projectId={opts.projectId}
            formId={row.original.id}
            value={row.original.approved}
          />
        ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              label: t("common:preview"),
              Icon: getIcon("preview"),
              onClick: async () => {
                if (row.original.type !== "form") return;
                if (!row.original.preview) return;
                opts.onPreviewPDF?.(row.original.preview);
              },
              isHidden: (() => {
                if (row.original.type !== "form") return true;
                if (row.original.deleted) return true;
                if (!row.original.preview) return true;
                return false;
              })(),
            },
            {
              label: t("common:edit"),
              Icon: getIcon("edit"),
              onClick: async () => handleLinkEntity(row.original),
              isHidden: !!row.original.deleted,
            },
            {
              label: t("common:undelete"),
              Icon: getIcon("undelete"),
              onClick: () => undeleteFormM.mutateAsync(row.original.id),
              isHidden: !row.original.deleted,
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              onClick: () => deleteM.mutateAsync(row.original),
              confirm: {
                entity: "registration",
                action: "delete",
              },
              isHidden: (() => {
                if (!!row.original.deleted) return true;
                if (row.original.type === "invoice" && row.original.invoiced) return true;
                return false;
              })(),
            },
          ]}
        />
      ),
    }),
  ]);
  return columns;
}
