// TODO: Add this as a proper filter when everything is ready for filters
import { Popover } from "~/lib/ui/popover/popover";
import { Button, Label } from "~/lib/ui";
import { ReactNode, useCallback, useRef, useState } from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { Input } from "~/lib/ui/form-elements";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { useTranslation } from "react-i18next";

export default function MinNumberFilter({
  label,
  min,
  max,
  value = 0,
  valueRender,
  onSubmit,
}: {
  label?: string;
  min: number;
  max: number;
  value?: number;
  valueRender: (value: number) => ReactNode;
  onSubmit: (value: number) => void;
}) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<number>(value ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleValueChange = (v: Array<number>) => {
    if (inputRef.current) {
      inputRef.current.value = v[0].toString();
    }
  };

  const handleValueCommit = (v: Array<number>) => {
    setSelectedValue(v[0]);
  };

  const handleSubmit = useCallback(() => {
    onSubmit(selectedValue);
  }, [selectedValue]);

  return (
    <div>
      {label && <Label>{label}</Label>}
      <Popover
        config={{
          side: "bottom",
          align: "start",
        }}
        triggerRender={() => (
          <Button as="span" size="small" variant="secondary">
            {valueRender(value)}
          </Button>
        )}
      >
        {(close) => (
          <div className="rounded-lg border bg-white p-6 shadow-md">
            <div className="flex flex-col gap-4">
              <div>
                <div className="pb-4 text-sm font-medium">Vælg beløb</div>
                <div className="flex justify-between text-xs">
                  <span>{valueRender(min)}</span>
                  <span>{valueRender(max)}</span>
                </div>
                <SliderPrimitive.Root
                  className="relative flex h-5 w-[200px] touch-none select-none items-center"
                  defaultValue={[value]}
                  min={min}
                  max={max}
                  step={Math.round(max / 100)}
                  onValueChange={handleValueChange}
                  onValueCommit={handleValueCommit}
                >
                  <SliderPrimitive.Track className="relative h-2 grow rounded-full bg-primary">
                    <SliderPrimitive.Range className="absolute h-full rounded-full bg-primary" />
                  </SliderPrimitive.Track>
                  <SliderPrimitive.Thumb
                    className="block size-5 rounded-full bg-hover shadow-sm shadow-black hover:bg-hover focus:shadow-sm focus:shadow-black focus:outline-none"
                    aria-label="Volume"
                  />
                </SliderPrimitive.Root>
              </div>
              <HorizontalDivider lineClassName="border-primary">
                {t("common:or").toLowerCase()}
              </HorizontalDivider>
              <div>
                <Label>Indtast beløb</Label>
                <Input
                  ref={inputRef}
                  type="number"
                  defaultValue={selectedValue}
                  onChange={(e) => setSelectedValue(Number(e.currentTarget.value))}
                />
              </div>

              <div className="flex justify-end">
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    handleSubmit();
                    close();
                  }}
                >
                  Anvend
                </Button>
              </div>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
}
